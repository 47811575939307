<template>
  <NouDialog>
    <div class="withdraw">
      <div class="withdraw__title">
        <span>Withdrawal</span>
        <div class="icon g-clickable" @click="close">
          <i class="iconfont icon-close" />
        </div>
      </div>
      <div class="withdraw__content">
        <div class="label">
          <span>Select token</span>
        </div>
        <SoarSelectToken :item="token" :list="tokenList" @change="changeToken" />
        <div class="label">
          <span>Amount({{ token.symbol }})</span>
          <span @click="setMax">Balance: <i>{{ balance || 0 }}</i></span>
        </div>
        <div class="balance">
          <div class="input">
            <input type="text" v-model="amount" placeholder="0.00" />
          </div>
        </div>
        <div class="label">
          <span>Receiver</span>
        </div>
        <div class="balance">
          <div class="input">
            <input type="text" v-model="receiver" placeholder="Enter wallet address" />
          </div>
        </div>
        <div class="button g-clickable" :class="{ disable: !isOk }" @click="confirm">
          <span>Withdraw</span>
        </div>
      </div>
    </div>
  </NouDialog>
</template>

<script>
import NouDialog from "@/components/common/NouDialog";
import SoarSelectToken from "@/components/SoarSelectToken";
import showToast from "@/components/toast";
import { getBalances } from "@/init";
import { withdraw } from "@/api/account";
import { amount2Decimals } from "@/utils/number";
import { getTokenList } from "@/api/account";
import showLoading from "@/components/loading";
import { isSolanaAddress } from "@/utils/helper";
import BigNumber from "bignumber.js";
import store from "@/store";

export default {
  components: {
    NouDialog,
    SoarSelectToken,
  },
  data () {
    return {
      balance: 0,
      amount: "",
      receiver: "",
      tokenCode: "So11111111111111111111111111111111111111112",
      tokenList: [
        {
          address: "So11111111111111111111111111111111111111112",
          symbol: "SOL",
          icon: require("@/assets/token/icon-sol.png"),
          balance: 0,
          usd: 0,
        },
      ],
      getBlanceTime: 0

    };
  },
  computed: {
    isOk () {
      if (!this.receiver) return
      if (!isSolanaAddress(this.receiver)) return
      if (!this.amount) return
      if (this.tokenCode == "So11111111111111111111111111111111111111112") {
        if (+this.amount > new BigNumber(+this.balance).minus(0.001).toNumber()) return
      } else {
        if (+this.amount > +this.balance) return
      }
      return true
    },
    token () {
      const item = this.tokenList.find((item) => {
        return item.address === this.tokenCode;
      });
      return item || {};
    },
  },
  watch: {
    authAddress () {
      this.load();
    },
  },
  mounted () {
    this.load();
  },
  methods: {
    async getBlances (k) {
      if (this.getBlanceTime) clearTimeout(this.getBlanceTime)
      if (k > 15) return
      const result = await getBalances(this.tokenList, {
        chain: this.chainCode,
        account: this.authAddress,
      });
      result.forEach(item => {
        if (item.address == 'So11111111111111111111111111111111111111112') {
          store.commit('SET_AUTH_SOL_BALANCE', item.balance);
        }
      })
      this.$set(this, "tokenList", result);
      let active = result.find(item => item.address == this.tokenCode)
      if (active && active.balance != this.balance) {
        this.balance = active.balance;
      } else {
        this.getBlanceTime = setTimeout(() => {
          this.getBlances(k++)
        }, 4000)
      }
    },
    async load () {
      if (!this.authAddress) return;

      const list = await getTokenList();
      if (list && list[0] && list[0].symbol === "SOL") {
        list[0].balance = this.authSolBalance;
        this.balance = this.authSolBalance;
      }

      this.$set(this, "tokenList", list);
      const result = await getBalances(list, {
        chain: this.chainCode,
        account: this.authAddress,
      });
      this.$set(this, "tokenList", result);

      let active = result.find(item => item.address == this.tokenCode)
      if (active) {
        this.balance = active.balance;
      }

      // this.getBlances(0)

    },
    setMax () {
      if (!this.balance) return;
      if (this.tokenCode == "So11111111111111111111111111111111111111112") {
        let b = new BigNumber(+this.balance).minus(0.001).toNumber()
        this.amount = b < 0 ? 0 : b;
      } else {
        this.amount = +this.balance
      }
    },
    async confirm () {
      if (!this.isOk) return
      if (+this.authSolBalance < 0.001) {
        showToast("Sol is not enough for gas");
        return;
      }
      const instance = showLoading({
        text: "Withdraw in progress",
      });
      const chainCode = this.chainCode;
      try {
        const result = await withdraw({
          amount: amount2Decimals(this.amount, this.token.decimals),
          address: this.receiver,
          token: this.tokenCode,
        });

        const { code, msg, data } = result || {};
        if (code === 0 && data) {
          instance.change({
            message: "submit",
            chainCode,
            hash: data,
            status: "success",
          });
          this.amount = "";
        } else {
          instance.change({
            message: "withdraw fail: " + (msg || ""),
            chainCode,
            status: "rejected",
          });
        }
        this.getBlances(0)
        this.load();
      } catch (e) {
        instance.change({
          message: e.message,
          chainCode,
          status: "rejected",
        });
      }
    },
    changeToken (item) {
      const { balance, address } = item;
      this.tokenCode = address;
      this.balance = balance;
    },
    close () {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.withdraw {
  &__title {
    padding: 16px 20px 16px 20px;
    margin: -20px -20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      font-size: 20px;
      font-weight: 600;
    }

    .icon {
      width: 30px;
      height: 30px;
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--border-color);

      i {
        font-size: 18px;
        font-weight: 600;
        color: var(--color-content);
        transition: all 0.3s ease;
      }

      &:hover {
        i {
          color: var(--color);
        }
      }
    }
  }

  &__content {
    margin-top: 10px;

    .label {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-size: 14px;
        color: var(--color-des);

        i {
          text-decoration: underline;
          color: var(--color-yellow);
          cursor: pointer;
        }
      }
    }

    .balance {
      margin: 10px 0 20px;
      height: 45px;
      display: flex;
      align-items: center;
      border: 1px solid var(--border-color);
      border-radius: 10px;
      box-sizing: border-box;

      .icon {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 5px;

        img {
          width: 32px;
          height: 32px;
          border-radius: 16px;
        }
      }

      span {
        font-size: 14px;
        font-weight: 500;
        color: var(--color);
      }

      .input {
        flex: 1;

        input {
          width: 100%;
          background-color: transparent;
          border: none;
          outline: none;
          box-sizing: border-box;
          text-align: left;

          font-size: 16px;
          line-height: 26px;
          padding: 3px 15px;
          box-sizing: border-box;
          color: var(--color);
          z-index: 2;
        }
      }
    }

    .button {
      margin-top: 24px;
      height: 52px;
      font-size: 18px;
      border-radius: 30px;
      background: var(--color-blue);

      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      &.disable {
        background: var(--border-color);

        span {
          color: var(--color-des);
        }
      }

      span {
        font-size: 16px;
        font-weight: 500;
        color: var(--button-color);
        user-select: none;
      }
    }
  }
}
</style>
