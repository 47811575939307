<template>
  <div class="header-mobile" :class="{ 'header-mobile__unfold': unfold, android: android }">
    <div class="header-mobile__more">
      <div class="header-mobile__box">
        <div class="title">
          <span>More</span>
          <div class="g-clickable icon close" @click="handleChange">
            <svg viewBox="0 0 1024 1024">
              <path
                d="M512 34.133333a486.4 486.4 0 1 0 486.4 486.4A486.4 486.4 0 0 0 512 34.133333z m209.4848 632.8064l-55.6032 55.466667-151.517867-151.125333-151.517866 151.1168-55.6032-55.466667 151.517866-151.108267L307.242667 364.714667l55.6032-55.466667 151.517866 151.125333 151.517867-151.1168 55.6032 55.466667-151.517867 151.099733z m0 0"
                fill="currentColor"></path>
            </svg>
          </div>
        </div>
        <div class="list">
          <div class="item" v-for="(item, i) in list" :key="i" @click="jump(item.href)">
            <span>{{ item.name }}</span>
            <svg viewBox="0 0 256 256">
              <path fill="currentColor" d="M200 64v104a8 8 0 0 1-16 0V83.31L69.66 197.66a8 8 0 0 1-11.32-11.32L172.69 72H88a8 8 0 0 1 0-16h104a8 8 0 0 1 8 8" />
            </svg>
          </div>
        </div>
        <div class="sns">
          <a :href="item.href" class="item" v-for="(item, i) in sns" :key="i">
            <img :src="item.icon" />
          </a>
        </div>
      </div>
    </div>
    <div class="header-mobile__menu">
      <a href="/" :class="{ on: page === 'Home' }">
        <svg viewBox="0 0 200 200">
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <rect x="0" y="0" width="200" height="200"></rect>
            <path
              d="M200,55 L200,111.25 C200,129.88961 183.210678,145 162.5,145 L0,145 L0,88.75 C0,70.1103897 16.7893219,55 37.5,55 L200,55 Z M36.25,82.3375 L29.375,82.3375 L29.375,122.5 L36.1875,122.5 L36.1875,92.29375 L36.4375,92.29375 L59.5,122.5 L66.125,122.5 L66.125,82.3375 L59.3125,82.3375 L59.3125,112.20625 L59.0625,112.20625 L36.25,82.3375 Z M107,82.3375 L74.9375,82.3375 L74.9375,122.5 L108.0625,122.5 L108.0625,117.26875 L81.75,117.26875 L81.75,104.5 L105.5,104.5 L105.5,99.26875 L81.75,99.26875 L81.75,87.56875 L107,87.56875 L107,82.3375 Z M118.625,82.3375 L110.9375,82.3375 L123.75,122.5 L131.0625,122.5 L140.125,91.50625 L140.375,91.50625 L149.4375,122.5 L156.6875,122.5 L169.5625,82.3375 L161.875,82.3375 L153.1875,113.6125 L152.9375,113.6125 L143.8125,82.3375 L136.6875,82.3375 L127.5625,113.6125 L127.3125,113.6125 L118.625,82.3375 L118.625,82.3375 Z"
              fill="currentColor" fill-rule="nonzero"></path>
          </g>
        </svg>
        <span>New Tokens</span>
      </a>
      <a href="/gems" :class="{ on: page === 'Gems' }">
        <svg viewBox="0 0 200 200">
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="1">
              <rect x="0" y="0" width="200" height="200"></rect>
              <path d="M62.2883137,79.4913683 L25,79.4913683 L100,168.889238 L65.9065017,79.4913683 L62.2883137,79.4913683 Z M80.679903,79.1294375 L100,168.841397 L117.599566,79.1294375 L80.679903,79.1294375 L80.679903,79.1294375 Z" fill="currentColor" fill-rule="nonzero"></path>
              <path
                d="M81.0007126,68.465919 L118.569035,68.465919 L99.4606239,42.7286703 L81.0007126,68.465919 L81.0007126,68.465919 Z M100,168.889238 L175,79.4913683 L134.065977,79.4913683 L100,168.889238 Z M104.935667,31 L141.909892,31 L174.719832,68.6571247 L133.465319,68.6571247 L104.935667,31 Z M56.0620477,31 L91.998641,31 L66.7394866,68.5478414 L25.0409612,68.5478414 L56.0620477,31 Z"
                fill="currentColor" fill-rule="nonzero"></path>
            </g>
          </g>
        </svg>
        <span>Hunt Gems</span>
      </a>
      <a href="/copy-trading" :class="{ on: page === 'Gems' }">
        <svg t="1734074519895" viewBox="0 0 1024 1024" version="1.1" p-id="8484" width="16" height="16">
          <path
            d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64z m5.4 670c-110 0-173.4-73.2-173.4-194.9v-52.3C344 364.2 407.4 290 517.3 290c94.3 0 162.7 60.7 162.7 147.4 0 2.6-2.1 4.7-4.7 4.7h-56.7c-4.2 0-7.6-3.2-8-7.4-4-49.5-40-83.4-93-83.4-65.3 0-102.1 48.5-102.1 135.5v52.6c0 85.7 36.9 133.6 102.1 133.6 52.8 0 88.7-31.7 93-77.8 0.4-4.1 3.8-7.3 8-7.3h56.8c2.6 0 4.7 2.1 4.7 4.7 0 82.6-68.7 141.4-162.7 141.4z"
            p-id="8485" fill="currentColor"></path>
        </svg>
        <span>Copy Trading</span>
      </a>
      <div class="header-mobile__switch" @click="handleChange">
        <HeaderMobileSwitch class="" :active="unfold" />
      </div>
    </div>
  </div>
</template>

<script>
import HeaderMobileSwitch from "@/components/HeaderMobileSwitch";
import { MENU, SNS } from "@/utils/config";
import showToast from "@/components/toast";

export default {
  name: "Menu",
  components: {
    HeaderMobileSwitch,
  },
  data () {
    return {
      unfold: false,
      android: false,
      menu: MENU,
      sns: SNS,
      list: [
        {
          name: "App Setting",
          href: "",
        },
        {
          name: "Docs",
          href: "https://docs.bullme.one",
        },
        {
          name: "Brand Kit",
          href: "/brand-kit",
        },
      ],
    };
  },
  computed: {
    page () {
      console.log("this.route", this.$route);
      return this.$route && this.$route.name;
    },
  },
  mounted () {
    const u = navigator.userAgent;
    if (u.indexOf("Android") > -1 || u.indexOf("Linux") > -1) {
      this.android = true;
    }
  },
  methods: {
    handleChange () {
      this.unfold = !this.unfold;
      if (this.unfold) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "";
      }
    },
    jump (href) {
      if (href) {
        window.location.href = href;
      } else {
        showToast("Comming soon");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header-mobile {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 56px;
  transition: all 0.3s;
  padding-bottom: env(safe-area-inset-bottom);
  background-color: var(--background);
  z-index: 299;

  &__unfold {
    .header-mobile__more {
      height: calc(100vh - 56px);
    }

    .header-mobile__box {
      bottom: 0;
    }
  }

  &__more {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 56px;
    height: 0;
    overflow: hidden;
    backdrop-filter: blur(10px);
  }

  &__box {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -56px;
    padding: 15px 10px 5px;
    background: var(--card);
    transition: all 0.3s ease;

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid var(--border-color);
      padding-bottom: 10px;

      span {
        font-size: 16px;
        color: var(--color);
      }

      svg {
        width: 24px;
        color: var(--button);
      }
    }

    .list {
      padding: 5px;

      .item {
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-radius: 12px;
        transition: all 0.3s ease;

        span {
          font-size: 14px;
          color: var(--color-content);
          white-space: nowrap;
        }

        svg {
          height: 14px;
          color: var(--color-content);
          margin-left: 3px;
        }
      }

      border-bottom: 1px solid var(--border-color);
    }

    .sns {
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      a {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;

        img {
          width: 20px;
        }
      }
    }
  }

  &__menu {
    height: 56px;
    display: grid;
    grid-gap: 10px;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr 60px;
    border-top: 1px solid var(--border-color);

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-decoration: none;

      svg {
        width: 22px;
        margin-bottom: 2px;
        color: var(--color-content);
      }

      span {
        font-size: 14px;
        color: var(--color-content);
      }

      &.on {

        svg,
        span {
          color: var(--color-yellow);
        }
      }
    }
  }

  &__switch {
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
