import axios from "axios";

const MarketApiURL = "https://pre-market-api.openocean.finance";
const SwapUrl = "https://ethapi.openocean.finance";

export const getCrossSwap = async (code, account, activeRoute) => {
  const { data } = await axios.post(`${MarketApiURL}/v1/cross/${code}/swap`, {
    account,
    route: JSON.parse(JSON.stringify(activeRoute)),
  });
  return {
    ...data.data,
  };
};

export const getSwap = async (chainId, params) => {
  let url = [];
  for (let key in params) {
    if (params[key]) {
      url.push(`${key}=${params[key]}`);
    }
  }
  if (chainId === "solana") {
    url.push("disabledDexIds = '1,2,7'")
    const { data } = await axios.get(
      `${SwapUrl}/v1/solana/swap-quote?${url.join("&")}`
    );
    return data;
  } else {
    const { data } = await axios.get(
      `${SwapUrl}/v2/${chainId}/swap?${url.join("&")}`
    );
    return data;
  }
};
