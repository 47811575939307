<template>
  <div class="mobile-switch" :class="{ active }">
    <div class="line" />
    <div class="line second" />
    <div class="line thrid" />
  </div>
</template>

<script>
export default {
  name: "HeaderMobileSwitch",
  props: {
    active: {
      type: Boolean,
      require: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-switch {
  display: inline-block;
  position: relative;
  height: 14px;
  width: 18px;
  vertical-align: middle;

  &.active {
    .line {
      position: absolute;
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
      background: #fff;

      &.second {
        opacity: 0;
      }

      &.thrid {
        margin-top: 0;
        transform: translateY(-50%) rotate(-45deg);
        background: #fff;
      }
    }
  }

  &.dark {
    .line {
      background: #fff;
    }
  }
}

.line {
  width: 18px;
  height: 2px;
  border-radius: 2px;
  background: #fff;
  transition: transform 0.3s;

  &.second {
    margin-top: 4px;
  }

  &.thrid {
    margin-top: 4px;
  }
}
</style>