import request from "@/utils/request";
import { changeIPFS, MEME_URL } from "@/utils/config";


export const getCurvePosition = async (mint) => {
  const data = await request(`https://api.moonshot.cc/token/v1/solana/${mint}`);
  const { moonshot } = data || {};
  const { curvePosition } = moonshot || {};
  return curvePosition;
}

export const getNewTokens = async (params) => {
  let data = []
  if (params && params.data) {
    data = params.data
  } else {
    let ar = []
    params.forEach(item => {
      if (item.min) {
        ar.push(`${item.code}Min=${item.min}`)
      }
      if (item.max) {
        ar.push(`${item.code}Max=${item.max}`)
      }
    })
    ar = ar.join('&')
    let res = await request(`${MEME_URL}/market/token/newTokens?${ar}`)
    data = res.data
  }

  data = data.map((item) => {
    item.mint = item.address;
    item.top10Holder1 = Number(item.top10Holder) * 100
    const { symbol, name } = item;
    item._symbol = symbol && symbol.toLowerCase();
    item._name = name && name.toLowerCase();

    return item;
  });
  return data;
};

export const getTokens = async (params) => {
  let data = []
  if (params.data) {
    data = params.data
  } else {
    let res = await request(`${MEME_URL}/market/token/tokens?type=${params.type}`);
    data = res.data
  }
  data.map((item) => {
    item.dex = item.source ? item.dex : 'swap'
    item.source = item.source || 'other'
    item.mint = item.address
    item.top10Holder1 = Number(item.top10Holder) * 100
  })
  return data;
};

export const getToken = async (params) => {
  let data = {}
  if (params.data) {
    data = params.data
  } else {
    let res = await request(
      `${MEME_URL}/market/token/detail?address=${params.address}`
    );
    data = res.data
    if (data.source === 'moonshot') {
      try {
        data.curvePosition = await getCurvePosition(params.address);
      } catch (error) {
        debugger
      }
    }
  }

  if (data) {
    data.mint = data.address;
    data.top10Holder1 = data.top10Holder * 100;
    data.baseAsset = {
      symbol: "SOL",
      address: "So11111111111111111111111111111111111111112",
      decimals: 9,
      icon: "https://s3.openocean.finance/token_logos/logos/solana/So11111111111111111111111111111111111111112.png",
      balance: 0,
      chain: "solana",
      price: data.solPrice,
    };
    data.dex = data.source ? data.dex : 'swap'
    data.source = data.source || 'other'
  }
  return data;
};

export const getTrades = async (params) => {
  const { data } = await request(
    `${MEME_URL}/market/token/trades?address=${params.address}`
  );
  return data;
};

export const getUserTrades = async (params) => {
  // params.account='7uG76wfkUDb8wwTaVQDdiGVDDBaX4uLnFMWsQU1KMfpa'
  let { data } = await request(`${MEME_URL}/trading/userTrades?address=${params.address}&account=${params.account}&onlyCopyTrading=${params.onlyCopyTrading}`);
  return data;
};

export const getUserHolds = async (params) => {
  // params.account = 'BeoYSrd7b4Bj6q2j3WATXb2CVesKd4Y7WyomuD7iQuWF'
  let { data } = await request(`${MEME_URL}/trading/userHolds?address=${params.address}&account=${params.account}`);
  // data = [
  //   {
  //     "address": "CqyA8t4KKAeYXKCUVgsKdXGFjapnscR2Sp9wiJRLpump",
  //     "symbol": "TUG",
  //     "account": "63kuw1frn9JAHFDb6mbf4deibTdKiDPvCAgv6RLPVUmd",
  //     "holdAmount": 10000,
  //     "buyAmount": 93338.661979,
  //     "buyVolume": 4.0230614,
  //     "buyCount": 1,
  //     "buyAvgPrice": 0.000043101768492301,
  //     "sellAmount": 83338.661979,
  //     "sellVolume": 0.5234954,
  //     "sellCount": 1,
  //     "sellAvgPrice": 0.000005608559078313,
  //     "totalProfit": -3.499566000000091,
  //     "lastActiveTime": 1730981792000,
  //     price: 0.06
  //   }
  // ]

  data.map((item) => {
    if (item.buyVolume) {
      item.pnl = (item.price * item.holdAmount) + item.sellVolume - item.buyVolume
      item.bfb = item.pnl / item.buyVolume * 100
      item.holdVolume = item.price * item.holdAmount
    }
    if (item.holdAmount && item.buyAmount) {
      item.unr = (item.price * item.holdAmount) - item.holdAmount * item.buyAvgPrice
      item.unrBfb = item.unr / (item.holdAmount * item.buyAvgPrice) * 100
    }
    if (item.totalProfit && item.buyAmount) {
      item.totalProfitBfb = item.totalProfit / item.buyVolume * 100
    }
    if (item.holdAmount && item.buyAmount) {
      item.holdBfb = item.holdAmount / item.buyAmount * 100
    }
  })

  return data;
};

export const getKline = async (params) => {
  const { data } = await request(
    `${MEME_URL}/market/kline/list?address=81uGwtDjy34u7noos8XmjLBGQicYjHyf7CnSC32hpump&period=1m&startTime=1731670200000&endTime=1731671100000`
  );
  return data;
};

export const post = async (code) => {
  try {
    const { data } = await request({
      method: "POST",
      data: {
        url: `${MEME_URL}/balances/${code}?limit=200&offset=0&minBalance=-1`,
      },
    });
    return (data || []).map((item) => {
      item.image_uri = item.image_uri && changeIPFS(item.image_uri);
      item.symbol = item.symbol && item.symbol.toUpperCase();
      return item;
    });
  } catch (e) {
    return [];
  }
};
