<template>
  <transition name="fadeBack" @after-leave="handleLeave">
    <div
      v-show="isShow"
      ref="scrollDom"
      class="nou-dialog nou-g-box"
      @click="handleClickModal"
    >
      <div class="nou-dialog__container__wrapper">
        <transition name="fade">
          <div
            v-show="isShow"
            class="nou-dialog__container"
            :class="{ ...dialogClass }"
            @click.stop="dialogClick"
          >
            <slot name="close">
              <i
                v-if="showClose"
                class="iconfont icon-close nou-dialog__close nou-g-btn--hover"
                @click="close"
              />
            </slot>
            <h2 v-if="title.length > 0" class="nou-dialog__title g-font-bold">
              {{ title }}
            </h2>
            <slot v-if="contentActive" />
          </div>
        </transition>
      </div>
    </div>
  </transition>
</template>
<script>
/**
 * dialog
 * @author yuanzeyu
 * @date 2019-12-23
 * @desc
 */
export default {
  name: "NouDialog",
  mixins: [],
  props: {
    width: {
      type: [String, Number],
      default: 400,
    },
    visible: {
      type: Boolean,
      require: false,
      default: true,
    },
    keepSlot: {
      // keep slot instead of recreated
      type: Boolean,
      require: false,
      default: false,
    },
    title: {
      type: String,
      require: true,
      default: "",
    },
    closeOnClickModal: {
      type: Boolean,
      default: false,
    },
    dialogClass: {
      type: Object,
      require: false,
      default: () => {},
    },
    showClose: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  data() {
    return {
      isShow: false,
      contentActive: false,
    };
  },
  watch: {
    visible: {
      handler(val) {
        if (val) {
          this.show();
        } else {
          this.close();
        }
      },
      immediate: true,
    },
  },
  created() {
    if (this.keepSlot) {
      this.contentActive = true;
    }
  },
  methods: {
    dialogClick() {
      this.$eventBus.$emit("dialogClick");
    },
    close() {
      this.isShow = false;
      this.$emit("update:visible", false);
    },
    handleClickModal() {
      if (this.closeOnClickModal) {
        this.close();
      }
    },
    show() {
      this.isShow = true;
      this.contentActive = true;
    },
    handleLeave() {
      if (!this.keepSlot) {
        // destroy slot after leave
        this.contentActive = false; // avoid view change between transition
      }
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.nou-dialog {
  z-index: 9999;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--dialog-mask);
  cursor: initial;
  overflow: auto;
}

.nou-dialog__container__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
}

.nou-dialog__container {
  position: relative;
  margin: 100px 0;
  width: 360px; // 400 total
  max-width: 100%;
  padding: 20px;
  border-radius: 16px;
  background: var(--dialog-content);
  box-shadow: 0 2px 12px rgba(0, 0, 0, .03), 0 20px 70px rgba(0, 0, 0, .06), 0 2px 4px rgba(0, 0, 0, .02);
  text-align: left;

  &.market {
    width: 420px;
  }

  &.iframe {
    width: 400px;
  }

  &.filter {
    background: var(--card);
  }

  &.search {
    background: var(--card);
    width: 420px;
  }

  @media (max-width: 750px) {
    width: calc(100% - 20px);
    box-sizing: border-box;
    margin: 0 10px;

    &.search {
      width: calc(100% - 20px);
    }
  }

  @media (max-width: var(--max-width)) {
    margin: 1rem 0;
    box-sizing: border-box;
    width: calc(100% - 0.6rem);
    padding: 0.4rem;

    &.fixed-mobile {
      position: fixed;
      top: initial;
      bottom: 0;
      left: 50%;
      margin: 0;
      transform: translate3d(-50%, 0, 0);
    }
  }
}

.nou-dialog__close {
  float: right;
  font-size: 14px;
  margin-top: 8px;
  cursor: pointer;
  @media (max-width: var(--max-width)) {
    font-size: 0.28rem;
    margin-top: 0.07rem;
  }
}

.nou-dialog__title {
  margin: 0;
  padding: 0;
  font-size: 20px;
  line-height: 21px;

  @media (max-width: var(--max-width)) {
    font-size: 0.36rem;
    line-height: 0.42rem;
    padding-bottom: 0.4rem;
  }
}

.fade-enter-active,
.fade-leave-active {
  //transition: all .5s;
  //transform: translateZ(0);
}

.fade-enter,
.fade-leave-active {
  //opacity: 0;
  //transform: translate3d(0, 100%, 0);
}

.fadeBack-enter-active,
.fadeBack-leave-active {
  transition: opacity 0.5s;
  opacity: 1;
}

.fadeBack-enter,
.fadeBack-leave-active {
  opacity: 0;
}
</style>

