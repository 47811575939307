/**
 * name: Asset Management
 * author: tony@soar.io
 * date: 2024/7/29
 * desc:
 */
import Vue from "vue";
import App from "./App.vue";
import i18n from "@/utils/lang";
import router from './router'
import store from './store';
import mixin from "./mixin";
import * as filters from "./utils/filters";

import "./assets/iconfont/iconfont.css";
import "./styles/index.scss";

// register global utility filters.
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

window.$eventBus = Vue.prototype.$eventBus = new Vue();
Vue.mixin(mixin)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
