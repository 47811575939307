import { Connection, SystemProgram, PublicKey, Transaction, ComputeBudgetProgram } from "@solana/web3.js";
import { sleep } from "@/utils/helper";
import promiseRetry from "promise-retry";
import { amount2Decimals } from "@/utils/number";
import { SOLANA_RPC } from "@/utils/config";


export const connection = new Connection(SOLANA_RPC, "confirmed");

export async function createTransaction(
  connection,
  instructions,
  payer,
  priorityFeeInSol
) {
  const modifyComputeUnits = ComputeBudgetProgram.setComputeUnitLimit({
    units: 1400000,
  });

  const transaction = new Transaction().add(modifyComputeUnits);

  if (priorityFeeInSol > 0) {
    const microLamports = priorityFeeInSol * 1_000_000_000; // convert SOL to microLamports
    const addPriorityFee = ComputeBudgetProgram.setComputeUnitPrice({
      microLamports,
    });
    transaction.add(addPriorityFee);
  }

  transaction.add(...instructions);

  transaction.feePayer = payer;
  transaction.recentBlockhash = (
    await connection.getLatestBlockhash()
  ).blockhash;
  return transaction;
}

export function bufferFromUInt64(value) {
  const buffer = new ArrayBuffer(8);
  const view = new DataView(buffer);

  // 将value转换为BigInt
  const bigIntValue = BigInt(value);

  // 写入64位整数（小端序）
  view.setBigUint64(0, bigIntValue, true);

  return new Uint8Array(buffer);
}

export function concatArrayBuffers(buffers) {
  const totalLength = buffers.reduce((acc, buf) => acc + buf.byteLength, 0);
  const result = new Uint8Array(totalLength);
  let offset = 0;
  for (const buffer of buffers) {
    result.set(new Uint8Array(buffer), offset);
    offset += buffer.byteLength;
  }
  return new Uint8Array(result.buffer);
}

export function hexStringToUint8Array(hexString) {
  if (hexString.length % 2 !== 0) {
    throw new Error("Invalid hex string");
  }
  const arrayBuffer = new Uint8Array(hexString.length / 2);

  for (let i = 0; i < hexString.length; i += 2) {
    const byteValue = parseInt(hexString.substr(i, 2), 16);
    arrayBuffer[i / 2] = byteValue;
  }

  return arrayBuffer;
}

export function bufferFromString(value) {
  // 创建一个ArrayBuffer,大小为4字节(用于长度)加上字符串的长度
  const buffer = new ArrayBuffer(4 + value.length);

  // 创建一个DataView来操作这个buffer
  const view = new DataView(buffer);

  // 写入字符串长度(32位无符号整数,小端序)
  view.setUint32(0, value.length, true);

  // 将字符串写入buffer
  const encoder = new TextEncoder();
  const uint8Array = encoder.encode(value);
  new Uint8Array(buffer, 4).set(uint8Array);

  return new Uint8Array(buffer);
}

export async function transactionSenderAndConfirmationWaiter({
  serializedTransaction,
}) {
  const txid = await connection.sendRawTransaction(serializedTransaction, {
    // skipPreflight: true,
    skipPreflight: true,
    preflightCommitment: "confirmed",
  });
  console.log("txid", txid);
  const controller = new AbortController();
  const abortSignal = controller.signal;

  const abortableResender = async () => {
    while (true) {
      await sleep(5000);
      if (abortSignal.aborted) return;
      try {
        let id = await connection.sendRawTransaction(serializedTransaction, {
          skipPreflight: true,
        });
        console.log("Resender-------------");
        console.log(id);
      } catch (e) {
        console.warn(`Failed to resend transaction: ${e}`);
      }
    }
  };

  try {
    abortableResender();
    let times = 0;
    let races = [
      new Promise(async (resolve) => {
        while (!abortSignal.aborted) {
          times++;
          if (times > 8) resolve("");
          await sleep(2000);
          const tx = await connection.getSignatureStatus(txid, {
            searchTransactionHistory: false,
          });
          if (tx?.value?.confirmationStatus === "confirmed") {
            resolve(tx);
          }
        }
      }),
    ];
    // if (blockhashWithExpiryBlockHeight && blockhashWithExpiryBlockHeight.lastValidBlockHeight) {
    //   const lastValidBlockHeight = blockhashWithExpiryBlockHeight.lastValidBlockHeight - 150;
    //   races.push(connection.confirmTransaction(
    //     {
    //       ...blockhashWithExpiryBlockHeight,
    //       lastValidBlockHeight,
    //       signature: txid,
    //       abortSignal,
    //     },
    //     "confirmed"
    //   ))
    // }
    await Promise.race(races);
  } catch (e) {
    if (e instanceof TransactionExpiredBlockheightExceededError) {
      return null;
    } else {
      throw e;
    }
  } finally {
    controller.abort();
  }

  const response = promiseRetry(
    async (retry) => {
      const response = await connection.getTransaction(txid, {
        commitment: "confirmed",
        maxSupportedTransactionVersion: 0,
      });
      if (!response) {
        retry(response);
      }
      return response;
    },
    {
      retries: 2,
      minTimeout: 3000,
    }
  );

  return { response, txid };
}

export function getFeeInstruction(creator) {
  return SystemProgram.transfer({
    fromPubkey: creator,
    toPubkey: new PublicKey("3EXqsrR34LWZjnsSiwShysA7vaKBHoJiSJ1kCv1fuy49"),
    lamports: amount2Decimals(0.001, 9),
  });
}

