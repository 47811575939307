<template>
  <div class="select" @click.stop :class="{ show: isShow === true }">
    <div class="icon" @click="isShow = true">
      <img :src="item.icon || iconDefault" />
      <span>{{ item.symbol }}</span>
      <svg viewBox="0 0 13 8">
        <g>
          <path
            d="M6.49024 5.27362L1.45675 0.240116C1.15026 -0.0663668 0.62501 -0.0663668 0.318784 0.240116L0.231327 0.327573C-0.0751559 0.634055 -0.0751559 1.15931 0.231327 1.50939L5.87779 7.15586C6.05271 7.33103 6.27173 7.41848 6.4905 7.37488C6.70953 7.37488 6.9283 7.28743 7.10321 7.15586L12.7497 1.50939C13.0562 1.20291 13.0562 0.677655 12.7497 0.371429L12.6622 0.283973C12.3557 -0.0225103 11.8307 -0.0225103 11.5243 0.283973L6.49024 5.27362Z"
            fill="currentColor"
          ></path>
        </g>
      </svg>
    </div>
    <div class="select__list">
      <ul>
        <li
          class="select__item g-clickable"
          v-for="(item, i) in list"
          :key="i"
          @click="changeFilter(item)"
        >
          <img :src="item.icon || iconDefault" />
          <span>{{ item.symbol }}</span>
          <div>
            <span>{{ item.balance | nFormatter(6) }}</span>
            <span>${{ item.balance * item.price | nFormatter(4) }}</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      isShow: false,
    };
  },
  mounted() {
    this.$eventBus.$on("dialogClick", this.close);
  },
  methods: {
    changeFilter(item) {
      this.$emit("change", item);
      this.close();
    },
    close() {
      this.isShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.select {
  position: relative;
  margin: 10px 0 20px;

  .icon {
    width: auto;
    height: 45px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    border: 1px solid var(--border-color);
    box-sizing: border-box;
    border-radius: 10px;
    background: var(--dialog-content);

    @media (max-width: 750px) {
      height: 42px;
    }

    img {
      width: 28px;
      height: 28px;
      border-radius: 16px;
      margin-right: 6px;
    }

    span {
      flex: 1;
      font-size: 14px;
      font-weight: 500;
      line-height: 19px;
      margin-right: 6px;
      white-space: nowrap;
      color: var(--color);
    }

    svg {
      width: 9px;
      color: var(--color-content);
      transition: all 0.3s ease;
    }
  }

  &__list {
    position: absolute;
    left: 0;
    right: 0;
    top: 30px;
    z-index: 9;
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s ease;
    ul {
      padding: 6px;
      background-color: var(--card);
      border: 1px solid var(--border-color);
      border-radius: 10px;
      max-height: 320px;
      overflow: auto;
      border-top: none;

      li {
        padding: 0 8px;
        line-height: 45px;
        border-radius: 10px;
        transition: all 0.3s ease;
        display: flex;
        align-items: center;
        margin-bottom: 6px;

        &:last-child {
          margin-bottom: 0;
        }

        img {
          width: 28px;
          height: 28px;
          border-radius: 16px;
          margin-right: 6px;
        }

        & > span {
          flex: 1;
          white-space: nowrap;
          font-size: 14px;
          color: var(--color-content);
        }

        div {
          text-align: right;
          span {
            display: block;
            font-size: 16px;
            font-weight: 600;
            line-height: 1;
            color: var(--color);

            &:last-child {
              margin-top: 3px;
              font-size: 14px;
              font-weight: normal;
              color: var(--color-content);
            }
          }
        }

        &:hover {
          background-color: rgba(255, 255, 255, 0.05);
          span {
            color: var(--color);
          }
        }
      }
    }
  }

  &.show {
    .select__list {
      padding-top: 20px;
      max-height: initial;
    }
  }
}
</style>