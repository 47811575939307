import request from "@/utils/request";

import {
  BASE_URL,
  OPEN_API_V3,
  changeIPFS,
} from "@/utils/config";
import * as four from "@/api/four";
import * as pump from "@/api/pump";
import * as moonshot from "@/api/moonshot";

export const APIMAP = {
  four,
  pump,
  moonshot,
};

export const getChainBySource = (source) => {
  return {
    pump: 'solana',
    moonshot: 'solana',
    four: 'bsc'
  }[source];
}

export const getAPI = (source) => {
  return APIMAP[source] || pump;
};

export const getAPIAll = async (fn, account) => {
  const list = [];
  for (let key in APIMAP) {
    list.push(APIMAP[key][fn](account));
  }
  const res = await Promise.all(list);
  return res.reduce((acc, cur) => {
    return acc.concat(cur);
  }, []);
};

export const getCoins = async (chain, source, key, sort, order, offset, limit) => {
  const _limit = limit || 50;
  if (['solana', 'bsc'].indexOf(chain) >= 0) {
    const data = await request(
      `${OPEN_API_V3}meme/${chain}/${source || "all"}/hzcoins?searchTerm=${key}&offset=${(offset || 0) * _limit
      }&limit=${_limit}&order=${order.toUpperCase() || "DESC"}&sort=${sort || "last_trade_timestamp"
      }`
    );
    return (data || []).map((item) => {
      item.image_uri = changeIPFS(item.image_uri);
      item.metadata_uri = changeIPFS(item.metadata_uri);
      item.symbol = item.shortName || item.symbol;
      return item;
    });
  }
  return [];
};

export const getCoin = async (mint, source) => {
  const chain = getChainBySource(source);
  try {
    const { data } = await request(
      `${OPEN_API_V3}meme/${chain}/${source}/getCoin?mint=${mint}`
    );
    if (data) {
      return {
        chain,
        token: data,
        mint,
        source
      }
    }
  } catch (error) {
  }
};

export const buy = async (source, params) => {
  const chain = getChainBySource(source);
  // const { account, mintAddress, baseAmount, tokenAmount, priorityFeeInSol, slippageDecimal } = params;
  params.referrer = "75FCfnJbNNqRFdjBhc53ZTqZB4CQg6WkjPQpqZj32uRr";
  params.referrerFee = 0.001;
  params.referrerShare = 30;
  const { data } = await request({
    method: "POST",
    url: `${OPEN_API_V3}meme/${chain}/${source}/buy`,
    data: params
  });
  return data;
};

export const sell = async (source, params) => {
  const chain = getChainBySource(source);
  params.referrer = "75FCfnJbNNqRFdjBhc53ZTqZB4CQg6WkjPQpqZj32uRr";
  params.referrerFee = 0.001;
  params.referrerShare = 30;
  const { data } = await request({
    method: "POST",
    url: `${OPEN_API_V3}meme/${chain}/${source}/sell`,
    data: params
  });
  return data;
};

export const getSolPrice = async () => {
  const { data } = await request(`${OPEN_API_V3}meme/getSolPrice`);
  const { solPrice } = data || {};
  return solPrice;
};

export const getTrades = async (source, mint) => {
  const chain = getChainBySource(source);
  const { data } = await request(
    `${OPEN_API_V3}meme/${chain}/${source}/getTrades?mint=${mint}`
  );
  return data;
};

export const getReplies = async (source, mint) => {
  const chain = getChainBySource(source);
  const { data } = await request(
    `${OPEN_API_V3}meme/${chain}/${source}/getReplies?mint=${mint}`
  );
  return data;
};

export const getTopHolders = async (source, mint) => {
  const chain = getChainBySource(source);
  const { data } = await request(
    `${OPEN_API_V3}meme/${chain}/${source}/getTopHolders?mint=${mint}`
  );
  return data;
};

export const getUserBalances = async (account) => {
  const { data } = await request(
    `${OPEN_API_V3}meme/solana/getUserBalances?account=${account}`
  );
  return data;
};

export const getUserReplies = async (account) => {
  const { data } = await request(
    `${OPEN_API_V3}meme/solana/getUserReplies?account=${account}`
  );
  return data;
};

export const getFollowers = async (account) => {
  const { data } = await request(
    `${OPEN_API_V3}meme/solana/getFollowers?account=${account}`
  );
  return data;
};

export const getFollowing = async (account) => {
  const { data } = await request(
    `${OPEN_API_V3}meme/solana/getFollowing?account=${account}`
  );
  return data;
};

export const getUserCreatedCoins = async (account) => {
  const { data } = await request(
    `${OPEN_API_V3}meme/solana/getUserCreatedCoins?account=${account}`
  );
  return data;
};

export const getTxs = async (chainKey, account) => {
  const { data } = await request(
    `${OPEN_API_V3}${chainKey}/getTxs?account=${account}&pageSize=20&pageNumber=1`
  );
  return data;
};

export const ipfs = async (form) => {
  try {
    const data = await request({
      method: "POST",
      // url: `${OPEN_API_V3}form/forward`,
      url: `${BASE_URL}/api/ipfs`,
      data: form,
      headers: { "Content-Type": "multipart/form-data" },
    });
    return data;
  } catch (e) {
    return [];
  }
};