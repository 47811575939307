import { render, staticRenderFns } from "./Balances.vue?vue&type=template&id=2f130b80&scoped=true"
import script from "./Balances.vue?vue&type=script&lang=js"
export * from "./Balances.vue?vue&type=script&lang=js"
import style0 from "./Balances.vue?vue&type=style&index=0&id=2f130b80&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f130b80",
  null
  
)

export default component.exports