import Vue from 'vue';
import View from './Loading.vue';

const showLoading = params => {
  const Loading = Vue.extend(View);
  const instance = new Loading({
    params,
  }).$mount();
  document.body.appendChild(instance.$el);
  return instance;
}

export default showLoading;
