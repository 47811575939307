import * as four from "@/swap/four";
import * as pump from "@/swap/pump";
import * as moonshot from "@/swap/moonshot";

export const getSwap = (source) => {
  if (source === "pump") {
    return pump;
  } else if (source === "moonshot") {
    return moonshot;
  } else if (source === "four") {
    return four;
  }
};

export const calc = (source, params) => {
  const swap = getSwap(source);
  return swap.calc(params);
};

export const buy = async (source, params) => {
  const swap = getSwap(source);
  return await swap.buy(params);
};

export const sell = async (source, params) => {
  const swap = getSwap(source);
  return await swap.sell(params);
};

export const launch = async (source, params) => {
  const swap = getSwap(source);
  return await swap.launch(params);
};
