import {
  Keypair,
  PublicKey,
  Transaction,
  TransactionInstruction,
  SystemProgram,
  ComputeBudgetProgram,
  VersionedTransaction,
  TransactionMessage,
  AddressLookupTableAccount
} from "@solana/web3.js";
import {
  getAssociatedTokenAddress,
  createAssociatedTokenAccountInstruction,
  TOKEN_PROGRAM_ID,
  ASSOCIATED_TOKEN_PROGRAM_ID
} from "@solana/spl-token";
import bs58 from "bs58";
import {
  GLOBAL,
  FEE_RECIPIENT,
  ASSOC_TOKEN_ACC_PROG,
  RENT,
  PUMP_FUN_PROGRAM,
  PUMP_FUN_ACCOUNT,
  SYSTEM_PROGRAM_ID,
  MPL_TOKEN_METADATA,
  MINT_AUTHORITY,
  COMPUTE_BUDGET_PROGRAM_ID,
} from "@/utils/config";
import { getSwap } from "@/api/cross";
import { myWallet, getBondingCurve } from "@/init";
import showLoading from "@/components/loading";
import { decimals2Amount, amount2Decimals } from "@/utils/number";
import BN from "bn.js";
import {
  connection,
  createTransaction,
  bufferFromUInt64,
  concatArrayBuffers,
  hexStringToUint8Array,
  bufferFromString,
  getFeeInstruction,
  transactionSenderAndConfirmationWaiter
} from "@/swap/helper";
import BigNumber from "bignumber.js";

function launchCalcTokenOut(amount, isReverse) {
  const initialVirtualSolReserves = new BN(30000000000);
  const initialVirtualTokenReserves = new BN(1073000000000000);
  const initialRealTokenReserves = new BN(793100000000000);
  const feeBasisPoints = new BN(100);
  let tokenOut;
  if (isReverse) {
    const tokenAmount = new BN(amount2Decimals(amount, 6));
    const tokenAmountMin = BN.min(tokenAmount, initialRealTokenReserves);
    tokenOut = tokenAmountMin.mul(initialVirtualSolReserves).div(initialVirtualTokenReserves.sub(tokenAmount)).add(new BN(1));
    const fee = tokenOut.mul(feeBasisPoints).div(new BN(1e4));
    tokenOut = tokenOut.add(fee);
  } else {
    const solInLamports = amount2Decimals(amount, 9);
    const t = initialVirtualSolReserves.mul(initialVirtualTokenReserves);
    const o = initialVirtualSolReserves.add(new BN(solInLamports));
    const l = t.div(o).add(new BN(1));
    tokenOut = initialVirtualTokenReserves.sub(l);
    tokenOut = BN.min(tokenOut, initialRealTokenReserves);
  }
  return tokenOut;
}

async function swap(instanct, account, params) {
  const swapParams = {
    inTokenSymbol: params.inTokenSymbol,
    inTokenAddress: params.inTokenAddress,
    outTokenSymbol: params.outTokenSymbol,
    outTokenAddress: params.outTokenAddress,
    amount: params.amount,
    slippage: params.slippage || 100,
    account: account,
    // referrer: "0x3487ef9f9b36547e43268b8f0e2349a226c70b53",
    // referrerFee: 30,
    disableRfq: 1,
  };
  const chainCode = "solana";
  const res = await getSwap(chainCode, swapParams);
  const inAmount = decimals2Amount(res.inAmount, res.inToken.decimals);
  const outAmount = decimals2Amount(res.outAmount, res.outToken.decimals);
  instanct.change({
    status: "loading",
    chainCode,
    text: `Swaping ${inAmount} ${res.inToken.symbol} for ${outAmount} ${res.outToken.symbol}`,
  });
  try {
    let transaction = "";
    if (res.dexId == 6 || res.dexId == 7) {
      transaction = VersionedTransaction.deserialize(
        Buffer.from(res.transaction, "hex")
      );
      const owner = new PublicKey(account);
      const feeInstruction = getFeeInstruction(owner);
      const addressLookupTableAccounts = await Promise.all(
        transaction.message.addressTableLookups.map(async (lookup) => {
            return new AddressLookupTableAccount({
                key: lookup.accountKey,
                state: AddressLookupTableAccount.deserialize(await connection.getAccountInfo(lookup.accountKey).then((res) => res.data)),
            })
        }))
      const message = TransactionMessage.decompile(transaction.message, { addressLookupTableAccounts })
      message.instructions.unshift(feeInstruction);
      const recentBlockhash = await connection.getLatestBlockhash("finalized")
      .then(res => res.blockhash);
      message.recentBlockhash = recentBlockhash;
      transaction.message = message.compileToV0Message(addressLookupTableAccounts);
    } else {
      transaction = Transaction.from(Buffer.from(res.transaction, "hex"));
    }
    let signed = null;
    if (myWallet.isCoin98) {
      const result = await myWallet.request({
        method: "sol_sign",
        params: [transaction],
      });
      console.log("Got signature, submitting transaction");
      const bytes = bs58.decode(result.signature);
      transaction.signatures[0].signature = bytes;
      transaction.feePayer = new PublicKey(swapParams.account);
      signed = transaction;
    } else if (myWallet.isSlopeWallet) {
      const { msg, data } = await myWallet.signTransaction(
        bs58.encode(
          transaction.serializeMessage
            ? transaction.serializeMessage()
            : transaction.message.serialize()
        )
      );
      if (msg !== "ok") return;
      const bytes = bs58.decode(data.signature);
      transaction.signatures[0].signature = bytes;
      transaction.feePayer = new PublicKey(swapParams.account);
      signed = transaction;
    } else {
      signed = await myWallet.signTransaction(transaction);
    }

    let serializedTransaction = signed.serialize({
      verifySignatures: false,
      requireAllSignatures: false,
    });

    let blockhashWithExpiryBlockHeight;
    if (res.lastValidBlockHeight) {
      blockhashWithExpiryBlockHeight = {
        blockhash: transaction.message.recentBlockhash,
        lastValidBlockHeight: res.lastValidBlockHeight,
      };
    } else {
      const blockhashInfo = await connection.getLatestBlockhash();
      const { blockhash, lastValidBlockHeight } = blockhashInfo;
      blockhashWithExpiryBlockHeight = {
        blockhash: transaction._message
          ? transaction._message.recentBlockhash
          : blockhash,
        lastValidBlockHeight: lastValidBlockHeight + 400,
      };
    }

    const transactionResponse = await transactionSenderAndConfirmationWaiter({
      serializedTransaction,
      blockhashWithExpiryBlockHeight,
    });
    if (transactionResponse && transactionResponse.response) {
      if (transactionResponse.response.meta?.err) {
        instanct.change({
          message: transactionResponse.response.meta?.err,
          chainCode,
          status: "rejected",
        });
        // throw new Error(transactionResponse.response.meta?.err);
      } else {
        const { txid } = transactionResponse;
        if (txid) {
          instanct.change({
            message: "submit",
            chainCode,
            hash: txid,
            status: "success",
          });
        }
      }
    } else {
      instanct.change({
        message: "Transaction not confirmed.",
        chainCode,
        status: "rejected",
      });
      // throw new Error('Transaction not confirmed.');
    }
  } catch (e) {
    instanct.change({
      message: e.message,
      chainCode,
      status: "rejected",
    });
  }
}

export function launchCalc(params) {
  const { amount, isReverse } = params;
  const tokenOut = launchCalcTokenOut(amount, isReverse);
  return tokenOut ? decimals2Amount(tokenOut.toNumber(), isReverse ? 9 : 6) : 0;
}

export async function buy(params) {
  const {
    account,
    mintAddress,
    baseAmount,
    priorityFeeInSol,
    slippageDecimal,
    quoteAsset,
    coinData
  } = params;
  const chainCode = "solana";
  const instanct = showLoading({
    text: "Obtaining token information and quotation",
  });
  if (!coinData) {
    console.error("Failed to retrieve coin data...");
    return;
  }
  const solInLamports = amount2Decimals(baseAmount, 9);
  if (coinData.raydium_pool) {
    return await swap(instanct, account, {
      inTokenSymbol: "SOL",
      inTokenAddress: "So11111111111111111111111111111111111111112",
      outTokenSymbol: coinData.symbol,
      outTokenAddress: coinData.mint,
      amount: solInLamports,
    });
  }
  try {
    const tokenAmount = Math.floor(
      (solInLamports * coinData["virtual_token_reserves"]) /
        coinData["virtual_sol_reserves"]
    );
    const _tokenAmount = decimals2Amount(tokenAmount, quoteAsset.decimals);
    instanct.change({
      status: "loading",
      chainCode,
      text: `Swaping ${baseAmount} SOL for ${_tokenAmount} ${coinData.symbol}`,
    });
    const owner = new PublicKey(account);
    const mint = new PublicKey(mintAddress);
    const txBuilder = new Transaction();
    
    // fee
    const feeInstruction = getFeeInstruction(owner);
    txBuilder.add(feeInstruction);

    const tokenAccountAddress = await getAssociatedTokenAddress(
      mint,
      owner,
      false
    );
    const tokenAccountInfo = await connection.getAccountInfo(
      tokenAccountAddress
    );
    let tokenAccount;
    if (!tokenAccountInfo) {
      txBuilder.add(
        createAssociatedTokenAccountInstruction(
          owner,
          tokenAccountAddress,
          owner,
          mint
        )
      );
      tokenAccount = tokenAccountAddress;
    } else {
      tokenAccount = tokenAccountAddress;
    }
    const solInWithSlippage = baseAmount * (1 + slippageDecimal);
    const maxSolCost = Math.floor(amount2Decimals(solInWithSlippage, 9));
    const ASSOCIATED_USER = tokenAccount;
    const USER = owner;
    const BONDING_CURVE = new PublicKey(coinData["bonding_curve"]);
    const ASSOCIATED_BONDING_CURVE = new PublicKey(
      coinData["associated_bonding_curve"]
    );

    const keys = [
      { pubkey: GLOBAL, isSigner: false, isWritable: false },
      { pubkey: FEE_RECIPIENT, isSigner: false, isWritable: true },
      { pubkey: mint, isSigner: false, isWritable: false },
      { pubkey: BONDING_CURVE, isSigner: false, isWritable: true },
      { pubkey: ASSOCIATED_BONDING_CURVE, isSigner: false, isWritable: true },
      { pubkey: ASSOCIATED_USER, isSigner: false, isWritable: true },
      { pubkey: USER, isSigner: false, isWritable: true },
      { pubkey: SYSTEM_PROGRAM_ID, isSigner: false, isWritable: false },
      { pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false },
      { pubkey: RENT, isSigner: false, isWritable: false },
      { pubkey: PUMP_FUN_ACCOUNT, isSigner: false, isWritable: false },
      { pubkey: PUMP_FUN_PROGRAM, isSigner: false, isWritable: false },
    ];

    const data = concatArrayBuffers([
      bufferFromUInt64("16927863322537952870"),
      bufferFromUInt64(tokenAmount),
      bufferFromUInt64(maxSolCost),
    ]);

    const instruction = new TransactionInstruction({
      keys: keys,
      programId: PUMP_FUN_PROGRAM,
      data: data,
    });
    txBuilder.add(instruction);

    const transaction = await createTransaction(
      connection,
      txBuilder.instructions,
      owner,
      priorityFeeInSol
    );
    let signed = await myWallet.signTransaction(transaction);
    let serializedTransaction = signed.serialize({
      verifySignatures: false,
      requireAllSignatures: false,
    });
    const transactionResponse = await transactionSenderAndConfirmationWaiter({
      serializedTransaction,
    });
    if (transactionResponse && transactionResponse.response) {
      if (transactionResponse.response.meta?.err) {
        instanct.change({
          message: transactionResponse.response.meta?.err,
          chainCode,
          status: "rejected",
        });
        // throw new Error(transactionResponse.response.meta?.err);
      } else {
        const { txid } = transactionResponse;
        if (txid) {
          instanct.change({
            message: "submit",
            chainCode,
            hash: txid,
            status: "success",
          });
        }
      }
    } else {
      instanct.change({
        message: "Transaction not confirmed.",
        chainCode,
        status: "rejected",
      });
      // throw new Error('Transaction not confirmed.');
    }
  } catch (e) {
    instanct.change({
      message: e.message,
      chainCode,
      status: "rejected",
    });
  }
}

export async function sell(params) {
  const {
    account,
    mintAddress,
    tokenAmount,
    priorityFeeInSol,
    slippageDecimal,
    quoteAsset,
    coinData
  } = params;
  const chainCode = "solana";
  const instanct = showLoading({
    text: "Obtaining token information and quotation",
  });
  if (!coinData) {
    console.error("Failed to retrieve coin data...");
    return;
  }
  const tokenOut = amount2Decimals(tokenAmount, quoteAsset.decimals);
  if (coinData.raydium_pool) {
    return await swap(instanct, account, {
      inTokenSymbol: coinData.symbol,
      inTokenAddress: coinData.mint,
      outTokenSymbol: "SOL",
      outTokenAddress: "So11111111111111111111111111111111111111112",
      amount: tokenOut,
    });
  }
  try {
    const minSolOutput = Math.floor(
      (tokenOut * (1 - slippageDecimal) * coinData["virtual_sol_reserves"]) /
        coinData["virtual_token_reserves"]
    );
    const minSolOutputAmount = decimals2Amount(minSolOutput, 9);
    instanct.change({
      status: "loading",
      chainCode,
      text: `Swaping ${tokenAmount} ${coinData.symbol} for ${minSolOutputAmount} SOL`,
    });
    const owner = new PublicKey(account);
    const mint = new PublicKey(mintAddress);
    const txBuilder = new Transaction();

    // fee
    const feeInstruction = getFeeInstruction(owner);
    txBuilder.add(feeInstruction);

    const tokenAccountAddress = await getAssociatedTokenAddress(
      mint,
      owner,
      false
    );
    const tokenAccountInfo = await connection.getAccountInfo(
      tokenAccountAddress
    );

    let tokenAccount;
    if (!tokenAccountInfo) {
      txBuilder.add(
        createAssociatedTokenAccountInstruction(
          owner,
          tokenAccountAddress,
          owner,
          mint
        )
      );
      tokenAccount = tokenAccountAddress;
    } else {
      tokenAccount = tokenAccountAddress;
    }
    const keys = [
      { pubkey: GLOBAL, isSigner: false, isWritable: false },
      { pubkey: FEE_RECIPIENT, isSigner: false, isWritable: true },
      { pubkey: mint, isSigner: false, isWritable: false },
      {
        pubkey: new PublicKey(coinData["bonding_curve"]),
        isSigner: false,
        isWritable: true,
      },
      {
        pubkey: new PublicKey(coinData["associated_bonding_curve"]),
        isSigner: false,
        isWritable: true,
      },
      { pubkey: tokenAccount, isSigner: false, isWritable: true },
      { pubkey: owner, isSigner: false, isWritable: true },
      { pubkey: SYSTEM_PROGRAM_ID, isSigner: false, isWritable: false },
      { pubkey: ASSOC_TOKEN_ACC_PROG, isSigner: false, isWritable: false },
      { pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false },
      { pubkey: PUMP_FUN_ACCOUNT, isSigner: false, isWritable: false },
      { pubkey: PUMP_FUN_PROGRAM, isSigner: false, isWritable: false },
    ];

    const data = concatArrayBuffers([
      bufferFromUInt64("12502976635542562355"),
      bufferFromUInt64(tokenOut),
      bufferFromUInt64(minSolOutput),
    ]);

    const instruction = new TransactionInstruction({
      keys: keys,
      programId: PUMP_FUN_PROGRAM,
      data: data,
    });
    txBuilder.add(instruction);

    const transaction = await createTransaction(
      connection,
      txBuilder.instructions,
      owner,
      priorityFeeInSol
    );
    let signed = await myWallet.signTransaction(transaction);
    let serializedTransaction = signed.serialize({
      verifySignatures: false,
      requireAllSignatures: false,
    });
    const transactionResponse = await transactionSenderAndConfirmationWaiter({
      serializedTransaction,
    });
    if (transactionResponse && transactionResponse.response) {
      if (transactionResponse.response.meta?.err) {
        instanct.change({
          message: transactionResponse.response.meta?.err,
          chainCode,
          status: "rejected",
        });
        // throw new Error(transactionResponse.response.meta?.err);
      } else {
        const { txid } = transactionResponse;
        if (txid) {
          instanct.change({
            message: "submit",
            chainCode,
            hash: txid,
            status: "success",
          });
        }
      }
    } else {
      instanct.change({
        message: "Transaction not confirmed.",
        chainCode,
        status: "rejected",
      });
      // throw new Error('Transaction not confirmed.');
    }
  } catch (e) {
    instanct.change({
      message: e.message,
      chainCode,
      status: "rejected",
    });
  }
}

export function calc(params) {
  const {
    amount,
    direction,
    label,
    item,
    quoteAsset,
    baseAsset,
    bondingCurve,
    account,
    cb
  } = params;
  let tokenOut = 0;
  const { virtual_token_reserves, virtual_sol_reserves, raydium_pool } = item;
  if (raydium_pool) {
    const chainCode = "solana";
    const swapParams = {
      slippage: 100,
      account,
      // referrer: "0x3487ef9f9b36547e43268b8f0e2349a226c70b53",
      // referrerFee: 30,
      disableRfq: 1
    };
    if (direction === 'buy') {
      if (label === baseAsset.symbol) {
        Object.assign(swapParams, {
          inTokenSymbol: quoteAsset.symbol,
          inTokenAddress: quoteAsset.address,
          outTokenSymbol: baseAsset.symbol,
          outTokenAddress: baseAsset.address,
          amount: amount2Decimals(amount, quoteAsset.decimals),
        })
      } else {
        Object.assign(swapParams, {
          inTokenSymbol: baseAsset.symbol,
          inTokenAddress: baseAsset.address,
          outTokenSymbol: quoteAsset.symbol,
          outTokenAddress: quoteAsset.address,
          amount: amount2Decimals(amount, baseAsset.decimals),
        })
      }
    } else if (direction === 'sell') {
      Object.assign(swapParams, {
        inTokenSymbol: quoteAsset.symbol,
        inTokenAddress: quoteAsset.address,
        outTokenSymbol: baseAsset.symbol,
        outTokenAddress: baseAsset.address,
        amount: amount2Decimals(amount, quoteAsset.decimals),
      })
    }
    getSwap(chainCode, swapParams).then(res => {
      const { outAmount, outToken } = res;
      const { decimals } = outToken || {};
      const _outAmount = decimals2Amount(outAmount, decimals || 18);
      return cb && cb(_outAmount);
    })
    return 0;
  }
  const { realTokenReserves } = bondingCurve;
  const virtualSolReserves = new BigNumber(virtual_sol_reserves);
  const virtualTokenReserves = new BigNumber(virtual_token_reserves);
  const feeBasisPoints = new BigNumber(100).div(1e4);
  if (direction === "buy") {
    if (label === "SOL") {
      const _amount = amount2Decimals(amount, quoteAsset.decimals);
      const e = BigNumber.min(_amount, realTokenReserves);
      tokenOut = e.multipliedBy(virtualSolReserves).div(virtualTokenReserves.minus(e).plus(1));
      tokenOut = tokenOut.multipliedBy(new BigNumber(1).plus(feeBasisPoints)).toNumber();
      tokenOut = decimals2Amount(tokenOut, baseAsset.decimals);
    } else {
      const e = amount2Decimals(amount, 9);
      const n = virtualSolReserves.multipliedBy(virtualTokenReserves);
      const r = virtualSolReserves.plus(e);
      const o = n.div(r).plus(1);
      const a = virtualTokenReserves.minus(o);
      tokenOut = BigNumber.min(a, realTokenReserves);
      tokenOut = decimals2Amount(tokenOut.toNumber(), quoteAsset.decimals);
    }
  } else {
    const _amount = amount2Decimals(amount, quoteAsset.decimals);
    console.log('amt', _amount);
    const amt = new BigNumber(_amount);
    tokenOut = amt.multipliedBy(virtual_sol_reserves).div(new BigNumber(virtual_token_reserves).plus(_amount));
    tokenOut = tokenOut.multipliedBy(new BigNumber(1).minus(feeBasisPoints)).toNumber();
    tokenOut = decimals2Amount(tokenOut, baseAsset.decimals)
  }
  return tokenOut;
}

export async function launch(params) {
  const {
    account, ipfsData, amount, isReverse
  } = params;
  const { metadata, metadataUri } = ipfsData || {};
  const { name, symbol } = metadata || {};
  const uri = metadataUri;
  const chainCode = "solana";
  const instanct = showLoading({
    text: "Obtaining token information and quotation",
  });
  try {
    const owner = new PublicKey(account);

    //Create new wallet to be used as mint
    const mint = Keypair.generate();

    const [bondingCurve, bondingCurveBump] = PublicKey.findProgramAddressSync(
        [Buffer.from("bonding-curve"), mint.publicKey.toBuffer()],
        PUMP_FUN_PROGRAM
    );

    const [associatedBondingCurve, associatedBondingCurveBump] = PublicKey.findProgramAddressSync(
        [
            bondingCurve.toBuffer(),
            new PublicKey("TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA").toBuffer(),
            mint.publicKey.toBuffer()
        ],
        new PublicKey("ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL")
    );

    const [metadata, metadataBump] = PublicKey.findProgramAddressSync(
        [Buffer.from("metadata"), MPL_TOKEN_METADATA.toBuffer(), mint.publicKey.toBuffer()],
        MPL_TOKEN_METADATA
    );

    const keys = [
        { pubkey: mint.publicKey, isSigner: true, isWritable: true }, // Mint account
        { pubkey: MINT_AUTHORITY, isSigner: false, isWritable: false }, // Mint authority
        { pubkey: bondingCurve, isSigner: false, isWritable: true }, // Bonding curve PDA
        { pubkey: associatedBondingCurve, isSigner: false, isWritable: true }, // Associated bonding curve PDA
        { pubkey: GLOBAL, isSigner: false, isWritable: false }, // Global config
        { pubkey: MPL_TOKEN_METADATA, isSigner: false, isWritable: false }, // Metadata program ID
        { pubkey: metadata, isSigner: false, isWritable: true }, // Metadata PDA
        { pubkey: owner, isSigner: true, isWritable: true }, // Owner account
        { pubkey: SYSTEM_PROGRAM_ID, isSigner: false, isWritable: false }, // System program
        { pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false }, // Token program
        { pubkey: ASSOCIATED_TOKEN_PROGRAM_ID, isSigner: false, isWritable: false }, // Associated token account program
        { pubkey: RENT, isSigner: false, isWritable: false }, // Rent sysvar
        { pubkey: PUMP_FUN_ACCOUNT, isSigner: false, isWritable: false }, // Pump fun account
        { pubkey: PUMP_FUN_PROGRAM, isSigner: false, isWritable: false } // Pump fun program ID
    ];

    const nameBuffer = bufferFromString(name);
    const symbolBuffer = bufferFromString(symbol);
    const uriBuffer = bufferFromString(uri);

    const data = concatArrayBuffers([
        hexStringToUint8Array("181ec828051c0777"),
        nameBuffer,
        symbolBuffer,
        uriBuffer
    ]);

    const instruction = new TransactionInstruction({
        keys: keys,
        programId: PUMP_FUN_PROGRAM,
        data: data
    });

    const instructions = [];
    instructions.push(instruction);

    if (+amount > 0) {
      let solIn, tokenOut;
      if (isReverse) {
        solIn = launchCalcTokenOut(amount, isReverse);
        solIn = solIn.toNumber();
        tokenOut = amount2Decimals(amount, 6);
      } else {
        tokenOut = launchCalcTokenOut(amount, isReverse);
        tokenOut = tokenOut.toNumber();
        solIn = amount2Decimals(amount, 9);
      }
      const tokenAccountAddress = await getAssociatedTokenAddress(
        mint.publicKey,
        owner,
        false
      );
      const tokenAccountInfo = await connection.getAccountInfo(
        tokenAccountAddress
      );
      let tokenAccount;
      if (!tokenAccountInfo) {
        instructions.push(
          createAssociatedTokenAccountInstruction(
            owner,
            tokenAccountAddress,
            owner,
            mint.publicKey
          )
        );
        tokenAccount = tokenAccountAddress;
      } else {
        tokenAccount = tokenAccountAddress;
      }
      const slippageDecimal = 0.2;
      const solInWithSlippage = solIn * (1 + slippageDecimal);
      const maxSolCost = Math.floor(amount2Decimals(solInWithSlippage, 9));
      const ASSOCIATED_USER = tokenAccount;
      const USER = owner;
      const keys = [
        { pubkey: GLOBAL, isSigner: false, isWritable: false },
        { pubkey: FEE_RECIPIENT, isSigner: false, isWritable: true },
        { pubkey: mint.publicKey, isSigner: false, isWritable: false },
        { pubkey: bondingCurve, isSigner: false, isWritable: true },
        { pubkey: associatedBondingCurve, isSigner: false, isWritable: true },
        { pubkey: ASSOCIATED_USER, isSigner: false, isWritable: true },
        { pubkey: USER, isSigner: false, isWritable: true },
        { pubkey: SYSTEM_PROGRAM_ID, isSigner: false, isWritable: false },
        { pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false },
        { pubkey: RENT, isSigner: false, isWritable: false },
        { pubkey: PUMP_FUN_ACCOUNT, isSigner: false, isWritable: false },
        { pubkey: PUMP_FUN_PROGRAM, isSigner: false, isWritable: false },
      ];

      const data = concatArrayBuffers([
        bufferFromUInt64("16927863322537952870"),
        bufferFromUInt64(tokenOut),
        bufferFromUInt64(maxSolCost),
      ]);

      const instruction = new TransactionInstruction({
        keys: keys,
        programId: PUMP_FUN_PROGRAM,
        data: data,
      });
      instructions.push(instruction);
    }

    const recentBlockhash = await connection.getLatestBlockhash("finalized")
    .then(res => res.blockhash);
    const messageV0 = new TransactionMessage({
        payerKey: owner,
        recentBlockhash: recentBlockhash,
        instructions: [
            ComputeBudgetProgram.setComputeUnitLimit({ units: 250000 }),
            null,
            ComputeBudgetProgram.setComputeUnitPrice({ microLamports: 100000 }),
            ...instructions
        ].filter(Boolean)
    }).compileToV0Message();
    let transaction = new VersionedTransaction(messageV0);
    transaction.sign([mint]);
    // const transaction = await createTransactionV2(connection, txBuilder.instructions, owner);
    const signed = await myWallet.signTransaction(transaction);
    const serializedTransaction = signed.serialize({
      verifySignatures: false,
      requireAllSignatures: false
    });
    const transactionResponse = await transactionSenderAndConfirmationWaiter({
      serializedTransaction,
    });
    if (transactionResponse && transactionResponse.response) {
      if (transactionResponse.response.meta?.err) {
        instanct.change({
          message: transactionResponse.response.meta?.err,
          chainCode,
          status: "rejected",
        });
        // throw new Error(transactionResponse.response.meta?.err);
      } else {
        const { txid } = transactionResponse;
        if (txid) {
          instanct.change({
            message: "submit",
            chainCode,
            hash: txid,
            address: mint.publicKey.toString(),
            status: "success",
          });
        }
      }
    } else {
      instanct.change({
        message: "Transaction not confirmed.",
        chainCode,
        status: "rejected",
      });
      // throw new Error('Transaction not confirmed.');
    }
  } catch (e) {
    instanct.change({
      message: e.message,
      chainCode,
      status: "rejected",
    });
  }
}


