import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import { tryConnect } from "@/init";
import { WalletList } from "@/utils/connect";
import { isApp } from "@/utils/helper";

const Home = () => import("../pages/Home");
const Gems = () => import("../pages/Gems");
const Asset = () => import("../pages/Asset");
const BrandKit = () => import("../pages/BrandKit");
const Referral = () => import("../pages/Referral");
const SmartMoney = () => import("../pages/SmartMoney");
const Account = () => import("../pages/Account");
const CopyTrading = () => import("../pages/CopyTrading");


Vue.use(VueRouter);

let routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/:chain/:source/:mint",
    name: "Asset",
    component: Asset,
  },
  {
    path: "/gems",
    name: "Gems",
    component: Gems,
  },
  {
    path: "/brand-kit",
    name: "BrandKit",
    component: BrandKit,
  },
  {
    path: "/referral",
    name: "Referral",
    component: Referral,
  },
  {
    path: "/smart-money",
    name: "SmartMoney",
    component: SmartMoney,
  },
  {
    path: "/smart-money/:address",
    name: "Account",
    component: Account,
  },
  {
    path: "/copy-trading",
    name: "CopyTrading",
    component: CopyTrading,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const { state } = store;
  const { wallet, account } = state;
  if (account) {
    let walletObj = WalletList.find((item) => {
      return item.code === wallet;
    })
    if (walletObj) {
      tryConnect(walletObj.code, walletObj.connect,true);
    }
  } else if (isApp()) {
    // const { code, connect } = WalletList[0];
    // tryConnect(code, connect);
  }
  return next();
});

export default router;
