import IconEth from "@/assets/chains/eth.svg";
import IconBNB from "@/assets/chains/bnb.svg";
import IconMetis from "@/assets/chains/metis.svg";
import IconFantom from "@/assets/chains/fantom.svg";
import IconPolygon from "@/assets/chains/polygon.svg";
import IconGnosis from "@/assets/chains/gnosis.svg";
import IconAvalanche from "@/assets/chains/avalanche.svg";
import IconHarmony from "@/assets/chains/harmony.svg";
import IconArbitrum from "@/assets/chains/arbitrum.svg";
import IconOptimism from "@/assets/chains/optimism.svg";
import IconMoonriver from "@/assets/chains/moonriver.svg";
import IconAurora from "@/assets/chains/aurora.svg";
import IconCronos from "@/assets/chains/cronos.svg";
import IconOsmosis from "@/assets/chains/osmosis.svg";
import IconSifchain from "@/assets/chains/sifchain.svg";
import IconAptos from "@/assets/chains/aptos.svg";
import IconKava from "@/assets/chains/kava.svg";
import IconCelo from "@/assets/chains/celo.svg";
import IconKlaytn from "@/assets/chains/klaytn.svg";
import IconLinea from "@/assets/chains/linea.svg";
import IconZkSync from "@/assets/chains/zksync.svg";
import IconTelos from "@/assets/chains/telos.svg";
import IconScroll from "@/assets/chains/scroll.svg";
import IconBase from "@/assets/chains/base.svg";
import IconOpBNB from "@/assets/chains/bnb.svg";
import IconMantle from "@/assets/chains/mantle.png";
import IconManta from "@/assets/chains/manta.svg";
import IconBlastSepolia from "@/assets/chains/blast.svg";
import IconMode from "@/assets/chains/mode.svg";
import IconPulseChain from "@/assets/chains/pulse.svg";
import IconMerlinChain from "@/assets/chains/merlin.png";
import IconRootstock from "@/assets/chains/rootstock.svg";
import IconSei from "@/assets/chains/sei.svg";
import IconSolana from "@/assets/chains/solana.svg";

export const ChainList = [
  {
    chainName: "Ethereum",
    chainCode: "eth",
    chainId: 1,
    chainIcon: IconEth,
    blockExplorerName: "Etherscan",
    blockExplorerUrls: "https://etherscan.io/tx/",
    rpcUrls: [
      "https://eth.llamarpc.com"
    ],
    nativeCurrency: { decimals: 18, name: "Ethereum", symbol: "ETH" },
  },
  {
    chainName: "BNB Chain",
    chainCode: "bsc",
    chainId: 56,
    chainIcon: IconBNB,
    blockExplorerName: "BscScan",
    blockExplorerUrls: "https://bscscan.com/tx/",
    rpcUrls: [
      "https://bsc-dataseed1.bnbchain.org"
    ],
    nativeCurrency: { decimals: 18, name: "Ethereum", symbol: "ETH" },
  },
  {
    chainName: "Arbitrum",
    chainCode: "arbitrum",
    chainId: 42161,
    chainIcon: IconArbitrum,
    blockExplorerName: "Arbiscan",
    blockExplorerUrls: "https://arbiscan.io/tx/",
    rpcUrls: [
      "https://arbitrum.llamarpc.com"
    ],
    nativeCurrency: { decimals: 18, name: "Ethereum", symbol: "ETH" },
  },
  {
    chainName: "Optimism",
    chainCode: "optimism",
    chainId: 10,
    chainIcon: IconOptimism,
    blockExplorerName: "Optimism Explorer",
    blockExplorerUrls: "https://optimistic.etherscan.io/tx/",
    rpcUrls: [
      "https://optimism.llamarpc.com"
    ],
    nativeCurrency: { decimals: 18, name: "Ethereum", symbol: "ETH" },
  },
  {
    chainName: "zkSync Era",
    chainCode: "zksync",
    chainId: 324,
    chainIcon: IconZkSync,
    blockExplorerName: "zkSync era Explorer",
    blockExplorerUrls: "https://explorer.zksync.io/tx/",
    rpcUrls: [
      "https://zksync.meowrpc.com"
    ],
    nativeCurrency: { decimals: 18, name: "Ethereum", symbol: "ETH" },
  },
  {
    chainName: "Polygon",
    chainCode: "polygon",
    chainId: 137,
    chainIcon: IconPolygon,
    blockExplorerName: "Polygon Explorer",
    blockExplorerUrls: "https://polygonscan.com/tx/",
    rpcUrls: [
      "https://polygon.llamarpc.com"
    ],
    nativeCurrency: { decimals: 18, name: "Ethereum", symbol: "ETH" },
  },
  {
    chainName: "Avalanche",
    chainCode: "avax",
    chainId: 43114,
    chainIcon: IconAvalanche,
    blockExplorerName: "Avalanche Explorer",
    blockExplorerUrls: "https://cchain.explorer.avax.network/tx/",
    rpcUrls: [
      "https://avax.meowrpc.com"
    ],
    nativeCurrency: { decimals: 18, name: "Ethereum", symbol: "ETH" },
  },
  {
    chainName: "Fantom",
    chainCode: "fantom",
    chainId: 250,
    chainIcon: IconFantom,
    blockExplorerName: "Fantom Explorer",
    blockExplorerUrls: "https://ftmscan.com/tx/",
    rpcUrls: [
      "https://rpc.fantom.network"
    ],
    nativeCurrency: { decimals: 18, name: "Ethereum", symbol: "ETH" },
  },
  {
    chainName: "Solana Mainnet",
    chainCode: "solana",
    chainIcon: IconSolana,
    blockExplorerName: "SolScan",
    blockExplorerUrls: "https://solscan.io/tx/",
    rpcUrls: [],
    nativeCurrency: { decimals: 9, name: "Solana", symbol: "SOL" },
  },
  {
    chainName: "Base",
    chainCode: "base",
    chainId: 8453,
    chainIcon: IconBase,
    blockExplorerName: "Base Explorer",
    blockExplorerUrls: "https://basescan.org/tx/",
    rpcUrls: [
      "https://base.llamarpc.com"
    ],
    nativeCurrency: { decimals: 18, name: "Ethereum", symbol: "ETH" },
  },
  {
    chainName: "Linea",
    chainCode: "linea",
    chainId: 59144,
    chainIcon: IconLinea,
    blockExplorerName: "Linea Explorer",
    blockExplorerUrls: "https://lineascan.build/tx/",
    rpcUrls: [
      "https://linea-rpc.publicnode.com"
    ],
    nativeCurrency: { decimals: 18, name: "Ethereum", symbol: "ETH" },
  },
  {
    chainName: "Scroll",
    chainCode: "scroll",
    chainId: 534352,
    chainIcon: IconScroll,
    blockExplorerName: "Scroll Explorer",
    blockExplorerUrls: "https://blockscout.scroll.io/tx/",
    rpcUrls: [
      "https://rpc.ankr.com/scroll"
    ],
    nativeCurrency: { decimals: 18, name: "Ethereum", symbol: "ETH" },
  },
  {
    chainName: "Blast",
    chainCode: "blast",
    chainId: 81457,
    chainIcon: IconBlastSepolia,
    blockExplorerName: "Blast Explorer",
    blockExplorerUrls: "https://blastscan.io/tx/",
    rpcUrls: [],
    nativeCurrency: { decimals: 18, name: "Ethereum", symbol: "ETH" },
  },
  {
    chainName: "Mode",
    chainCode: "mode",
    chainId: 34443,
    chainIcon: IconMode,
    blockExplorerName: "Mode Explorer",
    blockExplorerUrls: "https://explorer.mode.network/tx/",
    rpcUrls: [],
    nativeCurrency: { decimals: 18, name: "Ethereum", symbol: "ETH" },
  },
  {
    chainName: "Mantle",
    chainCode: "mantle",
    chainId: 5000,
    chainIcon: IconMantle,
    blockExplorerName: "Mantle Explorer",
    blockExplorerUrls: "https://explorer.mantle.xyz/tx/",
    rpcUrls: [],
    nativeCurrency: { decimals: 18, name: "Ethereum", symbol: "ETH" },
  },
  {
    chainName: "Manta Pacific",
    chainCode: "manta",
    chainId: 169,
    chainIcon: IconManta,
    blockExplorerName: "Manta Explorer",
    blockExplorerUrls: "https://pacific-explorer.manta.network/tx/",
    rpcUrls: [],
    nativeCurrency: { decimals: 18, name: "Ethereum", symbol: "ETH" },
  },
  {
    chainName: "Polygon zkEVM",
    chainCode: "polygon_zkevm",
    chainId: 1101,
    chainIcon: IconPolygon,
    blockExplorerName: "Polygon zkEVM Explorer",
    blockExplorerUrls: "https://zkevm.polygonscan.com/tx/",
    rpcUrls: ["https://rpc.ankr.com/polygon_zkevm"],
    nativeCurrency: { decimals: 18, name: "Ethereum", symbol: "ETH" },
  },
  {
    chainName: "Telos",
    chainCode: "telos",
    chainId: 40,
    chainIcon: IconTelos,
    blockExplorerName: "Telos Explorer",
    blockExplorerUrls: "https://teloscan.io/tx/",
    rpcUrls: [],
    nativeCurrency: { decimals: 18, name: "Ethereum", symbol: "ETH" },
  },
  {
    chainName: "Gnosis",
    chainCode: "xdai",
    chainId: 100,
    chainIcon: IconGnosis,
    blockExplorerName: "Gnosis Explorer",
    blockExplorerUrls: "https://blockscout.com/poa/xdai/tx/",
    rpcUrls: [],
    nativeCurrency: { decimals: 18, name: "Ethereum", symbol: "ETH" },
  },
  {
    chainName: "Rootstock",
    chainCode: "rootstock",
    chainId: 30,
    chainIcon: IconRootstock,
    blockExplorerName: "Rootstock Explorer",
    blockExplorerUrls: "https://explorer.rsk.co/tx/",
    rpcUrls: [],
    nativeCurrency: { decimals: 18, name: "Ethereum", symbol: "ETH" },
  },
  {
    chainName: "Kava",
    chainCode: "kava",
    chainId: 2222,
    chainIcon: IconKava,
    blockExplorerName: "Kava Explorer",
    blockExplorerUrls: "https://explorer.kava.io/tx/",
    rpcUrls: [],
    nativeCurrency: { decimals: 18, name: "Ethereum", symbol: "ETH" },
  },
  {
    chainName: "Sei",
    chainCode: "sei",
    chainId: 1329,
    chainIcon: IconSei,
    blockExplorerName: "Sei Explorer",
    blockExplorerUrls: "https://seitrace.com/tx/",
    rpcUrls: [],
    nativeCurrency: { decimals: 18, name: "Ethereum", symbol: "ETH" },
  },
  {
    chainName: "Metis",
    chainCode: "metis",
    chainId: 1088,
    chainIcon: IconMetis,
    blockExplorerName: "Metis Explorer",
    blockExplorerUrls: "https://andromeda-explorer.metis.io/tx/",
    rpcUrls: [],
    nativeCurrency: { decimals: 18, name: "Ethereum", symbol: "ETH" },
  },
  {
    chainName: "opBNB",
    chainCode: "opbnb",
    chainId: 204,
    chainIcon: IconOpBNB,
    blockExplorerName: "opBNB Explorer",
    blockExplorerUrls: "https://mainnet.opbnbscan.com/tx/",
    rpcUrls: [],
    nativeCurrency: { decimals: 18, name: "Ethereum", symbol: "ETH" },
  },
  {
    chainName: "Celo",
    chainCode: "celo",
    chainId: 42220,
    chainIcon: IconCelo,
    blockExplorerName: "Celo Explorer",
    blockExplorerUrls: "https://explorer.celo.org/tx/",
    rpcUrls: [],
    nativeCurrency: { decimals: 18, name: "Ethereum", symbol: "ETH" },
  },
  {
    chainName: "Cronos",
    chainCode: "cronos",
    chainId: 25,
    chainIcon: IconCronos,
    blockExplorerUrls: "https://cronos.org/explorer/tx/",
    rpcUrls: [],
    nativeCurrency: { decimals: 18, name: "Ethereum", symbol: "ETH" },
  },
  {
    chainName: "Aptos",
    chainCode: "aptos",
    chainId: "aptos",
    chainIcon: IconAptos,
    blockExplorerName: "Aptos Explorer",
    blockExplorerUrls: "https://explorer.aptoslabs.com/txn/",
    rpcUrls: [],
    nativeCurrency: { decimals: 18, name: "Ethereum", symbol: "ETH" },
  },
  {
    chainName: "Aurora",
    chainCode: "aurora",
    chainId: 1313161554,
    chainIcon: IconAurora,
    blockExplorerName: "Aurora Explorer",
    blockExplorerUrls: "https://aurorascan.dev/tx/",
    rpcUrls: [],
    nativeCurrency: { decimals: 18, name: "Ethereum", symbol: "ETH" },
  },
  {
    chainName: "Moonriver",
    chainCode: "moonriver",
    chainId: 1285,
    chainIcon: IconMoonriver,
    blockExplorerName: "Moonriver Explorer",
    blockExplorerUrls: "https://moonriver.moonscan.io/tx/",
    rpcUrls: [],
    nativeCurrency: { decimals: 18, name: "Ethereum", symbol: "ETH" },
  },
  {
    chainName: "Harmony",
    chainCode: "harmony",
    chainId: 1666600000,
    chainIcon: IconHarmony,
    blockExplorerName: "Harmony Explorer",
    blockExplorerUrls: "https://explorer.harmony.one/tx/",
    rpcUrls: [],
    nativeCurrency: { decimals: 18, name: "Ethereum", symbol: "ETH" },
  },
  {
    chainName: "Merlin Chain",
    chainCode: "merlin",
    chainId: 4200,
    chainIcon: IconMerlinChain,
    blockExplorerName: "Merlin Chain Explorer",
    blockExplorerUrls: "https://scan.merlinchain.io/tx/",
    rpcUrls: [],
    nativeCurrency: { decimals: 18, name: "Ethereum", symbol: "ETH" },
  },
  {
    chainName: "PulseChain",
    chainCode: "pulse",
    chainId: 369,
    chainIcon: IconPulseChain,
    blockExplorerName: "PulseChain Explorer",
    blockExplorerUrls: "https://scan.pulsechain.com/tx/",
    rpcUrls: [],
    nativeCurrency: { decimals: 18, name: "Ethereum", symbol: "ETH" },
  },
  {
    chainName: "Klaytn",
    chainCode: "klaytn",
    chainId: 8217,
    chainIcon: IconKlaytn,
    blockExplorerName: "Klaytn Explorer",
    blockExplorerUrls: "https://scope.klaytn.com/tx/",
    rpcUrls: [],
    nativeCurrency: { decimals: 18, name: "Ethereum", symbol: "ETH" },
  },
  {
    chainName: "Sifchain",
    chainCode: "sifchain",
    chainId: "sifchain-1",
    chainIcon: IconSifchain,
    blockExplorerName: "Sifchain Explorer",
    blockExplorerUrls: "https://www.mintscan.io/sifchain/txs/",
    rpcUrls: [],
    nativeCurrency: { decimals: 18, name: "Ethereum", symbol: "ETH" },
  },
  {
    chainName: "Osmosis",
    chainCode: "osmosis",
    chainId: "osmosis-1",
    chainIcon: IconOsmosis,
    blockExplorerName: "Osmosis Explorer",
    blockExplorerUrls: "https://www.mintscan.io/osmosis/txs/",
    rpcUrls: [],
    nativeCurrency: { decimals: 18, name: "Ethereum", symbol: "ETH" },
  },
  {
    chainName: "Arbitrum Sepolia",
    chainCode: "arbitrum_sepolia",
    chainId: 42161,
    chainIcon: IconArbitrum,
    blockExplorerName: "Arbitrum Sepolia Explorer",
    blockExplorerUrls: "https://sepolia.arbiscan.io/tx/",
    rpcUrls: [],
    nativeCurrency: { decimals: 18, name: "Ethereum", symbol: "ETH" },
  },
];

export const getChainMap = () => {
  const map = {};
  ChainList.forEach((item) => {
    map[item.chainId] = item;
  });
  return map;
};

export const getChainById = (chainId) => {
  return ChainList.find((item) => {
    return item.chainId === +chainId;
  });
};

export const getChainByCode = (chainCode) => {
  return ChainList.find((item) => {
    return item.chainCode === chainCode;
  });
};

export const getChainCodeById = (chainId) => {
  const { chainCode } = ChainList.find((item) => {
    return item.chainId === chainId;
  });
  return chainCode;
};

export const getChainIdByCode = (chainCode) => {
  const { chainId } = ChainList.find((item) => {
    return item.chainCode === chainCode;
  });
  return chainId;
};

export const getBlockExplorerNameByCode = (chainCode) => {
  const { blockExplorerName } = ChainList.find((item) => {
    return item.chainCode === chainCode;
  });
  return blockExplorerName;
};

export const getBlockExplorerPathByCode = (chainCode, path) => {
  const { blockExplorerUrls } = ChainList.find((item) => {
    return item.chainCode === chainCode;
  });
  return blockExplorerUrls.replace(/\/tx\/$/gi, `${path}`);
};

export const getBlockExplorerUrlByCode = (chainCode, txHash) => {
  const { blockExplorerUrls } = ChainList.find((item) => {
    return item.chainCode === chainCode;
  });
  return `${blockExplorerUrls}${txHash}`;
};

export const getAvailableChainList = () => {
  return ChainList.filter((item) => {
    return !item.disabled;
  });
};
