<template>
  <span>{{ ageStr }}</span>
</template>

<script>
import { formatTimestampToOneUnit } from "@/utils/filters";
import { mapState } from "vuex";

export default {
  props: {
    age: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapState({
      nowTime: (state) => state.nowTime
    }),
    ageStr() {
      return formatTimestampToOneUnit(this.age, this.nowTime);
    },
  },
};
</script>
