<template>
  <div class="header-bar">
    <div class="back g-clickable-small" @click="back">
      <svg viewBox="0 0 1024 1024">
        <path
          d="M927.858848 478.061027 168.863431 478.061027l308.721247-308.86758c11.685133-11.69025 11.435447-30.881334-0.557702-42.868343-11.992125-11.985986-31.18321-12.226462-42.868343-0.536212L75.199191 484.928435c-1.994424 1.662873-3.773953 3.583618-5.294586 5.713119-4.047176 5.373381-6.013971 11.839653-5.9055 18.328437-0.12996 7.794523 2.725064 15.561417 8.595772 21.432125l361.565802 361.744881c11.685133 11.691273 30.876218 11.449773 42.868343-0.535189 11.993149-11.985986 12.242835-31.17707 0.557702-42.868343L168.441828 539.454282l759.417019 0c16.527418 0 29.925566-13.738909 29.925566-30.694069S944.386266 478.061027 927.858848 478.061027z"
          fill="currentColor"
        ></path>
      </svg>
      <span>Back</span>
    </div>
    <div class="icon" v-if="icon">
      <img :src="icon" />
    </div>
    <div class="title" :class="{ noicon: !icon }" v-if="title">
      <span>{{ title }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
  },
  methods: {
    back() {
      window.history.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.header-bar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.back {
  height: 36px;
  padding: 0 24px;
  border-radius: 18px;
  border: 1px solid rgba(255, 255, 255, 0.2);

  display: flex;
  align-items: center;
  justify-content: flex-start;

  &:hover {
    border: 1px solid var(--color-blue);
    background-color: var(--color-blue);
  }

  svg {
    height: 14px;
    color: var(--color-content);
    margin-right: 10px;
  }

  span {
    font-size: 16px;
    color: var(--color);
  }
}

.icon {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  margin-right: 6px;
  img {
    height: 32px;
  }
}

.title {
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &.noicon {
    margin-left: 20px;
  }

  span {
    font-size: 20px;
    font-weight: 600;
    color: var(--color);
    white-space: nowrap;
  }
}
</style>