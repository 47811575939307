import { render, staticRenderFns } from "./HeaderItem.vue?vue&type=template&id=36a1d531&scoped=true"
import script from "./HeaderItem.vue?vue&type=script&lang=js"
export * from "./HeaderItem.vue?vue&type=script&lang=js"
import style0 from "./HeaderItem.vue?vue&type=style&index=0&id=36a1d531&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36a1d531",
  null
  
)

export default component.exports