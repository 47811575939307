
import { formatWithCommas } from "@/utils/helper";

export function toFixed (value, decimals) {
  if (value === 0) return (+value).toFixed(decimals || 2);
  if (value == "" || value == undefined) return "";
  return formatWithCommas((+value).toFixed(decimals || 2));
}

export function formatAccount (account, key) {
  if (key) {
    return account && account.length > 6
      ? account.substring(0, 3) + "**" + account.substring(account.length - 3)
      : account;
  }
  return account && account.length > 10
    ? account.substring(0, 6) + "****" + account.substring(account.length - 4)
    : account;
}

export function formatHash (hash) {
  if (!hash) return "--";
  return hash.substring(0, 8) + "****" + hash.substring(hash.length - 6);
}

export function formatDate (time, Delimiter) {
  Delimiter = Delimiter || '/';
  let now = null
  if (typeof time === 'string' && time.indexOf('-') > 0) {
    now = new Date(time);
  } else if (Number(time) < new Date().getTime() / 1000) {
    now = new Date(time * 1000);
  } else {
    now = new Date(time);
  }

  var year = now.getFullYear() + '';
  var month = now.getMonth() + 1 + '';
  var date = now.getDate() + '';
  var hour = now.getHours() + '';
  var minute = now.getMinutes() + '';
  var second = now.getSeconds() + '';

  month = month.length < 2 ? '0' + month : month;
  date = date.length < 2 ? '0' + date : date;
  hour = hour.length < 2 ? '0' + hour : hour;
  minute = minute.length < 2 ? '0' + minute : minute;
  second = second.length < 2 ? '0' + second : second;

  // return month + Delimiter + date + Delimiter + year + " " + hour + ":" + minute + ":" + second;
  return month + Delimiter + date + " " + hour + ":" + minute + ":" + second;
}

export function formatNumber (number, dec = 2, dsep, tsep) {
  if (number == 0) return 0
  if (isNaN(+number) || number == null) return '';
  number = parseFloat(number)
  number = number.toFixed(~~dec);
  tsep = typeof tsep == 'string' ? tsep : ',';

  var parts = number.split('.'),
    fnums = parts[0],
    decimals = parts[1] ? (dsep || '.') + parts[1] : '';
  var num = fnums.replace(/(\d)(?=(?:\d{3})+$)/g, '$1' + tsep) + decimals

  return num;
}

export function nFormatter (num, digits, lessNum) {
  var units = ['k', 'M', 'B', 'T', 'P', 'E', 'Z', 'Y'],
    decimal;
  if (!isNaN(lessNum) & +num < lessNum) {
    return '< ' + lessNum;
  }
  if (isNaN(num)) {
    return 0;
  }
  if (+num < 1000) {
    return formatNumber(num, digits);
  }
  for (var i = units.length - 1; i >= 0; i--) {
    decimal = Math.pow(1000, i + 1);

    if (num <= -decimal || num >= decimal) {
      const val = +(num / decimal).toFixed(digits || 2);
      return (/\./.test(val) ? (val || "").toString().replace(/0$/gi, '') : val) + units[i];
    }
  }

  return num;
}


export function formatTimestampToOneUnit (timestamp, nowTime) {
  let time = nowTime
  time = parseInt(new Date().getTime() - timestamp)

  const secondsInMinute = 60;
  const secondsInHour = secondsInMinute * 60;
  const secondsInDay = secondsInHour * 24;
  const secondsInMonth = secondsInDay * 30;
  const secondsInYear = secondsInDay * 365;

  const seconds = parseInt(time / 1000);
  // return seconds
  if (seconds >= secondsInYear) {
    const years = Math.floor(seconds / secondsInYear);
    return `${years}Y`;
  } else if (seconds >= secondsInMonth) {
    const months = Math.floor(seconds / secondsInMonth);
    return `${months}M`;
  } else if (seconds >= secondsInDay) {
    const days = Math.floor(seconds / secondsInDay);
    return `${days}d`;
  } else if (seconds >= secondsInHour) {
    const hours = Math.floor(seconds / secondsInHour);
    return `${hours}h`;
  } else if (seconds >= secondsInMinute) {
    const minutes = Math.floor(seconds / secondsInMinute);
    return `${minutes}m`;
  } else {
    const remainingSeconds = Math.floor(seconds);
    return `${remainingSeconds}s`;
  }
}


export function formatScientificNotation (num, significantDigits = 2) {
  const numStr = num.toString();

  const regex = new RegExp(`^0\\.(0+)(\\d{0,${significantDigits}})`);
  const match = numStr.match(regex);

  if (match && match[1].length > 2) {
    const zeroCount = match[1].length;
    const digits = match[2];
    const subscriptZeroCount = String(zeroCount)
      .split("")
      .map(char => String.fromCharCode(8320 + parseInt(char)))
      .join("");
    return `0.0${subscriptZeroCount}${digits}`;
  }

  return num.toFixed(significantDigits);
}
