<template>
  <NouDialog>
    <div class="g-dialog-panel loading">
      <div class="g-dialog-title">
        <span>{{ title }}</span>
        <div class="g-clickable close" @click="close">
          <i class="iconfont icon-close"></i>
        </div>
      </div>
      <div class="content">
        <div class="message loading" v-if="status === 'loading'">
          <div class="icon">
            <svg
              version="1.1"
              id="L3"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 100 100"
              enable-background="new 0 0 0 0"
              xml:space="preserve"
            >
              <circle
                fill="none"
                stroke="var(--color-des)"
                stroke-width="3"
                cx="50"
                cy="50"
                r="44"
                style="opacity: 0.5"
              ></circle>
              <circle
                fill="var(--color)"
                stroke="var(--card)"
                stroke-width="3"
                cx="7"
                cy="55"
                r="6"
              >
                <animateTransform
                  attributeName="transform"
                  dur="1.5s"
                  type="rotate"
                  from="0 50 48"
                  to="360 50 52"
                  repeatCount="indefinite"
                ></animateTransform>
              </circle>
            </svg>
          </div>
          <span class="text">{{ text }}</span>
          <!-- <span class="desc">{{ message || "Confirm this transaction in you wallet" }}</span> -->
          <span class="desc">{{ message }}</span>
        </div>
        <div class="message success" v-if="status === 'success'">
          <div class="icon">
            <svg viewBox="0 0 400 400" class="success">
              <circle
                class="circle"
                fill="none"
                stroke="var(--color-green)"
                stroke-width="12"
                cx="200"
                cy="200"
                r="180"
                stroke-linecap="round"
                transform="rotate(-90,200,200)"
              />
              <polyline
                class="tick"
                fill="none"
                stroke="var(--color-green)"
                stroke-width="20"
                points="88,214 173,284 304,138"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <a :href="blockExplorerUrl" target="_blank">
            <span>View on {{ blockExplorerName }}</span>
          </a>
          <a :href="'/' + address" v-if="address">
            <span>View details</span>
          </a>
          <div class="g-clickable button" @click="close">
            <span>Close</span>
          </div>
        </div>
        <div class="message rejected" v-if="status === 'rejected'">
          <div class="icon">
            <svg viewBox="0 0 400 400" class="rejected">
              <circle
                class="circle"
                fill="none"
                stroke="#EA4848"
                stroke-width="12"
                cx="200"
                cy="200"
                r="180"
                stroke-linecap="round"
                transform="rotate(-90,200,200)"
              />
              <polyline
                class="tick"
                fill="none"
                stroke="#EA4848"
                stroke-width="18"
                points="125,125 275,275"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <polyline
                class="tick2"
                fill="none"
                stroke="#EA4848"
                stroke-width="18"
                points="275,125 125,275"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <span class="text">{{ text }}</span>
          <span class="desc">{{ message }}</span>
          <div class="g-clickable button" @click="close">
            <span>Close</span>
          </div>
        </div>
      </div>
    </div>
  </NouDialog>
</template>

<script>
import NouDialog from "@/components/common/NouDialog";
import { getBlockExplorerUrlByCode, getBlockExplorerNameByCode } from "@/utils/chain";

const TITLE  = {
  "loading": "Pending Transaction",
  "success": "Transaction Sent",
  "rejected": "Transaction Rejected",
}
export default {
  components: {
    NouDialog,
  },
  data(vm) {
    const { params } = vm.$options || {};
    const { status = "loading", text, message, name } = params || {};
    return {
      message,
      text,
      hash: "",
      address: "",
      status,
      name,
      blockExplorerUrl: "",
      blockExplorerName: ""
    };
  },
  computed:{
    title(){
      return TITLE[this.status];
    }
  },
  methods: {
    close() {
      document.body.style.overflow = "auto";
      this.$el.parentNode.removeChild(this.$el);
    },
    change(params) {
      const { chainCode, text, message, hash, address, status } = params || {};
      this.blockExplorerUrl = getBlockExplorerUrlByCode(chainCode, hash);
      this.blockExplorerName = getBlockExplorerNameByCode(chainCode);
      this.text = text || this.text;
      this.message = message;
      this.hash = hash;
      this.status = status;
      this.address = address;
    },
  },
  beforeDestroy() {
    this.$el.parentNode.removeChild(this.$el);
  },
};
</script>

<style lang="scss" scoped>
.g-dialog-title {
  span {
    font-size: 20px;
  }
}

.icon {
  margin: 50px auto;
  svg {
    width: 100px;
  }
}

.message {
  display: flex;
  align-content: center;
  flex-direction: column;

  .text {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
    color: var(--color);
    margin-bottom: 15px;
    text-align: center;
  }

  .name {
    font-size: 14px;
    font-weight: 500;
    color: var(--color-des);
    padding-top: 20px;
    text-align: center;
  }

  .desc {
    font-size: 14px;
    line-height: 1.5;
    color: var(--color-content);
    margin-bottom: 10px;
    word-break: break-all;
    text-align: center;
  }

  a {
    text-decoration: none;
    color: var(--color-sky-blue);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;

    span {
      font-size: 16px;
      font-weight: 500;
      text-decoration: underline;
    }
    i {
      margin-left: 5px;
      font-size: 20px;
    }
  }

  &.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &.success,
  &.rejected,
  &.failed {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .button {
      margin-top: 30px;
    }
  }
}

.button {
  height: 50px;
  border-radius: 25px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  background-color: var(--button);
  transition: all 0.3s ease;
  &:hover {
    opacity: 0.8;
  }

  span {
    font-size: 15px;
    font-weight: 600;
    color: var(--color);
  }
}

.success {
  .circle {
    stroke-dasharray: 1194;
    stroke-dashoffset: 1194;
    animation: circle 1s ease-in-out;
    animation-fill-mode: forwards;
  }

  .tick {
    stroke-dasharray: 350;
    stroke-dashoffset: 350;
    animation: tick 0.8s ease-in-out;
    animation-fill-mode: forwards;
    animation-delay: 0.95s;
  }
}

.rejected,
.failed {
  .circle {
    stroke-dasharray: 1194;
    stroke-dashoffset: 1194;
    animation: circle 0.8s ease-in-out;
    animation-fill-mode: forwards;
  }
  .tick {
    stroke-dasharray: 350;
    stroke-dashoffset: 350;
    animation: tick 0.25s ease-in-out;
    animation-fill-mode: forwards;
    animation-delay: 1.05s;
  }
  .tick2 {
    stroke-dasharray: 350;
    stroke-dashoffset: 350;
    animation: tick 0.25s ease-in-out;
    animation-fill-mode: forwards;
    animation-delay: 0.85s;
  }
}

@keyframes tick {
  from {
    stroke-dashoffset: 350;
  }
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes circle {
  from {
    stroke-dashoffset: 1194;
  }
  to {
    stroke-dashoffset: 2388;
  }
}
</style>
