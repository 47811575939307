<template>
  <div class="balances">
    <div class="net-worth">
      <div class="value">
        <span>net worth</span>
        <span>${{ total | nFormatter(2) }}</span>
      </div>
      <div class="icon g-clickable" @click="refresh">
        <svg viewBox="0 0 19 19" id="arrow_loading">
          <path stroke="none" fill="rgba(255,255,255,0.05)"
            d="M17.61907,6.64429064 L13.8617197,6.64429064 C13.3840439,6.64429064 12.9914138,6.25155686 12.9914138,5.77398471 C12.9914138,5.2963089 13.3840439,4.90357512 13.8617197,4.90357512 L15.3689095,4.90357512 C14.0103035,2.98248087 11.7707673,1.73006538 9.2340637,1.73006538 C5.0840575,1.73006538 1.7194412,5.09468172 1.7194412,9.24468791 C1.7194412,13.3946681 5.0840575,16.7592845 9.2340637,16.7592845 C13.3840439,16.7592845 16.74866,13.3946681 16.74866,9.24468791 C16.74866,8.76701211 17.141394,8.37438191 17.61907,8.37438191 C18.096616,8.37438191 18.48935,8.76701211 18.48935,9.24468791 C18.48935,14.3498902 14.3498902,18.4893498 9.2446879,18.4893498 C4.139356,18.4893498 0,14.3498902 0,9.24468791 C0,4.13935601 4.139356,0 9.2446879,0 C12.3438798,0 15.0823402,1.52833455 16.759284,3.86343695 L16.759284,2.31382802 C16.759284,1.83615222 17.152018,1.44352209 17.62959,1.44352209 C18.10724,1.44352209 18.5,1.83615222 18.5,2.31382802 L18.5,5.78450529 C18.478726,6.26218109 18.096616,6.64429064 17.61907,6.64429064 Z">
          </path>
          <defs>
            <path id="arrow" stroke="none" fill="none"
              d="M17.61907,6.64429064 L13.8617197,6.64429064 C13.3840439,6.64429064 12.9914138,6.25155686 12.9914138,5.77398471 C12.9914138,5.2963089 13.3840439,4.90357512 13.8617197,4.90357512 L15.3689095,4.90357512 C14.0103035,2.98248087 11.7707673,1.73006538 9.2340637,1.73006538 C5.0840575,1.73006538 1.7194412,5.09468172 1.7194412,9.24468791 C1.7194412,13.3946681 5.0840575,16.7592845 9.2340637,16.7592845 C13.3840439,16.7592845 16.74866,13.3946681 16.74866,9.24468791 C16.74866,8.76701211 17.141394,8.37438191 17.61907,8.37438191 C18.096616,8.37438191 18.48935,8.76701211 18.48935,9.24468791 C18.48935,14.3498902 14.3498902,18.4893498 9.2446879,18.4893498 C4.139356,18.4893498 0,14.3498902 0,9.24468791 C0,4.13935601 4.139356,0 9.2446879,0 C12.3438798,0 15.0823402,1.52833455 16.759284,3.86343695 L16.759284,2.31382802 C16.759284,1.83615222 17.152018,1.44352209 17.62959,1.44352209 C18.10724,1.44352209 18.5,1.83615222 18.5,2.31382802 L18.5,5.78450529 C18.478726,6.26218109 18.096616,6.64429064 17.61907,6.64429064 Z">
            </path>
            <clipPath id="arrow-clip">
              <use xlink:href="#arrow"></use>
            </clipPath>
          </defs>
          <g clip-path="url(#arrow-clip)">
            <circle cx="7.02" cy="7.02" r="4.69" transform="rotate(365,12,12)" fill="none" stroke="currentColor" stroke-width="16" stroke-dasharray="30" stroke-dashoffset="0">
              <animate attributeName="stroke-dashoffset" values="0;-30" begin="arrow_loading.click; 0.7s" repeatCount="indefinite" dur="9.3s"></animate>
            </circle>
          </g>
          <use xlink:href="#arrow"></use>
          <animateTransform id="transform_0" attributeName="transform" attributeType="XML" type="rotate" from="0 0 0" to="-10 0 0" dur="0.07s" begin="arrow_loading.click;" repeatCount="1"></animateTransform>
          <animateTransform id="transform_1" attributeName="transform" attributeType="XML" type="rotate" from="-45 0 0" to="390 0 0" dur="0.6s" begin="transform_0.end" repeatCount="1"></animateTransform>
          <animateTransform id="transform_2" attributeName="transform" attributeType="XML" type="rotate" from="390 0 0" to="360 0 0" dur="0.15s" begin="transform_1.end" repeatCount="1"></animateTransform>
        </svg>
      </div>
    </div>
    <div class="loading" v-if="loading">
      <div class="g-loader"></div>
    </div>
    <SoarEmpty v-if="!loading && !list.length" />
    <a :href="`/solana/${item.source || 'pump'}/${item.address}`" target="_blank" class="item g-clickable" v-for="(item, i) in filterList" :key="i">
      <div class="icon">
        <img :src="item.icon || iconDefault" />
        <span>{{ item.symbol }}</span>
      </div>
      <div class="balance" v-if="item.symbol === 'SOL'">
        <span>${{ authSolBalance * item.price | nFormatter(4) }}</span>
        <span>{{ (authSolBalance || 0) | nFormatter(4) }}</span>
      </div>
      <div class="balance" v-else>
        <span>${{ item.balance * item.price | nFormatter(4) }}</span>
        <span>{{ (item.balance || 0) | nFormatter(4) }}</span>
      </div>
      <!-- <div class="icon end">
        <svg viewBox="0 0 36 36">
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path
              d="M19.8561938,17.596128 C19.958535,17.596128 20.0583637,17.6278283 20.1419542,17.6868736 L25.4406676,21.42984 C25.6640861,21.5876866 25.7172544,21.8967256 25.5594293,22.1201442 C25.5290278,22.1631816 25.4919533,22.2010921 25.4496043,22.2324456 L20.1509125,26.1554389 C19.9310471,26.3182168 19.6208883,26.2719396 19.4581319,26.0520957 C19.394987,25.9668086 19.3609041,25.8634956 19.3609044,25.757377 L19.3609044,23.2336839 L15.0290606,23.2336839 C11.9419004,23.2512343 10.2655397,21.7255493 10,18.6566502 C10.268253,18.9375224 11.4406891,20.594739 15.0290606,20.594739 C17.4213082,20.594739 18.8652705,20.5672397 19.3609044,20.5123057 L19.3609044,18.0914174 C19.3609044,17.8178669 19.5826433,17.596128 19.8561938,17.596128 Z"
              fill="currentColor"
            ></path>
            <path
              d="M16.5418681,9.20058562 C16.605013,9.28587268 16.6390959,9.38918571 16.6390956,9.49530432 L16.6390956,12.0189975 L20.9709394,12.0189975 C24.0580996,12.001447 25.7344603,13.527132 26,16.5960311 C25.731747,16.315159 24.5593109,14.6579423 20.9709394,14.6579423 C18.5786918,14.6579423 17.1347295,14.6854416 16.6390956,14.7403757 L16.6390956,17.1612639 C16.6390956,17.4348144 16.4173567,17.6565533 16.1438062,17.6565533 C16.041465,17.6565533 15.9416363,17.624853 15.8580458,17.5658077 L10.5593324,13.8228413 C10.3359139,13.6649948 10.2827456,13.3559557 10.4405707,13.1325372 C10.4709722,13.0894997 10.5080467,13.0515892 10.5503957,13.0202357 L15.8490875,9.09726395 C16.0689529,8.93448602 16.3791117,8.98076327 16.5418681,9.20060715 L16.5418681,9.20058562 Z"
              fill="currentColor"
            ></path>
          </g>
        </svg>
      </div> -->
    </a>
  </div>
</template>

<script>
import SoarTable from "@/components/SoarTable";
import SoarEmpty from "@/components/SoarEmpty";
import { decimals2Amount } from "@/utils/number";
import { getTokenList } from "@/api/account";
import { getBalance, getBalances } from "@/init";
import { debounce } from "@/utils/helper";

export default {
  components: {
    SoarTable,
    SoarEmpty,
  },
  data () {
    return {
      keys: ["Token", "Balance"],
      list: [],
      loading: true,
      decimals2Amount
    };
  },
  computed: {
    filterList () {
      return this.list.filter(item => {
        return item.balance > 0;
      })
    },
    total () {
      let sum = 0;
      this.list.forEach(item => {
        if (item.balance && item.price) {
          sum += item.balance * item.price;
        }
      });
      return sum;
    }
  },
  watch: {
    account () {
      this.getUserBalances();
    },
  },
  mounted () {

    this.getBlances()
  },
  methods: {
    getBlances () {
      if (this.getBlanceTime) clearTimeout(this.getBlanceTime)
      this.getUserBalances();
      this.loadAcountBalance();
      this.getBlanceTime = setTimeout(this.getBlances, 9300)
    },
    async getUserBalances () {
      if (!this.account) {
        this.list = [];
        this.loading = false;
        return;
      }
      const list = await getTokenList();
      if (list[0].symbol === "SOL") {
        list[0].balance = this.authSolBalance;
      }
      this.loading = false;
      if (!list || !list.length) return [];
      this.$set(this, "list", list);
    },
    async loadAcountBalance () {
      await getBalance(
        this.authAddress,
        "So11111111111111111111111111111111111111112"
      );
    },
    refresh: debounce(async function () {
      console.log('refresh');
      this.getBlances()
    }, 500),
  },
  beforeDestroy () {
    if (this.getBlanceTime) {
      console.log('beforeDestroy getBlanceTime')
      clearTimeout(this.getBlanceTime)
    }
  }
};
</script>

<style lang="scss" scoped>
.balances {
  height: calc(100vh - 400px);
  margin-right: -10px;
  padding-right: 10px;
  overflow: auto;

  .net-worth {
    height: 72px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--border-color);
    border-radius: 10px;
    margin: 20px 0 10px;

    .value {
      display: flex;
      flex-direction: column;

      span {
        &:first-child {
          font-size: 14px;
          margin-bottom: 5px;
          color: var(--color-content);
          text-transform: uppercase;
        }

        &:last-child {
          font-size: 32px;
          font-weight: 600;
          color: var(--color);
        }
      }
    }

    .icon {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  .item {
    display: grid;
    grid-gap: 5px;
    grid-template-columns: 1fr auto;
    background: var(--card);
    padding: 15px;
    border-radius: 16px;
    margin-top: 10px;
    text-decoration: none;

    &:hover {
      background: var(--border-color);
    }

    .icon {
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &.end {
        justify-content: flex-end;
      }

      svg,
      img {
        width: 36px;
        height: 36px;
        border-radius: 18px;
      }

      svg {
        color: var(--color-des);
      }

      span {
        font-size: 16px;
        color: var(--color);
        margin-left: 10px;
      }
    }

    .balance {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      flex-direction: column;

      span {
        &:first-child {
          font-size: 16px;
          font-weight: 600;
          line-height: 1.5;
          color: var(--color);
        }

        &:last-child {
          font-size: 14px;
          color: var(--color-content);
        }
      }
    }
  }
}

.loading {
  height: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.g-loader {
  width: 56px;
  height: 56px;
  border-width: 3px;
}
</style>