<template>
  <SoarTable
    :keys="keys"
    :loading="loading"
    :customClass="customClass"
    :empty="list && list.length === 0"
  >
    <tr v-for="(item, i) in list" :key="i">
      <td>
        <div class="icon">
          <span>{{ item.text }}</span>
          <img :src="item.file_uri" />
        </div>
      </td>
      <td>{{ item.total_likes }}</td>
      <td>{{ item.timestamp | formatDate }}</td>
      <td>
        <a :href="`/${item.mint}`" target="_blank">
          {{ item.mint | formatAccount }}
        </a>
      </td>
    </tr>
  </SoarTable>
</template>

<script>
import SoarEmpty from "@/components/SoarEmpty";
import SoarTable from "@/components/SoarTable";
import { getUserReplies } from "@/api";

export default {
  components: {
    SoarTable,
    SoarEmpty,
  },
  data() {
    return {
      keys: ["Content", "Likes", "Time", "Mint"],
      list: [],
      loading: true,
      customClass: {
        extendProfile: true,
      },
    };
  },
  watch: {
    account() {
      this.getUserReplies();
    },
  },
  mounted() {
    this.getUserReplies();
  },
  methods: {
    async getUserReplies() {
      if (!this.account) {
        this.list = [];
        return;
      }
      const list = await getUserReplies(this.account);
      this.$set(this, "list", list);
      this.loading = false;
    },
  },
};
</script>
