import { Environment, FixedSide, Moonshot } from "@wen-moon-ser/moonshot-sdk";
import {
  ComputeBudgetProgram,
  SystemProgram,
  PublicKey,
  TransactionMessage,
  VersionedTransaction,
} from "@solana/web3.js";
import {
  connection,
  getFeeInstruction,
  transactionSenderAndConfirmationWaiter,
} from "@/swap/helper";
import { myWallet } from "@/init";
import showLoading from "@/components/loading";
import BigNumber from "bignumber.js";
import { decimals2Amount, amount2Decimals } from "@/utils/number";
import { getSwap } from "@/api/cross";
import { SOLANA_RPC } from "@/utils/config";

// 定义常量产品模型的配置
const CONSTANT_PRODUCT_CONFIG = {
    type: "CONSTANT_PRODUCT_V1",
    collateralDecimalsNumber: 9,
    marketCapDecimalsNumber: 9,
    coefficientBMinimalUnits: 25,
    tokenDecimalsNumber: 9,
    marketCapThreshold: new BigNumber(5e11),
    totalSupply: new BigNumber("1000000000000000000"),
    platformFeeBasisPoints: 100,
    displayMarketCapThreshold: new BigNumber(432e9)
};

class ConstantProductModel {
    constructor(options) {
        this.config = CONSTANT_PRODUCT_CONFIG;
        this.initialVirtualTokenReserves = new BigNumber("1073000000000000000");
        this.initialVirtualCollateralReserves = new BigNumber("30000000000");
        this.constantProduct = this.initialVirtualTokenReserves.multipliedBy(this.initialVirtualCollateralReserves);
        
        if (options && options.config) {
            this.setConfig(options.config);
        }
    }

    setConfig(newConfig) {
        this.config = {
            ...this.config,
            ...newConfig
        };
    }

    getConfig() {
        return this.config;
    }

    getTokensNumberFromCollateral(params) {
        if (params.direction === "buy") {
            const collateralAfterFee = params.collateralAmount.minus(
                params.collateralAmount.multipliedBy(this.config.platformFeeBasisPoints).dividedBy(10000)
            );
            return this.buyInCollateral(collateralAfterFee, params.curvePosition);
        }
        
        const collateralWithFee = params.collateralAmount.plus(
            params.collateralAmount.multipliedBy(this.config.platformFeeBasisPoints).dividedBy(10000)
        );
        return this.sellInCollateral(collateralWithFee, params.curvePosition);
    }

    getCollateralPriceForTokens(params) {
        let collateralAmount = 0;
        if (params.direction === "buy") {
            collateralAmount = this.buyInToken(params.tokenAmount, params.curvePosition);
            return collateralAmount.plus(
                collateralAmount.multipliedBy(this.config.platformFeeBasisPoints).dividedBy(10000)
            );
        }
        
        collateralAmount = this.sellInToken(params.tokenAmount, params.curvePosition);
        return collateralAmount.minus(
            collateralAmount.multipliedBy(this.config.platformFeeBasisPoints).dividedBy(10000)
        );
    }

    buyInCollateral(collateralAmount, curvePosition) {
        const [currentTokenReserves, currentCollateralReserves] = this.getCurrentReserves(curvePosition);
        const newCollateralReserves = currentCollateralReserves.plus(collateralAmount);
        const newTokenReserves = this.constantProduct.dividedBy(newCollateralReserves);
        const tokensDifference = currentTokenReserves.minus(newTokenReserves);
        return new BigNumber(tokensDifference.toFixed(0));
    }

    sellInCollateral(collateralAmount, curvePosition) {
        const [currentTokenReserves, currentCollateralReserves] = this.getCurrentReserves(curvePosition);
        const newCollateralReserves = currentCollateralReserves.minus(collateralAmount);
        const tokensToAdd = this.constantProduct.dividedBy(newCollateralReserves).minus(currentTokenReserves);
        return new BigNumber(tokensToAdd.toFixed(0));
    }

    buyInToken(tokenAmount, curvePosition) {
        const [currentTokenReserves, currentCollateralReserves] = this.getCurrentReserves(curvePosition);
        const newTokenReserves = currentTokenReserves.minus(tokenAmount);
        return this.constantProduct.dividedBy(newTokenReserves).minus(currentCollateralReserves);
    }

    sellInToken(tokenAmount, curvePosition) {
        const [currentTokenReserves, currentCollateralReserves] = this.getCurrentReserves(curvePosition);
        const newTokenReserves = currentTokenReserves.plus(tokenAmount);
        const newCollateralReserves = this.constantProduct.dividedBy(newTokenReserves);
        return currentCollateralReserves.minus(newCollateralReserves);
    }

    getCurrentReserves(curvePosition) {
        const currentTokenReserves = this.initialVirtualTokenReserves.minus(curvePosition);
        const currentCollateralReserves = this.constantProduct.dividedBy(currentTokenReserves);
        return [currentTokenReserves, currentCollateralReserves];
    }
}

/**
 * 计算交易成本
 * @param {Object} params - 交易参数
 * @param {number} params.amount - 交易数量
 * @param {string} params.buyType - 买入类型 ('quote' 或其他)
 * @param {string} params.direction - 交易方向 ('buy' 或 'sell')
 * @param {BigNumber} params.curvePosition - 曲线位置
 * @param {ConstantProductModel} params.curve - 交易曲线对象 (常量产品模型实例)
 * @returns {BigNumber|undefined} 计算得到的成本或数量
 */
function calculateTradingCost(params) {
    try {
        const { amount, buyType, direction, curvePosition, curve } = params;
        
        // 买入且买入类型为 'quote'
        if (direction === "buy" && buyType === "quote") {
            return curve.getTokensNumberFromCollateral({
                curvePosition: toBigNumber(curvePosition),
                direction: direction,
                collateralAmount: toDecimalAmount(amount, curve.getConfig().collateralDecimalsNumber)
            });
        }
        
        // 卖出
        if (direction === "sell") {
            const tokenAmount = toDecimalAmount(amount, curve.getConfig().tokenDecimalsNumber);
            let adjustedCurvePosition;
            
            if (curve.getConfig().type === "LINEAR_V1") {
                adjustedCurvePosition = toBigNumber(curvePosition).minus(tokenAmount);
            } else {
                adjustedCurvePosition = toBigNumber(curvePosition);
            }
            
            return curve.getCollateralPriceForTokens({
                curvePosition: adjustedCurvePosition.lt(0) ? toBigNumber(0) : adjustedCurvePosition,
                tokenAmount: tokenAmount,
                direction: direction
            });
        }
        
        // 其他情况（买入但买入类型不是 'quote'）
        return curve.getCollateralPriceForTokens({
            curvePosition: toBigNumber(curvePosition),
            tokenAmount: toDecimalAmount(amount, curve.getConfig().tokenDecimalsNumber),
            direction: direction
        });
    } catch (error) {
        // 出错时返回 undefined
        return undefined;
    }
}

/**
 * 将输入转换为 BigNumber 类型
 * @param {number|string|BigNumber} value - 要转换的值
 * @returns {BigNumber} 转换后的 BigNumber 对象
 */
function toBigNumber(value) {
    return new BigNumber(value);
}

/**
 * 将数量转换为指定小数位数的 BigNumber
 * @param {number|string} amount - 要转换的数量
 * @param {number} decimals - 小数位数
 * @returns {BigNumber} 转换后的 BigNumber 对象
 */
function toDecimalAmount(amount, decimals) {
    return new BigNumber(amount).multipliedBy(new BigNumber(10).pow(decimals));
}

async function swap (instanct, account, params) {
  const swapParams = {
    inTokenSymbol: params.inTokenSymbol,
    inTokenAddress: params.inTokenAddress,
    outTokenSymbol: params.outTokenSymbol,
    outTokenAddress: params.outTokenAddress,
    amount: params.amount,
    slippage: params.slippage || 100,
    account: account,
    // referrer: "0x3487ef9f9b36547e43268b8f0e2349a226c70b53",
    // referrerFee: 30,
    disableRfq: 1,
  };
  const chainCode = "solana";
  const res = await getSwap(chainCode, swapParams);
  const inAmount = decimals2Amount(res.inAmount, res.inToken.decimals);
  const outAmount = decimals2Amount(res.outAmount, res.outToken.decimals);
  instanct.change({
    status: "loading",
    chainCode,
    text: `Swaping ${inAmount} ${res.inToken.symbol} for ${outAmount} ${res.outToken.symbol}`,
  });
  try {
    let transaction = "";
    if (res.dexId == 6 || res.dexId == 7) {
      transaction = VersionedTransaction.deserialize(
        Buffer.from(res.transaction, "hex")
      );
    } else {
      transaction = Transaction.from(Buffer.from(res.transaction, "hex"));
    }
    let signed = null;
    if (myWallet.isCoin98) {
      const result = await myWallet.request({
        method: "sol_sign",
        params: [transaction],
      });
      console.log("Got signature, submitting transaction");
      const bytes = bs58.decode(result.signature);
      transaction.signatures[0].signature = bytes;
      transaction.feePayer = new PublicKey(swapParams.account);
      signed = transaction;
    } else if (myWallet.isSlopeWallet) {
      const { msg, data } = await myWallet.signTransaction(
        bs58.encode(
          transaction.serializeMessage
            ? transaction.serializeMessage()
            : transaction.message.serialize()
        )
      );
      if (msg !== "ok") return;
      const bytes = bs58.decode(data.signature);
      transaction.signatures[0].signature = bytes;
      transaction.feePayer = new PublicKey(swapParams.account);
      signed = transaction;
    } else {
      signed = await myWallet.signTransaction(transaction);
    }

    let serializedTransaction = signed.serialize({
      verifySignatures: false,
      requireAllSignatures: false,
    });

    let blockhashWithExpiryBlockHeight;
    if (res.lastValidBlockHeight) {
      blockhashWithExpiryBlockHeight = {
        blockhash: transaction.message.recentBlockhash,
        lastValidBlockHeight: res.lastValidBlockHeight,
      };
    } else {
      const blockhashInfo = await connection.getLatestBlockhash();
      const { blockhash, lastValidBlockHeight } = blockhashInfo;
      blockhashWithExpiryBlockHeight = {
        blockhash: transaction._message
          ? transaction._message.recentBlockhash
          : blockhash,
        lastValidBlockHeight: lastValidBlockHeight + 150,
      };
    }

    const transactionResponse = await transactionSenderAndConfirmationWaiter({
      serializedTransaction,
      blockhashWithExpiryBlockHeight,
    });
    if (transactionResponse && transactionResponse.response) {
      if (transactionResponse.response.meta?.err) {
        instanct.change({
          message: transactionResponse.response.meta?.err,
          chainCode,
          status: "rejected",
        });
        // throw new Error(transactionResponse.response.meta?.err);
      } else {
        const { txid } = transactionResponse;
        if (txid) {
          instanct.change({
            message: "submit",
            chainCode,
            hash: txid,
            status: "success",
          });
        }
      }
    } else {
      instanct.change({
        message: "Transaction not confirmed.",
        chainCode,
        status: "rejected",
      });
      // throw new Error('Transaction not confirmed.');
    }
  } catch (e) {
    instanct.change({
      message: e.message,
      chainCode,
      status: "rejected",
    });
  }
}

export const buy = async (params) => {
  const chainCode = "solana";
  const instanct = showLoading({
    text: "Obtaining token information and quotation",
  });
  const { account, mintAddress, baseAmount, tokenAmount, coinData } = params;
  const { progress } = coinData && coinData.moonshot || {};
  if (progress === 100) {
    const solInLamports = amount2Decimals(baseAmount, 9);
    return await swap(instanct, account, {
      inTokenSymbol: "SOL",
      inTokenAddress: "So11111111111111111111111111111111111111112",
      outTokenSymbol: coinData.symbol,
      outTokenAddress: coinData.mint,
      amount: solInLamports,
    });
  }
  try {
    const moonshot = new Moonshot({
      SOLANA_RPC,
      environment: Environment.MAINNET,
      chainOptions: {
        solana: { confirmOptions: { commitment: "confirmed" } },
      },
    });
    console.log("moonshot buy", moonshot);
    const token = moonshot.Token({
      mintAddress,
    });
    // const curvePos = await token.getCurvePosition();
    // console.log("moonshot buy curvePos", curvePos);
    const creator = new PublicKey(account);
    console.log("moonshot buy creator", creator);
    instanct.change({
      status: "loading",
      chainCode,
      text: `Swaping ${baseAmount} SOL for ${tokenAmount} ${coinData.symbol}`,
    });
    
    // fee
    const feeInstruction = getFeeInstruction(creator);
    
    const { ixs } = await token.prepareIxs({
      slippageBps: 500,
      creatorPK: creator.toBase58(),
      tokenAmount: BigInt(amount2Decimals(tokenAmount, 9)),
      collateralAmount: BigInt(amount2Decimals(baseAmount, 9)),
      tradeDirection: "BUY",
      fixedSide: FixedSide.OUT, // This means you will get exactly the token amount and slippage is applied to collateral amount
    });
    console.log("moonshot buy ixs", ixs);
    const priorityIx = ComputeBudgetProgram.setComputeUnitPrice({
      microLamports: 200_000,
    });
    console.log("moonshot buy priorityIx", priorityIx);
    const blockhash = await connection.getLatestBlockhash("confirmed");
    console.log("moonshot buy blockhash", blockhash);
    const messageV0 = new TransactionMessage({
      payerKey: creator,
      recentBlockhash: blockhash.blockhash,
      instructions: [feeInstruction, priorityIx, ...ixs],
    }).compileToV0Message();
    const transaction = new VersionedTransaction(messageV0);
    console.log("moonshot buy transaction", transaction);
    let signed = await myWallet.signTransaction(transaction);
    let serializedTransaction = signed.serialize({
      verifySignatures: false,
      requireAllSignatures: false,
    });
    const transactionResponse = await transactionSenderAndConfirmationWaiter({
      serializedTransaction,
    });
    if (transactionResponse && transactionResponse.response) {
      if (transactionResponse.response.meta?.err) {
        instanct.change({
          message: transactionResponse.response.meta?.err,
          chainCode,
          status: "rejected",
        });
        // throw new Error(transactionResponse.response.meta?.err);
      } else {
        const { txid } = transactionResponse;
        if (txid) {
          instanct.change({
            message: "submit",
            chainCode,
            hash: txid,
            status: "success",
          });
        }
      }
    } else {
      instanct.change({
        message: "Transaction not confirmed.",
        chainCode,
        status: "rejected",
      });
    }
  } catch (e) {
    instanct.change({
      message: e.message,
      chainCode,
      status: "rejected",
    });
  }
};

export const sell = async (params) => {
  const chainCode = "solana";
  const instanct = showLoading({
    text: "Obtaining token information and quotation",
  });
  const { account, mintAddress, baseAmount, tokenAmount, quoteAsset, coinData } = params;
  const { progress } = coinData && coinData.moonshot || {};
  if (progress === 100) {
    const tokenOut = amount2Decimals(tokenAmount, quoteAsset.decimals);
    return await swap(instanct, account, {
      inTokenSymbol: coinData.symbol,
      inTokenAddress: coinData.mint,
      outTokenSymbol: "SOL",
      outTokenAddress: "So11111111111111111111111111111111111111112",
      amount: tokenOut,
    });
  }
  try {
    const moonshot = new Moonshot({
      SOLANA_RPC,
      environment: Environment.MAINNET,
      chainOptions: {
        solana: { confirmOptions: { commitment: "confirmed" } },
      },
    });

    const token = moonshot.Token({
      mintAddress,
    });
    // const curvePos = await token.getCurvePosition();
    // console.log("Current position of the curve: ", curvePos); // Prints the current curve position

    // make sure creator has funds
    const creator = new PublicKey(account);
    console.log("moonshot sell creator", creator);
    
    instanct.change({
      status: "loading",
      chainCode,
      text: `Swaping ${tokenAmount} ${coinData.symbol} for ${baseAmount} SOL`,
    });
    
    // fee
    const feeInstruction = getFeeInstruction(creator);

    const { ixs } = await token.prepareIxs({
      slippageBps: 500,
      creatorPK: creator.toBase58(),
      tokenAmount: BigInt(amount2Decimals(tokenAmount, 9)),
      collateralAmount: BigInt(amount2Decimals(baseAmount, 9)),
      tradeDirection: "SELL",
      fixedSide: FixedSide.IN, // This means you will pay exactly the token amount slippage is applied to collateral amount
    });

    const priorityIx = ComputeBudgetProgram.setComputeUnitPrice({
      microLamports: 200_000,
    });

    const blockhash = await connection.getLatestBlockhash("confirmed");
    const messageV0 = new TransactionMessage({
      payerKey: creator,
      recentBlockhash: blockhash.blockhash,
      instructions: [feeInstruction, priorityIx, ...ixs],
    }).compileToV0Message();

    const transaction = new VersionedTransaction(messageV0);

    let signed = await myWallet.signTransaction(transaction);
    let serializedTransaction = signed.serialize({
      verifySignatures: false,
      requireAllSignatures: false,
    });
    const transactionResponse = await transactionSenderAndConfirmationWaiter({
      serializedTransaction,
    });
    if (transactionResponse && transactionResponse.response) {
      if (transactionResponse.response.meta?.err) {
        instanct.change({
          message: transactionResponse.response.meta?.err,
          chainCode,
          status: "rejected",
        });
        // throw new Error(transactionResponse.response.meta?.err);
      } else {
        const { txid } = transactionResponse;
        if (txid) {
          instanct.change({
            message: "submit",
            chainCode,
            hash: txid,
            status: "success",
          });
        }
      }
    } else {
      instanct.change({
        message: "Transaction not confirmed.",
        chainCode,
        status: "rejected",
      });
      // throw new Error('Transaction not confirmed.');
    }
  } catch (e) {
    instanct.change({
      message: e.message,
      chainCode,
      status: "rejected",
    });
  }
};

export const calcSimple = (params) => {
  const { direction, amount, baseAsset, label, item} = params;
  const { moonshot } = item;
  const { priceNative } = moonshot;
  const r = new BigNumber(priceNative);
  const n = new BigNumber(amount);
  const buyType = direction === 'sell' || label === baseAsset.symbol ? 'base' : 'quote';
  let e = 0;
  if (direction === 'buy') {
    if (buyType === 'base') {
      e = n.times(r);
    } else if (buyType === 'quote') {
      e = n.dividedBy(r);
    }
  } else if (direction === 'sell') {
    e = n.times(r);
  }
  // e = amount2Decimals(result, decimals);
  const t = new BigNumber(3);
  if (direction === 'buy' && buyType === 'base') {
    e = e.multipliedBy(t.dividedBy(100).plus(1));
  } else {
    e = e.dividedBy(t.dividedBy(100).plus(1));
  }
  return e;
};

export const calc = (params) => {
  const { amount, direction, label, item, quoteAsset, slippage, baseAsset, account, cb } = params;
  const { moonshot } = item;
  if (!moonshot) return 0;
  
  const { progress, curvePosition } = moonshot;
  if (progress === 100) {
    const chainCode = "solana";
    const swapParams = {
      slippage: 100,
      account,
      // referrer: "0x3487ef9f9b36547e43268b8f0e2349a226c70b53",
      // referrerFee: 30,
      disableRfq: 1
    };
    if (direction === 'buy') {
      if (label === baseAsset.symbol) {
        Object.assign(swapParams, {
          inTokenSymbol: quoteAsset.symbol,
          inTokenAddress: quoteAsset.address,
          outTokenSymbol: baseAsset.symbol,
          outTokenAddress: baseAsset.address,
          amount: amount2Decimals(amount, quoteAsset.decimals),
        })
      } else {
        Object.assign(swapParams, {
          inTokenSymbol: baseAsset.symbol,
          inTokenAddress: baseAsset.address,
          outTokenSymbol: quoteAsset.symbol,
          outTokenAddress: quoteAsset.address,
          amount: amount2Decimals(amount, baseAsset.decimals),
        })
      }
    } else if (direction === 'sell') {
      Object.assign(swapParams, {
        inTokenSymbol: quoteAsset.symbol,
        inTokenAddress: quoteAsset.address,
        outTokenSymbol: baseAsset.symbol,
        outTokenAddress: baseAsset.address,
        amount: amount2Decimals(amount, quoteAsset.decimals),
      })
    }
    getSwap(chainCode, swapParams).then(res => {
      const { outAmount, outToken } = res;
      const { decimals } = outToken || {};
      const _outAmount = decimals2Amount(outAmount, decimals || 18);
      return cb && cb(_outAmount);
    })
    return 0;
  }
  if (!curvePosition) {
    return calcSimple(params);
  }
  const buyType = direction === 'sell' || label === baseAsset.symbol ? 'base' : 'quote';
  const curve = new ConstantProductModel();
  const tradingCost = calculateTradingCost({
      amount,
      buyType,
      direction,
      curvePosition: new BigNumber(curvePosition),
      curve: curve
  });
  const _slippage = new BigNumber(slippage);
  // : e.dividedBy(t.dividedBy(100).plus(1))
  console.log('Trading cost:', tradingCost.toString());
  let result, _tradingCost;
  if (buyType === 'base') {
    _tradingCost = decimals2Amount(tradingCost, baseAsset.decimals);
    result = new BigNumber(_tradingCost).multipliedBy(_slippage.dividedBy(100).plus(1)).toFixed(6);
  } else {
    _tradingCost = decimals2Amount(tradingCost, quoteAsset.decimals);
    result = new BigNumber(_tradingCost).dividedBy(_slippage.dividedBy(100).plus(1)).toFixed(6);
  }
  console.log('Trading cost:', result);
  return result;
}

export const launch = async (params) => {
  console.log("launch", params);
  return 0;
};
