<template>
  <div class="transaction">
    <div class="loading" v-if="loading">
      <div class="g-loader"></div>
    </div>
    <SoarEmpty v-if="!loading && !list.length" />
    <div class="item g-clickable" v-for="(item, i) in list" :key="i">
      <div class="left" @click="go(item)">
        <div class="icon">
          <img :src="item.token.icon || iconDefault" />
        </div>
        <div class="price" v-if="item.type === 'buy'">
          <span>- {{ item.solAmount | nFormatter(8) }}<i>{{ item.sol.symbol }}</i></span>
          <span>+ {{ item.tokenAmount | nFormatter(2) }}<i>{{ item.token.symbol }}</i></span>
        </div>
        <div class="price" v-else>
          <span>- {{ item.tokenAmount | nFormatter(2) }}<i>{{ item.token.symbol }}</i></span>
          <span>+ {{ item.solAmount | nFormatter(8) }}<i>{{ item.sol.symbol }}</i></span>
        </div>
      </div>
      <a :href="`https://solscan.io/tx/${item.hash}`" target="_blank">
        <div class="source">
          <div>
            <span>${{ item.solAmount * item.sol.price | formatNumber(4) }}</span>
          </div>
          <div>
            <SoarAge :age="item.create_at"></SoarAge>
            <span :class="{ buy: item.type === 'buy', sell: item.type === 'sell' }">{{ item.type }}</span>
          </div>
        </div>
        <div class="link">
          <svg viewBox="0 0 10 10">
            <path
              d="M4 2V3H1.5V8.5H7V6H8V9C8 9.13261 7.94732 9.25979 7.85355 9.35355C7.75979 9.44732 7.63261 9.5 7.5 9.5H1C0.867392 9.5 0.740215 9.44732 0.646447 9.35355C0.552678 9.25979 0.5 9.13261 0.5 9V2.5C0.5 2.36739 0.552678 2.24021 0.646447 2.14645C0.740215 2.05268 0.867392 2 1 2H4ZM9.5 0.5V4.5H8.5V2.2065L4.6035 6.1035L3.8965 5.3965L7.7925 1.5H5.5V0.5H9.5Z"
              fill="currentColor"></path>
          </svg>
        </div>
      </a>
    </div>

  </div>
</template>

<script>
import SoarTable from "@/components/SoarTable";
import SoarEmpty from "@/components/SoarEmpty";
import { mapState } from "vuex";
import { getTradeList } from "@/api/account";
import { getToken } from "@/api/meme";
import { debounce } from "@/utils/helper";
import SoarAge from "@/components/SoarAge";

export default {
  components: {
    SoarTable,
    SoarEmpty,
    SoarAge
  },
  data () {
    return {
      keys: ["InToken", "OutToken", "Hash"],
      list: [],
      loading: true
    };
  },
  computed: {
    ...mapState({
      tokenMap: (state) => state.tokenMap,
    }),
  },
  watch: {
    account () {
      this.getTxs();
    },
  },
  mounted () {
    this.getTxs();
  },
  methods: {
    async getTxs () {
      if (!this.account) {
        this.list = [];
        return;
      }
      const list = await getTradeList(1, 30);
      this.loading = false;
      this.$set(this, "list", list);
    },
    go: debounce(async function (item) {
      const { token } = item;
      let data = await getToken({
        address: token.address,
      });
      window.location.href = `/solana/${data.source}/${token.address}?tab=03`;
    }, 300),

  },
};
</script>

<style lang="scss" scoped>
.transaction {
  height: calc(100vh - 400px);
  margin-right: -10px;
  padding-right: 10px;
  overflow: auto;

  .item {
    display: grid;
    grid-gap: 2px;
    grid-template-columns: 1fr 120px;
    background: var(--card);
    padding: 10px;
    border-radius: 16px;
    margin-top: 10px;

    span {
      line-height: 1;
    }

    &:hover {
      background: var(--border-color);
    }

    .icon {
      width: 42px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 36px;
        height: 36px;
        border-radius: 18px;
      }
    }

    .price {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;

      span {
        font-size: 14px;
        line-height: 1.1;

        &:first-child {
          color: var(--color-red);
        }

        &:last-child {
          color: var(--color-green);
        }
      }

      i {
        color: var(--color-content);
        margin-left: 3px;
      }
    }

    .source {
      width: 60px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      flex-direction: column;

      .time {
        margin-right: 10px;
        color: var(--color-content);
        font-size: 12px;
      }

      span {
        font-size: 14px;
        line-height: 1.1;
        color: var(--color-content);
        text-transform: capitalize;

        &.buy {
          color: var(--color-green);
        }

        &.sell {
          color: var(--color-red);
        }
      }

    }

    .link {
      width: 42px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 16px;
        color: var(--color-content);
      }
    }

    a {
      display: flex;
      justify-content: end;
      align-content: center;
      text-decoration: none;

    }

    .left {
      display: flex;
      // justify-content: center;
      align-content: center;
    }
  }
}

.loading {
  height: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.g-loader {
  width: 56px;
  height: 56px;
  border-width: 3px;
}
</style>