<template>
  <SoarTable
    :keys="keys"
    :loading="loading"
    :customClass="customClass"
    :empty="list && list.length === 0"
  >
    <tr v-for="(item, i) in list" :key="i">
      <td>
        <div class="icon">
          <img :src="item.profile_image || iconUser" />
          <span v-if="item.username">{{ item.username }}</span>
          <span v-else>{{ item.address | formatAccount }}</span>
        </div>
      </td>
      <td>{{ item.followers || 0 }}</td>
      <td>{{ item.timestamp | formatDate }}</td>
      <td>
        <a :href="getBlockExplorerPathByCode(chain, `/address/${item.address}`)" target="_blank">
          <span>{{ item.address | formatAccount }}</span>
        </a>
      </td>
    </tr>
  </SoarTable>
</template>

<script>
import SoarEmpty from "@/components/SoarEmpty";
import SoarTable from "@/components/SoarTable";
import iconUser from "@/assets/images/icon-user.png";
import { getFollowing } from "@/api";
import { getBlockExplorerPathByCode } from "@/utils/chain";

export default {
  components: {
    SoarTable,
    SoarEmpty,
  },
  data() {
    return {
      keys: ["User", "Followers", "CreateAt", "Address"],
      list: [],
      loading: true,
      iconUser,
      customClass: {
        extendProfile: true,
      },
      getBlockExplorerPathByCode
    };
  },
  watch: {
    account() {
      this.getFollowing();
    },
  },
  mounted() {
    this.getFollowing();
  },
  methods: {
    async getFollowing() {
      if (!this.account) {
        this.list = [];
        return;
      }
      const list = await getFollowing(this.account);
      console.log('list', list);
      this.$set(this, "list", list);
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  img {
    width: 24px;
    height: 24px;
    object-fit: cover;
    border-radius: 12px;
  }
}
</style>