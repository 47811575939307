<template>
  <div>
    <div class="search g-clickable-small" @click="isShowSearch = true">
      <div class="search__icon">
        <svg viewBox="0 0 1025 1024">
          <path
            d="M1008.839137 935.96571L792.364903 719.491476a56.783488 56.783488 0 0 0-80.152866 0 358.53545 358.53545 0 1 1 100.857314-335.166073 362.840335 362.840335 0 0 1-3.689902 170.145468 51.248635 51.248635 0 1 0 99.217358 26.444296 462.057693 462.057693 0 1 0-158.255785 242.303546l185.930047 185.725053a51.248635 51.248635 0 0 0 72.568068 0 51.248635 51.248635 0 0 0 0-72.978056z"
            fill="var(--color-des)"
          ></path>
          <path
            d="M616.479587 615.969233a50.428657 50.428657 0 0 0-61.498362-5.534852 174.655348 174.655348 0 0 1-177.525271 3.484907 49.403684 49.403684 0 0 0-58.833433 6.76482l-3.074918 2.869923a49.403684 49.403684 0 0 0 8.609771 78.10292 277.767601 277.767601 0 0 0 286.992355-5.739847 49.403684 49.403684 0 0 0 8.404776-76.667958z"
            fill="var(--color-yellow)"
          ></path>
        </svg>
      </div>
      <div class="search__input">
        <span>Search token address</span>
      </div>
      <div class="search__submit">
        <svg viewBox="0 0 1024 1024">
          <path
            d="M439.488 960l124.416-169.984-124.416-35.84L439.488 960 439.488 960 439.488 960M0 559.936l353.472 107.072 435.328-369.6-337.408 398.144 377.92 116.736L1024 64.064 0 559.936 0 559.936 0 559.936M0 559.936"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
    <div class="search-mobile" @click="isShowSearch = true">
      <svg viewBox="0 0 1025 1024">
        <path
          d="M1008.839137 935.96571L792.364903 719.491476a56.783488 56.783488 0 0 0-80.152866 0 358.53545 358.53545 0 1 1 100.857314-335.166073 362.840335 362.840335 0 0 1-3.689902 170.145468 51.248635 51.248635 0 1 0 99.217358 26.444296 462.057693 462.057693 0 1 0-158.255785 242.303546l185.930047 185.725053a51.248635 51.248635 0 0 0 72.568068 0 51.248635 51.248635 0 0 0 0-72.978056z"
          fill="var(--color-des)"
        ></path>
        <path
          d="M616.479587 615.969233a50.428657 50.428657 0 0 0-61.498362-5.534852 174.655348 174.655348 0 0 1-177.525271 3.484907 49.403684 49.403684 0 0 0-58.833433 6.76482l-3.074918 2.869923a49.403684 49.403684 0 0 0 8.609771 78.10292 277.767601 277.767601 0 0 0 286.992355-5.739847 49.403684 49.403684 0 0 0 8.404776-76.667958z"
          fill="var(--color-yellow)"
        ></path>
      </svg>
    </div>
    <SoarSearch @close="isShowSearch = false" v-if="isShowSearch" />
  </div>
</template>

<script>
import SoarSearch from "@/components/SoarSearch";
export default {
  components: {
    SoarSearch
  },
  data() {
    return {
      isShowSearch: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.search {
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 8px;
  transition: all 0.3s ease;
  width: 360px;
  margin-right: 10px;
  box-sizing: border-box;
  overflow: hidden;

  display: flex;
  align-items: center;
  user-select: none;

  &__icon {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 16px;
    }
  }

  &__input {
    flex: 1;
    span {
      color: var(--border-color);
    }
  }

  &__submit {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 16px;
      color: var(--color-content);
    }
  }

  @media (max-width: 750px) {
    display: none;
  }
}

.search-mobile {
  width: 36px;
  height: 36px;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 8px;
  margin-right: 10px;
  box-sizing: border-box;

  display: none;
  align-items: center;
  justify-content: center;

  @media (max-width: 750px) {
    display: flex;
  }

  svg {
    width: 18px;
  }
}
</style>