import Web3 from "web3";
import IconMetamask from "@/assets/wallet/icon-metamask.svg";
import IconWalletConnect from "@/assets/wallet/icon-walletconnect.svg";
import IconPhantom from "@/assets/wallet/icon-phantom.svg";
import IconSolflare from "@/assets/wallet/icon-solflare.svg";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { getChainById } from "@/utils/chain";
import { PublicKey } from "@solana/web3.js";
import showToast from "@/components/toast";

export const errors = {
  NoBrowserWallet: "no Browser Wallet",
  NoBsc: "no bsc wallet",
  ChainCodeNotMath: "chainCode not match",
  ChainIdNotMath: "chainId not match",
};

export const rpc = {
  69: "https://kovan.optimism.io",
  420: "https://goerli.optimism.io",
  10: "https://mainnet.optimism.io",
  25: "https://evm.cronos.org",
  56: "https://bsc-dataseed1.binance.org",
  66: "https://exchainrpc.okex.org",
  100: "https://rpc.xdaichain.com",
  128: "https://http-mainnet.hecochain.com",
  137: "https://rpc-mainnet.maticvigil.com",
  250: "https://rpcapi.fantom.network",
  288: "https://mainnet.boba.network",
  1285: "https://rpc.moonriver.moonbeam.network",
  42161: "https://arb1.arbitrum.io/rpc",
  421611: "https://rinkeby.arbitrum.io/rpc",
  43114: "https://api.avax.network/ext/bc/C/rpc",
  1313161554: "https://mainnet.aurora.dev",
  1666600000: "https://api.harmony.one",
};

export function autoWalletCheck(check, time, count) {
  return new Promise((resolve) => {
    let i = 0;
    let s = count || 10;
    let id = setInterval(() => {
      if (i++ > s) {
        clearInterval(id);
        resolve(false);
      } else {
        const r = check && check();
        console.log('autoWalletCheck', r);
        if (r) {
          clearInterval(id);
          resolve(true);
        }
      }
    }, time / s);
  });
}

export const metamask = async () => {
  if (!(window.ethereum && window.ethereum.isMetaMask)) {
    throw new Error(errors.NoMetamask);
  }
  const sdk = new Web3(window.ethereum);
  const accounts = await sdk.eth.requestAccounts();
  const currentChainId = await sdk.eth.getChainId();
  const chain = getChainById(currentChainId);
  if (!chain) {
    throw new Error(errors.ChainCodeNotMath);
  }
  // const { chainId } = chain;
  // if (chainId && currentChainId !== chainId) {
  //   await tryConnect(chainId);
  // }
  return {
    chainId: currentChainId,
    chainCode: chain.chainCode,
    account: accounts[0],
    sdk
  };
};

export const walletconnect = async () => {
  const provider = new WalletConnectProvider({
    infuraId: "c334bb4b45a444979057f0fb8a0c9d1b",
    chainId: 1,
    rpc
  });
  await provider.enable();
  const sdk = new Web3(provider);
  const currentChainId = await sdk.eth.getChainId();
  if (chainId && currentChainId !== chainId) {
    await tryChangeChain(chainId, sdk);
  }
  const accounts = await sdk.eth.getAccounts();
  return {
    chainId: currentChainId,
    account: accounts[0],
    sdk,
  };
};

export const phantom = async () => {
  const result = await autoWalletCheck(() => {
    return (window.solana && window.solana.isPhantom) || (window.parent && window.parent.solana);
  }, 3000, 10);
  if (!result) {
    showToast("no phantom");
    throw "no phantom";
  }
  const wallet = window.parent && window.parent.solana ? window.parent.solana : window.solana;
  let sdk = wallet;
  let address = await new Promise((r) => {
    wallet.on('connect', (publicKey) => {
      const address = wallet.publicKey.toString();
      r(address);
    });
    wallet.connect();
  })
  wallet.customPublicKey = new PublicKey(address);
  return {
    chainId: 'solana',
    chainCode: 'solana',
    account: address,
    sdk
  };
};

export const solflare = async () => {
  const result = await autoWalletCheck(() => {
    return (window.solflare && window.solflare.isSolflare) || (window.parent && window.parent.solflare);
  }, 3000, 10);
  if (!result) {
    showToast("no solflare");
    throw "no solflare";
  }
  const wallet = window.solflare && window.parent.solflare ? window.parent.solflare : window.solflare;
  let sdk = wallet;
  let address = await new Promise((r) => {
    wallet.on('connect', (publicKey) => {
      const address = wallet.publicKey.toString();
      r(address);
    });
    wallet.connect();
  })
  wallet.customPublicKey = new PublicKey(address);
  return {
    chainId: 'solana',
    chainCode: 'solana',
    account: address,
    sdk
  };
};

export const tryChangeChain = async (chainIdNumber, sdk) => {
  const chainId = Web3.utils.toHex(chainIdNumber);
  try {
    await sdk.currentProvider.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId }],
    });
  } catch (switchError) {
    console.log("switchError", switchError);
    if (switchError.code === 4902) {
      try {
        const chain = getChainById(chainIdNumber);
        const { chainName, nativeCurrency, rpcUrls, blockExplorerUrls } = chain;
        await ethereum.request({
          method: "wallet_addEthereumChain",
          params: [{
            chainId,
            chainName,
            nativeCurrency,
            rpcUrls,
            blockExplorerUrls
          }],
        });
      } catch (addError) {
        console.log("addError", addError);
      }
    } else {
      throw switchError;
    }
  }
};

export const WalletList = [
  // {
  //   code: "browserwallet",
  //   name: "Browser Wallet",
  //   source: ["four"],
  //   icon: IconMetamask,
  //   connect: metamask,
  // },
  // {
  //   code: "walletconnect",
  //   name: "Wallet Connect",
  //   icon: IconWalletConnect,
  //   source: ["four"],
  //   connect: walletconnect,
  // },
  {
    code: "phantom",
    name: "Phantom",
    icon: IconPhantom,
    source: ["pump", "moonshot"],
    connect: phantom,
  },
  // {
  //   code: "solflare",
  //   name: "Solflare",
  //   icon: IconSolflare,
  //   source: ["pump", "moonshot"],
  //   connect: solflare
  // }
];
