<template>
  <div>
    <SoarEmpty />
  </div>
</template>

<script>
import SoarEmpty from "@/components/SoarEmpty";
export default {
  components: {
    SoarEmpty,
  },
};
</script>

