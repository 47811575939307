import store from "@/store";
import { providers, Contract } from "ethers";
import showToast from "@/components/toast";
import { getChainById, getChainByCode, getChainIdByCode } from "@/utils/chain";
import { errors } from "@/utils/connect";
import { decimals2Amount, amount2Decimals } from "@/utils/number";
import BigNumber from "bignumber.js";
import { PublicKey, Connection, Transaction, SystemProgram, LAMPORTS_PER_SOL } from "@solana/web3.js";
import { SOLANA_RPC, BONDING_CURV, ERC20 } from "@/utils/config";
import Muliticall from "ethers-multicall";
import BN from "bn.js";
import Web3 from "web3";
import bs58 from "bs58";
import nacl from "tweetnacl";
import showLoading from "@/components/loading";
import { transactionSenderAndConfirmationWaiter } from "@/swap/helper";

import {
  getMint
} from "@solana/spl-token";
import { getServerTime, walletLogin } from "@/api/account";

const { state } = store;
const solanaConnection = new Connection(SOLANA_RPC);
function sleep (interval) {
  return new Promise((resolve) => {
    setTimeout(resolve, interval);
  });
}

export let myWallet = {};
export const tryConnect = async (wallet, connect, isAuto) => {
  try {
    if (!connect) return;
    const { account, sdk, chainCode } = await connect();
    if (sdk.currentProvider) {
      const { currentProvider } = sdk || {};
      currentProvider.on("accountsChanged", async (args) => {
        console.log("accountsChanged");
        if (currentProvider && currentProvider._metamask) {
          const isUnLock = await currentProvider._metamask.isUnlocked();
          if (!isUnLock) {
            showToast(
              "There is no connected accounts. Please, connect at least 1 account in your wallet."
            );
            disConnect();
            return;
          }
        }
        const account =
          currentProvider.accounts && currentProvider.accounts[0]
            ? currentProvider.accounts[0]
            : args[0];
        store.commit("SET_ACCOUNT", account);
        // showToast('Account Changed');
      });
      currentProvider.on("chainChanged", (args) => {
        if (!state.account) return;

        const currentChainId = parseInt(args, 16);
        const chain = getChainById(currentChainId);
        if (!chain) {
          throw new Error(errors.ChainCodeNotMath);
        }
        const { chainCode } = chain;
        store.commit("SET_CHAINCODE", chainCode);
      });
      currentProvider.on("disconnect", () => {
        disConnect();
        showToast("Account Disconnected");
      });
      const provider = new providers.Web3Provider(sdk.eth.currentProvider);
      store.commit("SET_SIGNER", provider.getSigner());
    }
    myWallet = sdk;
    if (!isAuto) {
      const result = await gotoLogin(account, chainCode);
      if (result) {
        store.commit("SET_CHAINCODE", chainCode);
        store.commit("SET_WALLET", wallet);
        store.commit("SET_ACCOUNT", account);
      } else {
        disConnect()
      }
    }
    return account;
  } catch (e) {
    disConnect();
  }
};

export const disConnect = () => {
  store.commit("SET_ACCOUNT", "");
  store.commit('SET_AUTH_TOKEN', "");
  store.commit('SET_AUTH_EMAIL', "");
  store.commit('SET_AUTH_WALLET', "");
  store.commit('SET_AUTH_ADDRESS', "");
  store.commit('SET_AUTH_TIME', "");
}

export const getContractByRPC = (chain, abi, address) => {
  const { provider } = getProviderByRPC(chain);
  if (!provider) return;
  const contract = new Contract(address, abi, provider);
  return contract;
};

export const getProviderByRPC = (chain) => {
  const params = getChainByCode(chain);
  const { rpcUrls } = params;
  if (!params || !rpcUrls || !rpcUrls[0]) return {};
  const provider = new providers.JsonRpcProvider(rpcUrls[0]);
  return {
    provider,
    ...params,
  };
};

export const getGasPrice = async () => {
  const gasPrice = await myWallet.eth.getGasPrice();
  return parseInt(gasPrice * 1.2);
};

export const getBalance = async (account, address) => {
  try {
    let result = {};
    if (address === 'So11111111111111111111111111111111111111112') {
      const amount = await solanaConnection.getBalance(
        new PublicKey(account)
      );
      const decimals = 9;
      const balance = decimals2Amount(amount, decimals);
      result = {
        balance,
        decimals,
        balanceDecimals: amount
      }
      if (state.account === account) {
        store.commit('SET_SOL_BALANCE', balance);
      } else {
        store.commit('SET_AUTH_SOL_BALANCE', balance);
      }
    } else {
      const tokenAccounts =
        await solanaConnection.getParsedTokenAccountsByOwner(
          new PublicKey(account),
          {
            mint: new PublicKey(address),
          }
        );
      tokenAccounts.value.forEach((accountInfo) => {
        const accountData = accountInfo.account.data.parsed.info;
        const { mint, tokenAmount } = accountData;
        if (address == mint) {
          const { amount, decimals } = tokenAmount || {};
          result = {
            balance: decimals2Amount(amount, decimals),
            decimals,
            balanceDecimals: amount
          }
        }
      });
    }
    return result;
  } catch (e) {
    throw e;
  }
}

export const getSolanaBalances = async (tokenList, account) => {
  try {
    for (let i = 0; i < tokenList.length; i++) {
      if (
        tokenList[i].address == "So11111111111111111111111111111111111111112"
      ) {
        const amount = await solanaConnection.getBalance(
          new PublicKey(account)
        );
        const decimals = 9;
        const balance = decimals2Amount(amount, decimals);
        tokenList[i].balance = balance;
        tokenList[i].decimals = decimals;
        tokenList[i].balanceDecimals = amount;
        if (state.account === account) {
          store.commit('SET_SOL_BALANCE', balance);
        } else {
          store.commit('SET_AUTH_SOL_BALANCE', balance);
        }
      } else {
        const tokenAccounts =
          await solanaConnection.getParsedTokenAccountsByOwner(
            new PublicKey(account),
            {
              mint: new PublicKey(tokenList[i].address),
            }
          );
        tokenAccounts.value.forEach((accountInfo) => {
          const accountData = accountInfo.account.data.parsed.info;
          const { mint, tokenAmount } = accountData;
          if (tokenList[i].address == mint) {
            const { amount, decimals } = tokenAmount || {};
            tokenList[i].balance = decimals2Amount(amount, decimals);
            tokenList[i].decimals = decimals;
            tokenList[i].balanceDecimals = amount;
          }
        });
      }
    }
    return tokenList;
  } catch (err) {
    console.log('tokenList error', err);
    return tokenList;
  }
};

export const getBalancesAddress = (key) => {
  const map = {
    "boba": "0xed8F54daA8Da64Ce82F23263B208417cB2729433",
    "moonriver": "0xaeF00A0Cf402D9DEdd54092D9cA179Be6F9E5cE3",
    "optimism": "0xcA11bde05977b3631167028862bE2a173976CA11",
    "aurora": "0xBF69a56D35B8d6f5A8e0e96B245a72F735751e54",
    "harmony": "0x34b415f4d3b332515e66f70595ace1dcf36254c5",
    "cronos": "0x8924755a7FB45bF0A37A6A773795CFa878114A26",
    "ontevm": "0x9f92b2706c643ae6fdf2e9ca472f0e48497385f1",
    "metis": "0x999c891262ce01f1C1AFD1D46260E4c1E508B243",
    "kava": "0x7ED7bBd8C454a1B0D9EdD939c45a81A03c20131C",
    "celo": "0x75F59534dd892c1f8a7B172D639FA854D529ada3",
    "klaytn": "0xd11dfc2ab34abd3e1abfba80b99aefbd6255c4b8",
    "zksync": "0x1cBFd1688e19dE659247bb95f2ef161Df72C7565",
    "polygon_zkevm": "0x2B5F704aB7061FB4DBfc5876B024F4BDB2f5E8b6",
    // "linea":"0x9F92b2706c643ae6FDF2e9ca472f0E48497385f1",
    "linea": "0xE4B2D37DaBA87e865e811b240e07522414386de3",
    "telos": "0xcA11bde05977b3631167028862bE2a173976CA11",
    "scroll": "0x7740C579c844A21A4dF8E18c39a1c6eEBf7e9051",
    "base": "0xcA11bde05977b3631167028862bE2a173976CA11",
    "opbnb": "0x689e9631d1e308845DE5661C0B6bdE23c841e459",
    "mantle": "0xEecf65578bF2f5a506e501c5809688632C1540E5",
    "manta": "0x891CbfED9c791b8e8A53084aC39b213aF6109C70",
    "blast": "0xD9b3b246ac3B6e44641443fec1e3749df72050b8",
    "mode": "0x0D7A7dEf69281768599FAe0FE746EA9554b2b3D4",
    "pulse": "0xf0B8E4CBf9c6a3F010eA5bdC3AcB796c33d0BA12",
    "merlin": "0x830E7E548F4D80947a40A7Cf3a2a53166a0C3980",
    "rootstock": "0x996a9858cdfa45ad68e47c9a30a7201e29c6a386",
    "polygon": "0xc4f1501f337079077842343Ce02665D8960150B0",
    "gnosis": "0xcA11bde05977b3631167028862bE2a173976CA11",
    "x1": "0x55BeE1bD3Eb9986f6d2d963278de09eE92a3eF1D",
    "sei": "0xcA11bde05977b3631167028862bE2a173976CA11",
    "arbitrum_sepolia": "0xCf8EDB3333Fae73b23f689229F4De6Ac95d1f707",
    "gravity": "0xcA11bde05977b3631167028862bE2a173976CA11",
  }
  if (map[key]) {
    return {
      id: getChainIdByCode(key),
      address: map[key]
    }
  }
  return
}

export const isNativeToken = (address) => {
  return ['0x0000000000000000000000000000000000000000', '0x0000000000000000000000000000000000001010', '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'].indexOf(address) >= 0;
}

export const getBalances = async (tokenList, params) => {
  const { chain, account, approveContract } = params || {};
  if (!account) return;
  if (chain === "solana") {
    return await getSolanaBalances(tokenList, account);
  }
  const { provider, chainId } = getProviderByRPC(chain);
  if (!provider || !chainId) return;
  let balancesAddress = getBalancesAddress(chain)
  if (balancesAddress) {
    Muliticall.setMulticallAddress(
      balancesAddress.id,
      balancesAddress.address
    );
  }

  const ethcallProvider = new Muliticall.Provider(provider, Web3.utils.toHex(chainId));
  await ethcallProvider.init();
  const multicall = [];
  const multicallAllowance = [];
  for (let i = 0; i < tokenList.length; i++) {
    const token = tokenList[i];
    if (isNativeToken(token.address)) {
      multicall.push(ethcallProvider.getEthBalance(account));
      if (approveContract) {
        token.approve = -1;
      }
    } else {
      const contract = new Muliticall.Contract(token.address, ERC20);
      multicall.push(contract.balanceOf(account));
      if (approveContract) {
        multicallAllowance.push(contract.allowance(account, approveContract));
      }
    }
  }
  const result = await ethcallProvider.all(multicall);
  result.map((item, i) => {
    const { decimals } = tokenList[i];
    const amount = Web3.utils.hexToNumberString(item);
    const balance = +amount === 0 ? 0 : decimals2Amount(amount, decimals);
    tokenList[i].balance = Math.floor(balance * 10000) / 10000;
    tokenList[i].balanceAll = tokenList[i].balance;
    tokenList[i].balanceDecimals = amount;
  });
  if (approveContract) {
    const resultAllowance = await ethcallProvider.all(multicallAllowance);
    let seq = 0;
    tokenList.map((token, i) => {
      const { approve, decimals, symbol, address } = token;
      if (approve === -1) {
        seq = 1;
        return;
      }
      const item = resultAllowance[i - seq];
      const amount = Web3.utils.hexToNumberString(item);
      const _approve = +amount === 0 ? 0 : decimals2Amount(amount, decimals);
      token.approve = _approve;
      console.log("resultAllowance", symbol, address, _approve);
    });
  }
  console.log('result_' + chainId, tokenList);
  return tokenList
};

export const getBondingCurve = async (item) => {
  const { realSolReserves, realTokenReserves, virtualSolReserves, virtualTokenReserves } = item;

  const BondingCurve = {
    realSolReserves: new BN(Number(realSolReserves)),
    realTokenReserves: new BN(Number(realTokenReserves)),
    virtualSolReserves: new BN(Number(virtualSolReserves)),
    virtualTokenReserves: new BN(Number(virtualTokenReserves))
  }
  try {
    const { bondingCurve } = item || {};
    if (!bondingCurve) return bondingCurve;

    const { value } = await solanaConnection.getAccountInfoAndContext(
      new PublicKey(bondingCurve)
    );
    const result = BONDING_CURV.decode(value.data);
    console.log('BondingCurve01', realSolReserves, realTokenReserves, virtualSolReserves, virtualTokenReserves);
    console.log('BondingCurve02', result.realSolReserves.toString(), result.realTokenReserves.toString(), result.virtualSolReserves.toString(), result.virtualTokenReserves.toString());
    return result || bondingCurve;
  } catch (e) {
    return BondingCurve
  }
}

export const getTokenDecimals = async (tokenMintAddress) => {
  // Create a PublicKey object from the token's mint address
  const mintPubkey = new PublicKey(tokenMintAddress);

  try {
    // Fetch the mint account info
    const mintInfo = await getMint(solanaConnection, mintPubkey);

    // Return the decimals
    return mintInfo.decimals;
  } catch (error) {
    console.error('Error fetching token decimals:', error);
    return null;
  }
}

const feeBasisPoints = new BN(100);

function N (e, n, t) {
  let s, a;
  if (e.eq(new BN(0)) || !t)
    return new BN(0);
  if (n) {
    let n = t.virtualSolReserves.mul(t.virtualTokenReserves)
      , r = t.virtualSolReserves.add(e)
      , o = n.div(r).add(new BN(1));
    a = t.virtualTokenReserves.sub(o),
      a = BN.min(a, t.realTokenReserves),
      s = e
  } else
    s = (e = BN.min(e, t.realTokenReserves)).mul(t.virtualSolReserves).div(t.virtualTokenReserves.sub(e)).add(new BN(1)),
      a = e;
  let r = s.mul(feeBasisPoints).div(new BN(1e4));
  return n ? a : s.add(r);
}

function getFinalUSDMarketCap (t, g) {
  let { virtualSolReserves: e, virtualTokenReserves: n, tokenTotalSupply: s, realTokenReserves: a } = t
    , r = N(a, !1, t)
    , o = e.add(r)
    , l = n.sub(a);
  return l.eq(new BN(0)) ? 0 : Number((s.mul(o).div(l).toNumber() / 1e9 * g).toFixed(0)).toLocaleString()
}

export const getBondingCurveProgress = (bondingCurve, solPrice, decimals) => {
  const { realSolReserves, realTokenReserves, tokenTotalSupply } = bondingCurve;
  const R = new BN(amount2Decimals(793100000, decimals)); // 793100000 * token decimals(10^6)
  const D = realTokenReserves;
  const U = new BN(100).sub(D.mul(new BN(100)).div(R)).toNumber();
  const W = 1073000000; // initial_virtual_token_reserves E = 1073000000 * token decimals(10^6)
  const z = 30; // initial_virtual_sol_reserves L = 30000000000
  const K = 200; // 200000000000
  const Z = tokenTotalSupply.div(new BN(1e6)).toNumber(); // 1000000000; tokenTotalSupply = 1000000000000000
  const V = Math.min(R.sub(D).mul(new BN(100)).div(new BN((1e6 * Math.max(W - Math.sqrt(W * z * Z / K), 1)).toFixed(0))).toNumber(), 100);
  // dethrone the current king at a     getKingOfTheHillMarketCap
  const g = solPrice // https://frontend-api.pump.fun/sol-price
  const Cap = Number((200000000000 * g / LAMPORTS_PER_SOL).toFixed(0)).toLocaleString();

  // market cap reaches      getFinalUSDMarketCap
  const FinalCap = getFinalUSDMarketCap(bondingCurve, g);

  // tokens still available for sale in the bonding curve
  const available = realTokenReserves.div(new BN(1e6)).toNumber().toLocaleString();

  // SOL in the bonding curve
  const sol = (realSolReserves.toNumber() / LAMPORTS_PER_SOL).toLocaleString();
  return {
    U,
    V,
    Cap,
    FinalCap,
    available,
    sol
  };
};

export const getTokenLargestAccounts = async (tokenTotalSupply, address) => {
  const { value } = await solanaConnection.getTokenLargestAccounts(
    new PublicKey(address)
  )
  return (value || []).map(item => {
    item.account = item.address.toString()
    item.percent = new BigNumber(item.amount).dividedBy(tokenTotalSupply.toNumber()).multipliedBy(100).toFixed(2);
    return item;
  })
}

export async function personalSign (timestamp, account, chainCode) {
  const msg = `action:\nBullMe Authentication\nonlySignOn:\nhttps://bullme.one\ntimestamp:\n${timestamp}`;
  return new Promise(async (resolve) => {
    if (!myWallet) {
      await sleep(5000);
    }
    if (chainCode === 'solana') {
      const message = new TextEncoder().encode(msg);
      myWallet.signMessage(message, "utf8").then(({ signature }) => {
        const result = bs58.encode(signature);
        const valid = nacl.sign.detached.verify(message, signature, myWallet.publicKey.toBytes());
        if (valid) {
          resolve({
            "signature": result,
            "address": account,
            "timestamp": timestamp,
          });
        } else {
          disConnect(chainCode);
        }
      });
    } else {
      myWallet.eth.personal.sign(msg, account, "", async function (err, result) {
        if (!err) {
          resolve({
            "signature": result,
            "address": account,
            "timestamp": timestamp,
          });
        } else {
          // resolve({ error: err });
          disConnect(chainCode);
        }
      });
    }
  });
}

export async function gotoLogin (account, chainCode) {
  if (window.solana || (myWallet && myWallet.eth)) {
    const timestamp = await getServerTime();
    // if (state.authTime) {
    //   const diffTime = timestamp - state.authTime;
    //   if (diffTime < 24 * 60 * 60 * 1000) {
    //     return true;
    //   }
    // }
    const signInfo = await personalSign(timestamp, account, chainCode);
    if (!signInfo) {
      return false;
    }
    const res = await walletLogin(signInfo);
    if (res) {
      const { token, email, wallet, address } = res.data || {};
      store.commit('SET_AUTH_TOKEN', token);
      store.commit('SET_AUTH_EMAIL', email);
      store.commit('SET_AUTH_WALLET', wallet);
      store.commit('SET_AUTH_ADDRESS', address);
      store.commit('SET_AUTH_TIME', timestamp);
      return true;
    }
    return false;
  }
}

export async function transferSOL (toAddress, amount) {
  const instance = showLoading({
    text: "Deposit in progress",
  });
  const chainCode = "solana";
  try {
    const fromPubkey = new PublicKey(state.account);
    const toPubkey = new PublicKey(toAddress);
    const transaction = new Transaction().add(
      SystemProgram.transfer({
        fromPubkey,
        toPubkey,
        lamports: amount * 1000000000 // 将SOL转换为lamports (1 SOL = 10^9 lamports)
      })
    );
    const latestBlockhash = await solanaConnection.getLatestBlockhash();
    transaction.recentBlockhash = latestBlockhash.blockhash;
    transaction.feePayer = fromPubkey;
    const signed = await myWallet.signTransaction(transaction);
    const serializedTransaction = signed.serialize({
      verifySignatures: false,
      requireAllSignatures: false,
    });
    const transactionResponse = await transactionSenderAndConfirmationWaiter({
      serializedTransaction,
    });
    if (transactionResponse && transactionResponse.response) {
      if (transactionResponse.response.meta?.err) {
        instance.change({
          message: transactionResponse.response.meta?.err,
          chainCode,
          status: "rejected",
        });
      } else {
        const { txid } = transactionResponse;
        if (txid) {
          instance.change({
            message: "submit",
            chainCode,
            hash: txid,
            status: "success",
          });
        }
      }
    } else {
      instance.change({
        message: "Transaction not confirmed.",
        chainCode,
        status: "rejected",
      });
      // throw new Error('Transaction not confirmed.');
    }
  } catch (e) {
    instance.change({
      message: e.message,
      chainCode,
      status: "rejected",
    });
  }
}

export async function claimRewards (getReferralClaim) {
  const instance = showLoading({
    text: "Claim in progress",
  });
  const chainCode = "solana";
  try {
    let res = await getReferralClaim()

    if (res.code == 0) {
      instance.change({
        message: "submit",
        chainCode,
        hash: res.data.signature,
        status: "success",
      });
      return false
    } else {
      instance.change({
        message: res.error || res.msg || 'Error',
        chainCode,
        status: "rejected",
      });
      return true
    }

  } catch (e) {
    instance.change({
      message: e.message,
      chainCode,
      status: "rejected",
    });
    return false
  }
}