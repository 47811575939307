import request from "@/utils/request";
import iconUser from "@/assets/images/icon-user.png";
import BigNumber from "bignumber.js";
import { getBaseAsset } from "@/utils/config";

const BASE_URL = "https://four.meme/meme-api/v1";

function getTokenIdByMint(mint) {
  return (mint && mint.split("_")[1]) || "";
}


export const getTickerBySymbol = async (symbol) => {
  const { data } = await request({
    method: "POST",
    url: `${BASE_URL}/public/ticker`,
    data: {}
  });
  const { price } =
    data &&
    data.find((item) => {
      return item.symbol === symbol;
    });
  return price;
};

export const getCoin = async (mint) => {
  const _mint = mint && mint.split("_")[0];
  const { data } = await request(
    `${BASE_URL}/private/token/get/v2?address=${_mint}`
  );
  const {
    id,
    name,
    shortName,
    address,
    descr,
    image,
    userAddress,
    twitterUrl,
    webUrl,
    telegramUrl,
    tokenPrice,
    launchTime,
    totalAmount,
    saleAmount,
    symbol,
    version,
  } = data;
  const { marketCap, tamount, bamount, trading } = tokenPrice || {};
  const n = totalAmount;
  const s = saleAmount;
  const e = new BigNumber(n).minus(s);
  const c = tamount;
  const u = bamount;
  const k = await getTickerBySymbol(`${symbol}USDT`);
  const MaxMarketCap = new BigNumber(c)
    .times(u)
    .div(new BigNumber(c).minus(s))
    .minus(u)
    .times(n)
    .times(k)
    .div(e)
    .toFixed(2);
  const MarketCap = new BigNumber(marketCap).times(k).toFixed(2);
  const volume = new BigNumber(k).times(trading).toFixed(2).toString();
  const baseAsset = getBaseAsset("four");
  return {
    id,
    mint: address,
    name,
    address,
    symbol: shortName && shortName.toUpperCase(),
    baseAsset: {
      ...baseAsset[symbol],
      price: k,
    },
    description: descr,
    image_uri: image,
    metadata_uri: "",
    twitter: twitterUrl,
    telegram: telegramUrl,
    bonding_curve: "",
    associated_bonding_curve: "",
    creator: userAddress,
    decimals: 18,
    created_timestamp: launchTime,
    raydium_pool: "",
    complete: true,
    virtual_sol_reserves: 0,
    virtual_token_reserves: 0,
    total_supply: totalAmount,
    website: webUrl,
    show_name: false,
    king_of_the_hill_timestamp: 0,
    market_cap: volume,
    reply_count: 0,
    last_reply: 0,
    nsfw: false,
    market_id: "",
    inverted: true,
    is_currently_live: false,
    username: "",
    profile_image: iconUser,
    usd_market_cap: MarketCap,
    four: {
      ...tokenPrice,
      marketCap: MarketCap,
      saleAmount,
      MaxMarketCap,
      version,
    },
  };
};

export const getTrades = async (mint) => {
  const tokenId = getTokenIdByMint(mint);
  const { data } = await request(
    `${BASE_URL}/public/fill?tokenId=${tokenId}&endDate=&pageSize=20`
  );
  return (data || []).map((item) => {
    const { userAddress, side, amount, createDate, txHash, volume } = item;
    return {
      user: userAddress,
      is_buy: side === "BID",
      sol_amount1: volume,
      token_amount0: amount,
      timestamp: createDate,
      signature: txHash,
    };
  });
};

export const getReplies = async (mint) => {
  const tokenId = getTokenIdByMint(mint);
  const { data } = await request(
    `${BASE_URL}/private/token/comment?tokenId=${tokenId}&pageIndex=1&pageSize=12`
  );
  return (data || []).map((item) => {
    const { avatarPath, userName, comment, createDate } = item;
    return {
      profile_image: avatarPath,
      username: "",
      user: userName,
      text: comment,
      timestamp: createDate,
    };
  });
};

export const getTopHolders = async (mint) => {
  const tokenId = getTokenIdByMint(mint);
  const { data } = await request(
    `${BASE_URL}/private/token/holder?tokenId=${tokenId}`
  );
  return (data || []).map((item) => {
    const { address, amount } = item;
    return {
      account: address,
      amount,
      percent: new BigNumber(amount).div(1000000000).multipliedBy(100).toFixed(2),
    };
  });
};
