<template>
  <div class="field">
    <div class="field__name">
      <SoarToolTip :text="tips" v-if="tips">
        <span class="name">{{ name }}</span>
      </SoarToolTip>
      <span class="name" v-else>{{ name }}</span>
    </div>
    <div class="field__upload" v-if="type === 'upload'">
      <input type="file" @change="emitFile" accept="image/png,image/jpeg,image/svg+xml" />
      <div class="g-clickable">
        <img :src="fileSrc" v-if="fileSrc" />
        <svg viewBox="0 0 1024 1024" v-else>
          <path
            d="M884.18750029 107.93750029c-13.12499971-5.625-27.18749971-8.4375-41.25000058-8.4375H181.06250029c-15.00000029 0-29.06250029 2.8125-42.1875 7.49999971C93.87500029 124.81250029 62 167.00000029 62 217.62500029V807.3125c0 50.625 31.87500029 92.8125 77.81249971 109.6875 13.12499971 4.68749971 27.18749971 7.49999971 42.1875 7.49999971h660.9375c65.62500029 0 119.06250029-53.4375 119.06250029-119.06249942V217.62500029c0-50.625-31.87500029-92.8125-77.81249971-109.6875zM422 364.81249971c0 48.75000029-40.31250029 88.12500029-90 88.12500029s-90-39.375-90-88.12500029 40.31250029-88.12500029 90-88.12499942 90 39.375 90 88.12499942z m420.93749971 499.68750058H181.06250029c-28.125 0-52.49999971-19.6875-58.12500058-46.87500058-0.93750029-3.75000029-0.93750029-7.49999971-0.93749942-11.25v-45l177.1875-149.99999942 63.74999971 60.93749971c32.81249971 31.87500029 85.31250029 33.75 120.9375 4.68749971l299.06250029-247.5c57.18750029 61.875 96.56250029 103.12499971 120.9375 124.68750029v251.25000029c-1.87499971 32.81249971-28.125 59.0625-60.93750058 59.0625z"
            fill="currentColor"
          ></path>
        </svg>
        <span>{{ fileName || "Choose file to upload" }}</span>
      </div>
    </div>
    <div
      class="field__text"
      :class="{ focus: focus }"
      v-else-if="type === 'text'"
    >
      <textarea
        @focus="focus = true"
        @blur="focus = false"
        @input="emitInput"
        :placeholder="placeholder"
      />
    </div>
    <div class="field__input" :class="{ focus: focus }" v-else>
      <input
        type="text"
        @focus="focus = true"
        @blur="focus = false"
        @input="emitInput"
        :placeholder="placeholder"
      />
    </div>
  </div>
</template>

<script>
import showToast from "@/components/toast";
import SoarToolTip from "@/components/SoarToolTip";

export default {
  components: {
    SoarToolTip,
  },
  props: {
    value: {
      type: [File, String],
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    tips: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      focus: false,
      fileName: "",
      fileSrc: "",
    };
  },
  methods: {
    emitInput(e) {
      const val = e.target.value;
      console.log("emitInput", e);
      this.$emit("input", val);
      this.$emit("change", val);
    },
    emitFile(e) {
      const file = e.target.files[0];
      const allowedTypes = ['image/png', 'image/jpeg', 'image/svg+xml'];
      if (!allowedTypes.includes(file.type)) {
        showToast('Please upload images in PNG, JPEG or SVG format');
        return;
      }
      this.fileName = file.name;
      const reader = new FileReader();
      reader.onload = (event) => {
        this.fileSrc = event.target.result;
        this.$emit("fileChange", this.fileSrc);
      };
      reader.readAsDataURL(file);
      this.$emit("input", file);
      this.$emit("change", file);
    },
  },
};
</script>

<style lang="scss" scoped>
.field {
  margin-bottom: 10px;
  transition: all 0.3s ease;

  &__name {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    .name {
      font-size: 14px;
      font-weight: 500;
      line-height: 1;
      white-space: nowrap;
      color: var(--color);
    }
  }

  &__input {
    background-color: var(--field);
    border: 1px solid var(--field);
    border-radius: 8px;
    transition: all 0.3s ease;

    &.focus {
      border-color: var(--color);
    }

    input {
      width: 100%;
      background-color: transparent;
      border: none;
      outline: none;
      box-sizing: border-box;
      text-align: left;

      font-size: 14px;
      line-height: 1;
      font-weight: 500;
      padding: 12px 15px;
      box-sizing: border-box;
      color: var(--color);
    }
  }

  &__text {
    background-color: var(--field);
    border: 1px solid var(--field);
    border-radius: 8px;
    transition: all 0.3s ease;

    &.focus {
      border-color: var(--color);
    }

    textarea {
      width: 100%;
      background-color: transparent;
      border: none;
      outline: none;
      box-sizing: border-box;
      text-align: left;

      font-size: 14px;
      line-height: 1;
      font-weight: 500;
      padding: 10px 15px;
      box-sizing: border-box;
      color: var(--color);
    }
  }

  &__upload {
    background-color: var(--field);
    border: 1px solid var(--field);
    border-radius: 8px;
    transition: all 0.3s ease;
    position: relative;
    cursor: pointer;

    &.focus {
      border-color: var(--color);
    }

    input {
      width: 100%;
      background-color: transparent;
      border: none;
      outline: none;
      box-sizing: border-box;
      text-align: left;

      font-size: 14px;
      line-height: 1;
      font-weight: 500;
      padding: 10px 15px;
      box-sizing: border-box;
      color: var(--color);
      position: relative;
      opacity: 0;
      z-index: 2;
      cursor: pointer;
    }
    div {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;

      svg {
        height: 24px;
        color: var(--color-content);
      }

      img {
        height: 24px;
        width: 24px;
        object-fit: cover;
        border-radius: 6px;
      }

      span {
        font-size: 14px;
        font-weight: 600;
        line-height: 1;
        white-space: nowrap;
        color: var(--color-content);
        margin-left: 10px;
      }
    }
  }
}
</style>
