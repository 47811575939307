import request from "@/utils/request";
import iconUser from "@/assets/images/icon-user.png";

const BASE_URL = "https://api.moonshot.cc/token/v1";

export const getCoin = async (mint) => {
  const data = await request(`${BASE_URL}/solana/${mint}`);
  const { createdAt, marketCap, baseToken, moonshot, profile, pairAddress, volume, priceUsd } = data;
  const { address, symbol, name } = baseToken || {};
  const { creator } = moonshot || {};
  const { icon, links, decription } = profile || {};
  const { h24 } = volume || {};
  const { total } = h24 || {};
  const twitter = links && links.filter(item => {
    return item.indexOf('https://x.com') >= 0
  }) || "";
  const telegram = links && links.filter(item => {
    return item.indexOf('https://t.me') >= 0
  }) || "";
  moonshot.priceUsd = priceUsd;
  return {
      mint: address,
      name,
      address,
      symbol,
      description: decription,
      image_uri: icon && icon.replace(/\?.*/, ''),
      "metadata_uri": "https://cf-ipfs.com/ipfs/QmaFSKo4FX43NsVETn4nPfnFrXMqcGyK4mvKuCz2Pg65ji",
      twitter,
      telegram,
      "bonding_curve": pairAddress,
      "associated_bonding_curve": "HkkV2jspbnJAaQ4qG85aZ3R65Q9EaWhGehTLWd46uFvn",
      creator,
      moonshot,
      decimals: 9,
      "created_timestamp": createdAt,
      "raydium_pool": "GH8Ers4yzKR3UKDvgVu8cqJfGzU4cU62mTeg9bcJ7ug6",
      "complete": true,
      "virtual_sol_reserves": 115005359224,
      "virtual_token_reserves": 279900000000000,
      "total_supply": 1000000000000000,
      "website": null,
      "show_name": false,
      "king_of_the_hill_timestamp": 1712550773587,
      "market_cap": total,
      "reply_count": 0,
      "last_reply": 0,
      "nsfw": false,
      "market_id": "C5RUxJuZz5YC7s9zKiLrXePs9WxWNyJPY7jRhBe76fiJ",
      "inverted": true,
      "is_currently_live": false,
      "username": "",
      "profile_image": iconUser,
      "usd_market_cap": marketCap,
    };
};

export const getTrades = async (code) => {
  const data = await request(`https://api.moonshot.cc/trades/v1/latest/solana/${code}`);
  return (data || []).map((item) => {
    const { maker, type, amount0, amount1, blockTimestamp, txnId } = item;
    return {
      user: maker,
      is_buy: type === 'buy',
      sol_amount1: amount1,
      token_amount0: amount0,
      timestamp: blockTimestamp,
      signature: txnId
    }
  });
};

export const getReplies = async (code) => {
  return [];
};

export const getTopHolders = async (code) => {
  const { data } = await request({
    method: "POST",
    data: {
      url: `https://io.dexscreener.com/dex/pair-details/v3/solana/${code}`,
      method: "GET",
      isPuppeteer: true
    },
  });
  const { holders } = data && data.holders || {};
  return (holders || []).map((item) => {
    const { id, balance, percentage } = item;
    return {
      account: id,
      amount: balance,
      percent: percentage,
    }
  });
};

export const getUserBalances = async (code) => {
  return [];
};

export const getUserReplies = async (code) => {
  try {
    return [
      {
          "id": 8581780,
          "mint": "Ht1YmXuF7NNUkvVF8N84YVj5Qc39MzQbaY7jzAHsf8E",
          "file_uri": null,
          "text": "ssss",
          "user": "75FCfnJbNNqRFdjBhc53ZTqZB4CQg6WkjPQpqZj32uRr",
          "timestamp": 1723463272159,
          "hidden": false,
          "total_likes": 0,
          "sol_amount": null,
          "is_confirmed": null,
          "signature": null,
          "is_buy": null,
          "id_text": "8581780"
      }
    ];
  } catch (e) {
    return [];
  }
};

export const getFollowers = async (code) => {
  return [];
};

export const getFollowing = async (code) => {
  return [];
};

export const getUserCreatedCoins = async (code) => {
  return [];
};
