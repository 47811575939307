import Vue from "vue";
import Vuex from "vuex";
import Web3 from 'web3';
import { getChainByCode, getDefaultTokenByChain } from "@/utils/chain";

Vue.use(Vuex);

const SoarState = {
  key: "SoarState",
  get: () => {
    const temp = localStorage.getItem(SoarState.key);
    let s = temp && JSON.parse(temp) || {};
    // s.authToken = 'b26f17f021cb4f36bf7b74cef570672a'
    // s.authAddress='h9z9No9XGzd4dUMJCV65eH7oYTJXwBPjxjz2odXDQJG'
    return s
  },
  set: () => {
    const { account, wallet, chainCode, chainId, gasPrice, slippage, priorityFee, authToken, authEmail, authWallet, authAddress, authTime } = store.state;
    localStorage.setItem(
      SoarState.key,
      JSON.stringify({
        account,
        wallet,
        chainId,
        chainCode,
        slippage,
        priorityFee,
        gasPrice,
        authToken,
        authEmail,
        authWallet,
        authAddress,
        authTime
      })
    );
  },
};

window.addEventListener("beforeunload", (e) => {
  SoarState.set();
});

window.addEventListener("load", () => {
  const { state } = store;
  const { rpcUrls } = state;
  if (rpcUrls && rpcUrls.length > 0) {
    Object.assign(state, "provider", new Web3(rpcUrls[0]));
  }
  setInterval(() => {
    store.commit('SET_NOW_TIME', new Date().getTime());
  }, 1000);
})

const store = new Vuex.Store({
  state: {
    account: "",
    solBalance: "",
    wallet: "",
    chainCode: "bsc",
    chainId: 56,
    connect: false,
    slippage: 2,
    priorityFee: 0.0001,
    gasPrice: 5,
    provider: {},
    signer: null,
    nativeCurrency: null,
    tokenMap: {},
    authToken: "",
    authEmail: "",
    authWallet: "",
    authAddress: "",
    authSolBalance: "",
    authNetWalue: "",
    authTime: 0,
    nowTime: 0,
    ...SoarState.get(),
  },
  mutations: {
    SET_TOKENMAP (state, payload) {
      state.tokenMap = payload;
    },
    SET_NOW_TIME (state, payload) {
      state.nowTime = payload;
    },
    SET_GASPRICE (state, payload) {
      state.gasPrice = payload;
    },
    SET_ACCOUNT (state, payload) {
      state.account = payload;
    },
    SET_SOL_BALANCE (state, payload) {
      state.solBalance = payload;
    },
    SET_AUTH_TOKEN (state, payload) {
      state.authToken = payload;
    },
    SET_AUTH_EMAIL (state, payload) {
      state.authEmail = payload;
    },
    SET_AUTH_WALLET (state, payload) {
      state.authWallet = payload;
    },
    SET_AUTH_ADDRESS (state, payload) {
      state.authAddress = payload;
    },
    SET_AUTH_SOL_BALANCE (state, payload) {
      state.authSolBalance = payload;
    },
    SET_AUTH_NET_VALUE (state, payload) {
      state.authNetWalue = payload;
    },
    SET_AUTH_TIME (state, payload) {
      state.authTime = payload;
    },
    SET_WALLET (state, payload) {
      state.wallet = payload;
    },
    SET_SIGNER (state, payload) {
      state.signer = payload;
    },
    SET_CHAINCODE (state, payload) {
      state.chainCode = payload;
      const chain = getChainByCode(payload);
      if (chain) {
        const { chainId, rpcUrls } = chain;
        state.chainId = chainId;
        if (rpcUrls && rpcUrls.length) {
          state.provider = new Web3(rpcUrls[0]);
        }
      }
      SoarState.set();
    },
    SET_SLIPPAGE (state, payload) {
      state.slippage = payload;
    },
    SET_PRIORITY_FEE (state, payload) {
      state.priorityFee = payload;
    },
    SET_CHAINCODE (state, payload) {
      state.chainCode = payload;
      const chain = getChainByCode(payload);
      if (chain) {
        const { chainId, rpcUrls } = chain;
        state.chainId = chainId;
        if (rpcUrls && rpcUrls.length) {
          state.provider = new Web3(rpcUrls[0]);
        }
      }
      SoarState.set();
    },
  },
  actions: {
    async FETCH_CONTRACT ({ commit, state }) {
      // const { abi, contract, approveContract } = await getContract(state.chainCode);
      // commit("SET_SWAP_ABI", abi);
      // commit("SET_SWAP_CONTRACT", contract);
      // commit("SET_SWAP_APPROVE_CONTRACT", approveContract);
    },
  },
  modules: {},
});

export default store;
