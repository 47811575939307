<template>
  <div class="table-card" :class="{ top: top === true, empty: !loading && empty === true }">
    <div class="body" :class="{ ...customClass }">
      <table class="g-table">
        <caption v-if="title">{{ title }}</caption>
        <thead v-if="Object.keys(keys).length > 0">
          <tr>
            <th v-for="(item, i) in keys" :key="i">
              <div v-if="item.code" class="col g-clickable" :class="{ up: sortCol === item.code && sortType === 'up', down: sortCol === item.code && sortType === 'down' }" @click="sort(item.code)">
                <span class="tabs" v-if="item.tabs">
                  <span @click.stop="item.cb(j)" :class="{ active: item.active == j }" v-for="(obj, j) in item.tabs" :key="j">{{ obj }}</span>
                </span>
                <span v-else>{{ item.name === "Operation" ? "" : item.name }}</span>
                <div>
                  <svg viewBox="0 0 139.835 78.9918">
                    <path d="M131.19 78.99C138.32 78.99 142.88 69.47 137.42 64.01L76.05 2.63C72.53 -0.88 67.29 -0.88 63.77 2.63L2.4 64.01C-2.92 69.33 1.23 78.99 8.54 78.99L131.19 78.99Z" fill="currentColor" fill-rule="evenodd" />
                  </svg>
                  <svg viewBox="0 0 139.835 78.9918">
                    <path d="M8.64 0C1.51 0 -3.05 9.52 2.41 14.98L63.78 76.35C67.3 79.87 72.53 79.87 76.05 76.35L137.42 14.98C142.75 9.65 138.6 0 131.28 0L8.64 0Z" fill="currentColor" fill-rule="nonzero" />
                  </svg>
                </div>
              </div>
              <span v-else>{{ item.name === "Operation" ? "" : item.name }}</span>
            </th>
          </tr>
        </thead>
        <tbody class="g-scrollbar" v-if="!(loading || empty)">
          <slot />
        </tbody>
        <tbody v-else-if="loading">
          <tr class="no-hover" v-for="(item, i) in loadingRows" :key="i">
            <td v-for="(item, j) in keys" :key="i * 100 + j">
              <div class="g-skeleton-wrapper">
                <div class="g-skeleton" :style="{ opacity: (loadingRows - i) / loadingRows }"></div>
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot v-else-if="empty">
          <tr>
            <td :colspan="Object.keys(keys).length">
              <SoarEmpty />
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
import SoarEmpty from "@/components/SoarEmpty";

export default {
  components: {
    SoarEmpty
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    keys: {
      type: Array,
      default () {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingRows: {
      type: Number,
      default: 8
    },
    empty: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: Object,
      require: false,
      default: () => { },
    },
  },
  data () {
    return {
      sortCol: "",
      sortType: ""
    }
  },
  methods: {
    sort (code) {
      if (this.sortCol && this.sortCol === code) {
        this.sortType = this.sortType === "up" ? "down" : "up";
      } else {
        this.sortCol = code;
        this.sortType = "down";
      }
      this.$emit('sort', this.sortCol, this.sortType);
    }
  }
};
</script>

<style lang="scss" scoped>
.table-card {
  .g-table {
    border-collapse: collapse;
    width: 100%;

    tfoot {
      tr {
        border: none;
      }
    }

    th {
      text-align: left;

      .tabs {
        display: inline-block;
        border-radius: 5px;
        overflow: hidden;
        
        >span {
          display: inline-block;
          padding: 4px 6px;
          border: 1px solid var(--border-color);
          line-height: 1;
          position: relative;

          &.active {
            background: var(--button);
            z-index: 1;

          }
        }
      }

      &:last-child {
        text-align: right;

        .col {
          justify-content: flex-end;
        }
      }

      .col {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        span {
          font-size: 14px;
        }

        div {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          margin-left: 10px;

          svg {
            width: 8px;

            &:first-child {
              margin-bottom: 2px;
            }
          }
        }

        &.up {
          div {
            svg {
              &:first-child {
                color: var(--color-yellow);
              }
            }
          }
        }

        &.down {
          div {
            svg {
              &:last-child {
                color: var(--color-yellow);
              }
            }
          }
        }
      }
    }

    td {
      text-align: left;

      &:nth-last-child(2) {
        padding-right: 100px;
      }

      .g-skeleton-wrapper {
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .g-skeleton {
          width: 100%;
          height: 45px;
          border-radius: 12px;
        }
      }

      &:last-child {
        .g-skeleton-wrapper {
          justify-content: flex-end;
        }
      }

      .icon {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .icon-wrapper {
          height: 32px;
          display: flex;
          align-items: center;
          position: relative;
          margin-right: 6px;

          img {
            &:last-child {
              position: absolute;
              width: 16px;
              height: 16px;
              right: -6px;
              bottom: 0;
            }
          }
        }

        span {
          font-size: 14px;
        }
      }

      .button {
        float: right;

        width: 120px;
        height: 36px;
        border-radius: 18px;

        display: flex;
        align-items: center;
        justify-content: center;

        border: 1px solid var(--color-des);
        transition: all 0.3s ease;

        &:hover {
          border: 1px solid var(--color-blue);
          background-color: var(--color-blue);
        }

        span {
          font-size: 14px;
          color: var(--color);
        }
      }

      img {
        height: 24px;
        margin-right: 10px;
        vertical-align: middle;
      }

      a {
        color: var(--color);
        text-decoration: underline;
      }

      &:last-child {
        text-align: right;
        padding-right: 10px;
      }
    }
  }

  @media (min-width: 760px) {
    .extendOverflow {
      .g-table {
        tbody {
          max-height: calc(100vh - 450px);
        }
      }
    }

    .extendProfile {
      .g-table {
        tbody {
          max-height: initial;
          overflow: hidden;
          margin-right: 0;
          padding-right: 0;
        }
      }
    }

    .fixedFirstColumn {
      .g-table {
        th {
          &:first-child {
            width: calc(var(--firstColumnWith) + 10px);
            padding-left: 10px;
          }
        }

        td {
          &:first-child {
            width: var(--firstColumnWith);
            padding-left: 10px;
          }
        }
      }
    }

    .g-table {

      tbody tr,
      thead {
        display: table;
        width: 100%;
        table-layout: fixed;
      }

      tbody {
        display: block;
        max-height: 240px;
        margin-right: -12px;
        padding-right: 18px;
        overflow-y: auto;
        overflow-x: hidden;
      }

      tr {
        border-bottom: 1px solid var(--border-color);
      }
    }
  }

  @media (max-width: 750px) {
    .body {
      max-height: initial;
      overflow-x: auto;
      margin-right: 0;
      padding-right: 0;
    }

    .extendOverflow {
      max-height: initial;
      overflow: auto;
      margin-right: 0;
      padding-right: 0;
    }

    .fixedFirstColumn {
      .g-table {
        th {
          &:first-child {
            width: calc(var(--firstColumnWith) + 10px);
            padding-left: 10px;
          }
        }

        td {
          &:first-child {
            width: var(--firstColumnWith);
            padding-left: 10px;
          }
        }
      }
    }

    &.empty {
      .g-table {
        thead {
          display: none;
        }
      }
    }

    .g-table {
      table-layout: fixed;
      border-collapse: collapse;
      width: 100%;

      thead tr th {
        position: sticky;
        top: 0;
        background: var(--background);
      }

      th {
        width: 120px;
        padding: 10px;

        &:first-child {
          position: sticky;
          left: 0;
          z-index: 3;
          padding-left: 0;
        }

        &:nth-last-child(2) {
          padding-right: 80px;
        }

        &:last-child {
          text-align: right;
          position: sticky;
          right: 0;
          padding: 0;
          width: 36px;
          z-index: 3;
          padding-right: 0;

          .col {
            div {
              display: none;
            }
          }
        }
      }

      td {
        width: 120px;
        padding: 10px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        .g-skeleton-wrapper {
          height: 32px;

          .g-skeleton {
            height: 30px;
          }
        }

        &:nth-last-child(2) {
          padding-right: 80px;
        }

        &:first-child {
          position: sticky;
          left: 0;
          z-index: 2;
          background: var(--background);
          padding-left: 0;
        }

        &:last-child {
          position: sticky;
          right: 0;
          z-index: 2;
          width: 36px;
          padding: 0;
          background: var(--background);
          padding-right: 0;

          span,i {
            display: none;
          }
        }
      }
    }
  }
}
</style>