<template>
  <div class="container">
    <div class="content">
      <slot />
    </div>
  </div>
</template>

<script>
import HeaderBar from "@/components/HeaderBar.vue";
import { getChainByCode } from "@/utils/chain";

export default {
  components: {
    HeaderBar,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
  },
  computed: {
    chain() {
      if (!this.chainCode) return;
      return getChainByCode(this.chainCode);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 0 12px;
  .foot {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 56px;
    padding: 0 20px;
    background-color: #2b2b2c;
    border-radius: 8px 8px 0 0;
    display: none;
    align-items: center;
    justify-content: space-between;
    z-index: 5;

    &__account {
      height: 42px;

      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 2;

      img {
        width: 32px;
        margin-right: 6px;
      }

      span {
        font-size: 16px;
        color: var(--color);
      }
    }

    &__transaction {
      padding: 0 20px;
      height: 36px;
      border-radius: 18px;

      display: flex;
      align-items: center;
      justify-content: center;

      border: 1px solid rgba(255, 255, 255, 0.1);

      &:hover {
        border: 1px solid var(--color-blue);
        background-color: var(--color-blue);
      }

      a {
        font-size: 14px;
        color: var(--color);
        text-decoration: none;
      }
    }
  }

  @media (max-width: 750px) {
    padding: 20px 10px 80px;
    .foot {
      display: flex;
    }
  }
}
</style>