<template>
  <div id="head">
    <HeaderPc />
    <HeaderMobile />
  </div>
</template>

<script>
import HeaderPc from "@/components/HeaderPc";
import HeaderMobile from "@/components/HeaderMobile";

export default {
  components: {
    HeaderPc,
    HeaderMobile,
  },
  data() {
    return {};
  },
};
</script>
