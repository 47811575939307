<template>
  <NouDialog :dialogClass="{ search: true }" @click.native="close" @leave="close">
    <div class="search">
      <div class="search__head" :class="{ focus: focus }">
        <div class="search__icon">
          <svg viewBox="0 0 1025 1024">
            <path
              d="M942.515662 870.783433L803.879162 732.069161c53.985542-69.604264 86.128613-156.997684 86.128613-251.901147 0-227.201605-184.184883-411.383418-411.383418-411.383418-227.202629 0-411.383418 184.181813-411.383418 411.383418 0 227.197512 184.179766 411.383418 411.383418 411.383418 94.971002 0 182.421726-32.18912 252.049526-86.243223l138.640594 138.718365a51.632961 51.632961 0 0 0 73.199139 0 51.700499 51.700499 0 0 0 15.205307-36.623106 51.687196 51.687196 0 0 0-15.203261-36.620035zM222.962559 651.374304c-81.683371-122.089639-65.742307-284.876166 38.068014-388.801097a305.799708 305.799708 0 0 1 217.593784-90.294492c146.891512-0.113587 273.407973 103.554494 302.167994 247.605308 28.755927 144.048767-48.247864 288.355407-183.92394 344.657714-135.674029 56.301284-292.223504 8.921183-373.905852-113.167433z"
              fill="currentColor"></path>
            <path d="M550.933219 284.863886c-19.051915-20.996197-49.764404-20.996197-68.811202 0-18.885116 21.177322-18.885116 55.32198 0 76.504418L585.461617 475.993948c19.051915 20.99108 49.759287 20.99108 68.811202 0 18.816554-21.255093 18.709107-55.399751-0.2415-76.509534l-103.0981-114.620528z"
              fill="var(--color-yellow)"></path>
          </svg>
        </div>
        <div class="search__input">
          <input type="text" :placeholder="focus ? '' : 'Search token address'" @focus="focus = true" @blur="focus = false" @input="input" v-model="address" />
        </div>
        <div class="search__clear g-clickable" @click="clear" v-if="address">
          <svg viewBox="0 0 1024 1024">
            <path
              d="M592.15872 511.9488L999.61856 26.25536c6.83008-8.06912 1.08544-20.33664-9.46176-20.33664H866.28352c-7.30112 0-14.2848 3.25632-19.08736 8.84736L511.1296 415.39584 175.07328 14.76608c-4.64896-5.59104-11.64288-8.84736-19.08736-8.84736H32.11264c-10.55744 0-16.29184 12.26752-9.46176 20.33664l407.45984 485.69344L22.64064 997.64224c-6.83008 8.06912-1.08544 20.33664 9.46176 20.33664h123.86304c7.30112 0 14.27456-3.25632 19.08736-8.8576l336.05632-400.62976 336.06656 400.62976c4.6592 5.59104 11.64288 8.8576 19.08736 8.8576h123.86304c10.5472 0 16.29184-12.26752 9.46176-20.33664L592.15872 511.9488z m0 0"
              fill="currentColor"></path>
          </svg>
        </div>
      </div>
      <div class="search__result">
        <div class="search__loading" v-if="loading">
          <span class="g-loader"></span>
        </div>
        <a :href="`/${chainCode}/${item.source}/${item.mint}`" class="search__card g-clickable-small" v-else-if="item">
          <SoarToken :item="item" :type="1" />
          <div class="stats">
            <div>
              <span>MC</span>
              <span>${{ item.marketCap | nFormatter }}</span>
              <span>LIQ(<i>{{ item.isExternal ? '$' : 'WSOL' }}</i>)</span>
              <span>{{ item.liquidity | nFormatter }}</span>
            </div>
            <div>
              <span>VOL</span>
              <span>${{ item.tradeVolume | nFormatter }}</span>
              <span>TXS</span>
              <span>{{ item.tradeCount | nFormatter(0) }}</span>
            </div>
          </div>
        </a>
        <div class="nothing" v-else>
          <span>Nothing found...</span>
        </div>
      </div>
    </div>
  </NouDialog>
</template>

<script>
import NouDialog from "@/components/common/NouDialog";
import SoarToken from "@/components/SoarToken";
import { debounce } from "@/utils/helper";
import showToast from "@/components/toast";
import { getToken } from "@/api/meme";
import { isSolanaAddress } from "@/utils/helper";

export default {
  components: {
    NouDialog,
    SoarToken,
  },
  data () {
    return {
      focus: false,
      loading: false,
      address: "",
      item: null,
    };
  },
  methods: {
    close () {
      this.$emit("close");
    },
    clear () {
      this.address = "";
    },
    input: debounce(async function (e) {
      const address = e.target.value;
      if (isSolanaAddress(address)) {
        this.loading = true;
        try {
          const item = await getToken({
            address,
          });

          if (item) {
            this.$set(this, "item", item);
            this.loading = false;
            return
          }
        } catch (e) {
        }
      }
      this.loading = false;
      this.$set(this, "item", '');
    }, 500),
  },
};
</script>

<style lang="scss" scoped>
.search {
  &__head {
    height: 45px;
    display: flex;
    align-items: center;
    border: 1px solid var(--border-color);
    border-radius: 10px;
    box-sizing: border-box;
    transition: all 0.3s ease;

    &.focus {
      border-color: var(--color-content);
    }
  }

  &__icon {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 18px;
      color: var(--color-content);
    }
  }

  &__input {
    flex: 1;
    margin-left: 3px;

    input {
      width: 100%;
      line-height: 21px;
      border: none;
      outline: none;
      background: none;
      margin: 0;
      padding: 0;
      font-size: 14px;
      font-weight: 400;
      color: var(--color);

      &::placeholder {
        color: var(--color-des);
        font-weight: 400;
      }
    }
  }

  &__clear {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 12px;
      color: var(--color-content);
    }
  }

  &__result {
    min-height: 210px;

    .nothing {
      min-height: 210px;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-size: 16px;
        color: var(--color-des);
      }
    }
  }

  &__loading {
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;

    .g-loader {
      width: 42px;
      height: 42px;
      border-width: 3px;
    }
  }

  &__card {
    display: grid;
    align-items: center;
    grid-gap: 10px;
    grid-template-columns: auto 1fr;
    border: 1px solid var(--border-color);
    padding: 8px 10px 8px 10px;
    border-radius: 10px;
    margin-top: 10px;
    text-decoration: none;

    &:hover {
      border-color: var(--color-content);
    }

    .stats {
      &>div {
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 6px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      span {
        font-size: 12px;

        &:nth-child(2n) {
          font-weight: 600;
          margin-left: 3px;
          color: var(--color);
        }

        &:nth-child(2n + 1) {
          color: var(--color-des);
          margin-left: 6px;
        }

        i {
          font-size: 10px;
        }
      }
    }
  }
}
</style>