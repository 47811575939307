<template>
  <div class="portfolio" @click="close">
    <div class="dialog" @click.stop>
      <div class="dialog__title">
        <span>Profile</span>
        <div class="icon g-clickable" @click="close">
          <i class="iconfont icon-close" />
        </div>
      </div>
      <div class="dialog__panel">
        <div class="address">
          <div class="address__title">
            <span>Address</span>
            <a
              :href="`https://solscan.io/address/${authAddress}`"
              target="_blank"
            >
              <svg viewBox="0 0 10 10">
                <path
                  d="M4 2V3H1.5V8.5H7V6H8V9C8 9.13261 7.94732 9.25979 7.85355 9.35355C7.75979 9.44732 7.63261 9.5 7.5 9.5H1C0.867392 9.5 0.740215 9.44732 0.646447 9.35355C0.552678 9.25979 0.5 9.13261 0.5 9V2.5C0.5 2.36739 0.552678 2.24021 0.646447 2.14645C0.740215 2.05268 0.867392 2 1 2H4ZM9.5 0.5V4.5H8.5V2.2065L4.6035 6.1035L3.8965 5.3965L7.7925 1.5H5.5V0.5H9.5Z"
                  fill="currentColor"
                ></path>
              </svg>
            </a>
          </div>
          <div class="address__content" @click.stop="copy(authAddress)">
            <div class="g-clickable">
              <span>{{ authAddress }}</span>
              <svg viewBox="0 0 15.0033 15.0033">
                <g
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path
                    d="M4.835,4.835 L4.835,2.96833 C4.835,2.22159 4.835,1.84822 4.98032,1.56301 C5.10815,1.31213 5.31213,1.10815 5.56301,0.98032 C5.84822,0.835 6.22159,0.835 6.96833,0.835 L12.035,0.835 C12.7817,0.835 13.1551,0.835 13.4403,0.98032 C13.6912,1.10815 13.8952,1.31213 14.023,1.56301 C14.1683,1.84822 14.1683,2.22159 14.1683,2.96833 L14.1683,8.035 C14.1683,8.78173 14.1683,9.1551 14.023,9.44032 C13.8952,9.6912 13.6912,9.8952 13.4403,10.023 C13.1551,10.1683 12.7817,10.1683 12.035,10.1683 L10.1683,10.1683"
                    id="Path"
                    stroke="currentColor"
                    stroke-width="1.67"
                    fill-rule="nonzero"
                  ></path>
                  <path
                    d="M2.96833,14.1683 L8.035,14.1683 C8.78173,14.1683 9.1551,14.1683 9.44032,14.023 C9.6912,13.8952 9.8952,13.6912 10.023,13.4403 C10.1683,13.1551 10.1683,12.7817 10.1683,12.035 L10.1683,6.96833 C10.1683,6.22159 10.1683,5.84822 10.023,5.56301 C9.8952,5.31213 9.6912,5.10815 9.44032,4.98032 C9.1551,4.835 8.78173,4.835 8.035,4.835 L2.96833,4.835 C2.22159,4.835 1.84822,4.835 1.56301,4.98032 C1.31213,5.10815 1.10815,5.31213 0.98032,5.56301 C0.835,5.84822 0.835,6.22159 0.835,6.96833 L0.835,12.035 C0.835,12.7817 0.835,13.1551 0.98032,13.4403 C1.10815,13.6912 1.31213,13.8952 1.56301,14.023 C1.84822,14.1683 2.22159,14.1683 2.96833,14.1683 Z"
                    id="Path"
                    stroke="currentColor"
                    stroke-width="1.67"
                    fill-rule="nonzero"
                  ></path>
                </g>
              </svg>
            </div>
          </div>
        </div>
        <div class="button-group">
          <div class="button g-clickable" @click="deposit">
            <svg viewBox="0 0 256 256">
              <path
                fill="currentColor"
                d="M228 144v64a12 12 0 0 1-12 12H40a12 12 0 0 1-12-12v-64a12 12 0 0 1 24 0v52h152v-52a12 12 0 0 1 24 0m-108.49 8.49a12 12 0 0 0 17 0l40-40a12 12 0 0 0-17-17L140 115V32a12 12 0 0 0-24 0v83L96.49 95.51a12 12 0 0 0-17 17Z"
              />
            </svg>
            <span>Deposit</span>
          </div>
          <div class="button g-clickable" @click="withdraw">
            <svg viewBox="0 0 256 256">
              <path
                fill="currentColor"
                d="M228 144v64a12 12 0 0 1-12 12H40a12 12 0 0 1-12-12v-64a12 12 0 0 1 24 0v52h152v-52a12 12 0 0 1 24 0M96.49 80.49L116 61v83a12 12 0 0 0 24 0V61l19.51 19.52a12 12 0 1 0 17-17l-40-40a12 12 0 0 0-17 0l-40 40a12 12 0 1 0 17 17Z"
              />
            </svg>
            <span>Withdraw</span>
          </div>
          <div class="button g-clickable" @click="disConnect">
            <svg viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M13 3h-2v10h2zm4.83 2.17l-1.42 1.42A6.92 6.92 0 0 1 19 12c0 3.87-3.13 7-7 7A6.995 6.995 0 0 1 7.58 6.58L6.17 5.17A8.93 8.93 0 0 0 3 12a9 9 0 0 0 18 0c0-2.74-1.23-5.18-3.17-6.83"
              />
            </svg>
            <span>Logout</span>
          </div>
        </div>
      </div>
      <div class="dialog__tab">
        <div
          class="item g-clickable"
          :class="{ on: tabCode === item.code }"
          v-for="(item, i) in tabList"
          :key="i"
          @click="tabChange(item)"
        >
          <span>{{ item.name }}</span>
        </div>
        <div
          class="line"
          :style="{ left: tabCode === '01' ? '0px' : '50%' }"
        ></div>
      </div>
      <div class="dialog__list">
        <Balances v-show="tabCode === '01'" />
        <Transactions v-show="tabCode === '02'" />
      </div>
    </div>
  </div>
</template>

<script>
import SoarContainer from "@/components/SoarContainer";
import Transactions from "./components/Transactions";
import Balances from "./components/Balances";
import Info from "./components/Info";
import Replies from "./components/Replies";
import UserCreatedCoins from "./components/UserCreatedCoins";
import Followers from "./components/Followers";
import Notifications from "./components/Notifications";
import Following from "./components/Following";
import { disConnect } from "@/init";

export default {
  components: {
    SoarContainer,
    Transactions,
    Balances,
    Info,
    Replies,
    Followers,
    Following,
    Notifications,
    UserCreatedCoins,
  },
  data() {
    return {
      tabCode: "01",
      tabList: [
        {
          code: "01",
          name: "Balances",
        },
        {
          code: "02",
          name: "Trades",
        },
      ],
    };
  },
  mounted() {
    document.body.style.overflow = 'hidden';
  },
  methods: {
    tabChange(item) {
      const { code } = item;
      this.tabCode = code;
    },
    close() {
      this.$emit("close");
      document.body.style.overflow = 'visible';
    },
    deposit() {
      this.$emit("deposit");
      this.close();
    },
    withdraw() {
      this.$emit("withdraw");
      this.close();
    },
    disConnect() {
      disConnect();
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.portfolio {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9998;
  backdrop-filter: blur(3px);
}
.dialog {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 360px;
  padding: 16px;
  background: var(--background);
  border-left: 1px solid var(--border-color);
  border-radius: 16px;

  @media (max-width: 750px) {
    width: 100%;
    border: none;
    border-radius: 0;
    box-sizing: border-box;
  }

  &__title {
    padding: 10px 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--border-color);

    span {
      font-size: 20px;
      font-weight: 600;
      color: var(--color);
    }

    .icon {
      width: 30px;
      height: 30px;
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--border-color);

      i {
        font-size: 18px;
        font-weight: 600;
        color: var(--color-content);
        transition: all 0.3s ease;
      }

      &:hover {
        i {
          color: var(--color);
        }
      }
    }
  }

  &__panel {
    margin-top: 16px;
    .address {
      padding: 20px;
      border-radius: 16px;
      background: var(--card);

      &__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px 0 5px;

        span {
          font-size: 16px;
          font-weight: 600;
          color: var(--color);
        }

        a {
          svg {
            width: 16px;
            color: var(--color-content);
          }
          &:hover {
            svg {
              color: var(--color);
            }
          }
        }
      }

      &__content {
        margin-top: 10px;
        border-radius: 10px;
        background: var(--background);

        div {
          padding: 10px;
          position: relative;
        }

        span {
          font-size: 14px;
          line-height: 1.6;
          word-break: break-all;
        }

        svg {
          position: absolute;
          right: 10px;
          bottom: 10px;
          width: 16px;
          color: var(--border-color);
        }
      }
    }

    .button-group {
      margin-top: 16px;
      display: grid;
      grid-gap: 10px;
      grid-template-columns: 1fr 1fr 1fr;

      .button {
        height: 82px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background: var(--card);
        border-radius: 10px;

        span {
          font-size: 14px;
          color: var(--color-content);
        }

        svg {
          height: 20px;
          color: var(--color-content);
          margin-bottom: 8px;
        }

        &:hover {
          background: var(--border-color);
          span,
          svg {
            color: var(--color);
          }
        }
      }
    }
  }

  &__tab {
    margin-top: 16px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--border-color);
    position: relative;

    .item {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-size: 16px;
        color: var(--color-content);
      }

      &.on {
        span {
          color: var(--color);
        }
      }
    }

    .line {
      position: absolute;
      left: 0;
      width: 50%;
      bottom: -2px;
      height: 3px;
      background: var(--color-yellow);
      border-radius: 3px;
      transition: all 0.3s ease;
    }
  }
}
.card {
  background-color: var(--card);
  padding: 24px;
  border-radius: 24px;
  margin-bottom: 20px;
  box-sizing: border-box;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.005),
    0 20px 70px 0 rgba(0, 0, 0, 0.01);
}
.right {
  display: flex;
  flex-direction: column;
}
.tab {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;
  margin: 0 -20px 10px;
  padding: 0 20px 10px;
  border-bottom: 1px solid var(--border-color);

  &__item {
    padding: 0 15px;
    position: relative;
    &:first-child {
      padding-left: 0;
    }
    span {
      font-size: 14px;
      color: var(--color-content);
    }
    &.on {
      span {
        font-weight: 600;
        color: var(--color);
      }
    }
  }
}
.list {
  flex: 1;
  position: relative;
  &__inner {
    position: absolute;
    left: 0;
    right: -24px;
    padding-right: 24px;
    top: 0;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
</style>