import request from "@/utils/request";
import BigNumber from "bignumber.js";
import {
  BASE_URL,
  changeIPFS,
  OPEN_API_V3,
} from "@/utils/config";

export const getCoin = async (mint) => {
  const { data } = await request({
    method: "POST",
    data: {
      url: `${BASE_URL}/coins/${mint}`,
    },
  });
  if (data) {
    data.image_uri = changeIPFS(data.image_uri);
    data.metadata_uri = changeIPFS(data.metadata_uri);
    data.symbol = data.symbol && data.symbol.toUpperCase();
    data.decimals = 6;
  }
  return data;
};

export const getSolPrice = async () => {
  const { data } = await request({
    method: "POST",
    data: {
      url: `${BASE_URL}/sol-price`,
    },
  });
  const { solPrice } = data || {};
  return solPrice;
};

export const getTrades = async (code) => {
  const { data } = await request({
    method: "POST",
    data: {
      url: `${BASE_URL}/trades/all/${code}?limit=200&offset=0&order=desc`,
    },
  });
  return (data || []).map((item) => {
    item.profile_image = item.profile_image && changeIPFS(item.profile_image);
    return item;
  });
};

export const getReplies = async (code) => {
  const { data } = await request({
    method: "POST",
    data: {
      url: `${BASE_URL}/replies/${code}?limit=200&offset=0&order=DESC`,
    },
  });
  return (data || []).map((item) => {
    item.profile_image = item.profile_image && changeIPFS(item.profile_image);
    item.file_uri = item.file_uri && changeIPFS(item.file_uri);
    return item;
  });
};

export const getTopHolders = async (code) => {
  const { data } = await request(`${OPEN_API_V3}meme/${code}/top_holder`);
  const { holders } = data || {};
  const list = [];
  let sum = new BigNumber(0);
  for (let account in holders) {
    const amount = holders[account] || 0;
    if (amount >= 0) {
      list.push({
        account,
        amount: holders[account],
      });
      sum = sum.plus(amount);
    }
  }
  const result = list.map((item) => {
    const { account, amount } = item;
    const percent = new BigNumber(amount)
      .dividedBy(sum)
      .multipliedBy(100)
      .toFixed(2);
    return {
      account,
      amount,
      percent,
    };
  });
  return result;
};

export const getUserBalances = async (code) => {
  try {
    const { data } = await request({
      method: "POST",
      data: {
        url: `${BASE_URL}/balances/${code}?limit=200&offset=0&minBalance=-1`,
      },
    });
    return (data || []).map((item) => {
      item.image_uri = item.image_uri && changeIPFS(item.image_uri);
      item.symbol = item.symbol && item.symbol.toUpperCase();
      return item;
    });
  } catch (e) {
    return [];
  }
};

export const getUserReplies = async (code) => {
  try {
    return [
      {
        id: 8581780,
        mint: "Ht1YmXuF7NNUkvVF8N84YVj5Qc39MzQbaY7jzAHsf8E",
        file_uri: null,
        text: "ssss",
        user: "75FCfnJbNNqRFdjBhc53ZTqZB4CQg6WkjPQpqZj32uRr",
        timestamp: 1723463272159,
        hidden: false,
        total_likes: 0,
        sol_amount: null,
        is_confirmed: null,
        signature: null,
        is_buy: null,
        id_text: "8581780",
      },
    ];
    const { data } = await request({
      method: "POST",
      data: {
        url: `${BASE_URL}/replies/user-replies/${code}?limit=50&offset=0`,
      },
    });
    return (data || []).map((item) => {
      item.image_uri = item.image_uri && changeIPFS(item.image_uri);
      item.symbol = item.symbol && item.symbol.toUpperCase();
      return item;
    });
  } catch (e) {
    return [];
  }
};

export const getFollowers = async (code) => {
  try {
    const { data } = await request({
      method: "POST",
      data: {
        url: `${BASE_URL}/following/followers/${code}`,
      },
    });
    return (data || []).map((item) => {
      item.profile_image = item.profile_image && changeIPFS(item.profile_image);
      return item;
    });
  } catch (e) {
    return [];
  }
};

export const getFollowing = async (code) => {
  try {
    const { data } = await request({
      method: "POST",
      data: {
        url: `${BASE_URL}/following/${code}`,
      },
    });
    return (data || []).map((item) => {
      item.profile_image = item.profile_image && changeIPFS(item.profile_image);
      return item;
    });
  } catch (e) {
    return [];
  }
};

export const getUserCreatedCoins = async (code) => {
  try {
    const { data } = await request({
      method: "POST",
      data: {
        url: `${BASE_URL}/coins/user-created-coins/${code}?limit=200&offset=0&includeNsfw=false`,
      },
    });
    return (data || []).map((item) => {
      item.image_uri = item.image_uri && changeIPFS(item.image_uri);
      item.symbol = item.symbol && item.symbol.toUpperCase();
      return item;
    });
  } catch (e) {
    return [];
  }
};

export const getTxs = async (chainKey, account) => {
  const { data } = await request(
    `${OPEN_API_V3}${chainKey}/getTxs?account=${account}&pageSize=20&pageNumber=1`
  );
  return data;
};

export const ipfs = async (form) => {
  try {
    const data = await request({
      method: "POST",
      url: `${BASE_URL}/api/ipfs`,
      data: form,
      headers: { "Content-Type": "multipart/form-data" },
    });
    return data;
  } catch (e) {
    return [];
  }
};
