
import { FOUR_ABI_V1, FOUR_ABI_V2, FOUR_ABI_V3, ERC20 } from "@/utils/config";
import { getContractByRPC } from "@/init";
import { myWallet } from "@/init";
import showLoading from "@/components/loading";
import { decimals2Amount, amount2Decimals } from "@/utils/number";
import { confirmLater, isNativeToken } from "@/utils/helper";
import BigNumber from "bignumber.js";
import { getSwap } from "@/api/cross";

export const getGasPrice = async () => {
  try {
    // if (myWallet.eth) {
    //   const gasPrice = await myWallet.eth.getGasPrice();
    //   return parseInt(gasPrice * 1.2);
    // }
    return 1200000000;
  } catch (e) {
    return 1200000000;
  }
};

export const getAllowance = async (tokenAddress, contractAddress, account) => {
  try {
    const tokenContract = new myWallet.eth.Contract(ERC20, tokenAddress);
    const result = await tokenContract.methods
      .allowance(account, contractAddress)
      .call();
    return result;
  } catch (e) {
    throw e;
  }
};

export const sendApproveTo = async (
  tokenAddress,
  contractAddress,
  amount,
  gasPrice,
  account
) => {
  try {
    const tokenContract = new myWallet.eth.Contract(ERC20, tokenAddress);
    const result = await tokenContract.methods
      .approve(contractAddress, amount)
      .send({
        from: account,
        gasPrice,
      });
    return result;
  } catch (e) {
    throw e;
  }
};

export const checkApprove = async (
  tokenAddress,
  contractAddress,
  amount,
  gasPrice,
  account
) => {
  // get allowance
  const result = await getAllowance(tokenAddress, contractAddress, account);
  if (BigNumber(result).comparedTo(amount) < 0) {
    return await sendApproveTo(tokenAddress, contractAddress, amount, gasPrice, account);
  }
  return true;
};

export const executeTx = async(fn, txParams) => {
  try {
    const gas = await fn.estimateGas(txParams);
    txParams.gasLimit = Math.floor(gas * 1.5);
    return await fn.send(txParams);
  } catch (e) {
    throw e;
  }
}

export const getException = (e) => {
  try {
    const { message } = JSON.parse(e.message.replace(/^.*/i, ''));
    return message;
  } catch (m) {
    return e.message;
  }
}

export const calc = (params) => {
  const { baseAsset, quoteAsset, amount, direction, label, cb, item, account} = params;
  const { four } = item;
  const { progress, tokenManager, tokenManagerHelper, version } = four || {};
  if (+progress === 1) {
    const chainCode = "56";
    const swapParams = {
      slippage: 100,
      account,
      referrer: "0x3487ef9f9b36547e43268b8f0e2349a226c70b53",
      referrerFee: 30,
      disableRfq: 1,
      gasPrice: 1000000000
    };
    const baseAssetAddress = baseAsset.address === '0x0000000000000000000000000000000000000000' ? '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE' : baseAsset.address;  
    if (direction === 'buy') {
      if (label === baseAsset.symbol) {
        Object.assign(swapParams, {
          inTokenSymbol: quoteAsset.symbol,
          inTokenAddress: quoteAsset.address,
          outTokenSymbol: baseAsset.symbol,
          outTokenAddress: baseAssetAddress,
          amount: amount2Decimals(amount, quoteAsset.decimals),
        })
      } else {
        Object.assign(swapParams, {
          inTokenSymbol: baseAsset.symbol,
          inTokenAddress: baseAssetAddress,
          outTokenSymbol: quoteAsset.symbol,
          outTokenAddress: quoteAsset.address,
          amount: amount2Decimals(amount, baseAsset.decimals),
        })
      }
    } else if (direction === 'sell') {
      Object.assign(swapParams, {
        inTokenSymbol: quoteAsset.symbol,
        inTokenAddress: quoteAsset.address,
        outTokenSymbol: baseAsset.symbol,
        outTokenAddress: baseAssetAddress,
        amount: amount2Decimals(amount, quoteAsset.decimals),
      })
    }
    getSwap(chainCode, swapParams).then(res => {
      const { outAmount, outToken } = res;
      const { decimals } = outToken || {};
      const _outAmount = decimals2Amount(outAmount, decimals || 18);
      return cb && cb(_outAmount);
    })
    return 0;
  }
  if (version === 'V1') {
    const contractV1 = getContractByRPC("bsc", FOUR_ABI_V1, tokenManager);
    contractV1._tokenInfos(item.address).then(res => {
      const { K, T } = res;
      const e = new BigNumber(decimals2Amount(K.toString(), 18));
      const t = new BigNumber(decimals2Amount(T.toString(), 18));
      const p = e.div(t);
      const L = amount;
      if (direction === 'buy') {
        if (label === baseAsset.symbol) {
          const r = e.div(t.minus(L)).minus(p).toString();
          cb(r);
        } else {
          const r = t.minus(e.div(p.plus(L))).toString();
          cb(r);
        }
      } else {
        const r = e.div(t.minus(L)).minus(p).toString();
        cb(r);
      }
    })
    return 0;
  }
  if (version === 'V2') {
    const contract = getContractByRPC("bsc", FOUR_ABI_V3, tokenManagerHelper);
    if (direction === 'buy') {
      if (label === baseAsset.symbol) {
        contract.tryBuy(quoteAsset.address, amount2Decimals(amount, quoteAsset.decimals), 0).then(res => {
          const { suggestedFunds } = res;
          const d = decimals2Amount(suggestedFunds.toString(), baseAsset.decimals);
          return cb && cb(d);
        })
      } else {
        contract.tryBuy(quoteAsset.address, 0, amount2Decimals(amount, baseAsset.decimals)).then(res => {
          const { estimatedAmount } = res;
          const d = decimals2Amount(estimatedAmount.toString(), quoteAsset.decimals);
          return cb && cb(d);
        })
      }
    } else if (direction === 'sell') {
      contract.trySell(quoteAsset.address, amount2Decimals(amount, quoteAsset.decimals)).then(res => {
        const { funds } = res;
        const d = decimals2Amount(funds.toString(), baseAsset.decimals);
        return cb && cb(d);
      })
    };
  }
  if (version === 'V3') {
    const contract = getContractByRPC("bsc", FOUR_ABI_V3, tokenManagerHelper);
    if (direction === 'buy') {
      if (label === baseAsset.symbol) {
        contract.tryBuy(quoteAsset.address, amount2Decimals(amount, quoteAsset.decimals), 0).then(res => {
          const { suggestedFunds } = res;
          const d = decimals2Amount(suggestedFunds.toString(), baseAsset.decimals);
          return cb && cb(d);
        })
      } else {
        contract.tryBuy(quoteAsset.address, 0, amount2Decimals(amount, baseAsset.decimals)).then(res => {
          const { estimatedAmount } = res;
          const d = decimals2Amount(estimatedAmount.toString(), quoteAsset.decimals);
          return cb && cb(d);
        })
      }
    } else if (direction === 'sell') {
      contract.trySell(quoteAsset.address, amount2Decimals(amount, quoteAsset.decimals)).then(res => {
        const { funds } = res;
        const d = decimals2Amount(funds.toString(), baseAsset.decimals);
        return cb && cb(d);
      })
    };
  }
  return 0;
}

export const buy = async (params) => {
  const chainCode = "bsc";
  const instanct = showLoading({
    text: "Obtaining token information and quotation",
  });
  try {
    const { baseAsset, baseAmount, quoteAsset, tokenAmount, coinData, account, direction } = params;
    const { four } = coinData;
    const { version, progress, tokenManager } = four || {};
    if (+progress === 1) {
      return await swap(instanct, account, {
        inTokenSymbol: baseAsset.symbol,
        inTokenAddress: baseAsset.address,
        outTokenSymbol: quoteAsset.symbol,
        outTokenAddress: quoteAsset.address,
        amount: amount2Decimals(baseAmount, baseAsset.decimals),
      });
    }
    let _tokenAmount = 0, _baseAmount = 0;
    if (direction === 'quote') {
      _tokenAmount = new BigNumber(tokenAmount).times(new BigNumber(1).minus(5 / 100)).toString();
    } else {
      _baseAmount = new BigNumber(baseAmount).times(new BigNumber(1).plus(5 / 100)).toString();
    }
    instanct.change({
      status: "loading",
      chainCode,
      text: `Swaping ${baseAmount} ${baseAsset.symbol} for ${tokenAmount} ${quoteAsset.symbol}`,
    });
    let func = null;
    const txParams = {
      from: account,
      to: tokenManager,
      value: 0
    };
    const gasPrice = await getGasPrice();
    if (direction === 'quote') {
      const funds = amount2Decimals(baseAmount, baseAsset.decimals);
      const minAmount = amount2Decimals(_tokenAmount, quoteAsset.decimals);
      if (!isNativeToken(baseAsset.address)) {
        await checkApprove(baseAsset.address, tokenManager, funds, gasPrice, account);
      }
      if (version === 'V1') {
        const contract = new myWallet.eth.Contract(FOUR_ABI_V1, tokenManager);
        const buyFee = 0.005;
        const minTradeFee = 0.001;
        const i = new BigNumber(baseAmount).times(buyFee);
        const t = new BigNumber(baseAmount).plus(i.lt(minTradeFee) ? minTradeFee : i).toString();
        const value = amount2Decimals(t, baseAsset.decimals);
        txParams.value = isNativeToken(baseAsset.address) ? value : 0;
        func = contract.methods.purchaseTokenAMAP(quoteAsset.address, funds, minAmount);
      } else {
        const contract = new myWallet.eth.Contract(FOUR_ABI_V2, tokenManager);
        txParams.value = isNativeToken(baseAsset.address) ? funds : 0;
        func = contract.methods.buyTokenAMAP(quoteAsset.address, funds, minAmount);
      }
    } else {
      const maxFunds = amount2Decimals(_baseAmount, baseAsset.decimals);
      const amount = amount2Decimals(tokenAmount, quoteAsset.decimals);
      if (!isNativeToken(baseAsset.address)) {
        await checkApprove(baseAsset.address, tokenManager, maxFunds, gasPrice, account);
      }
      if (version === 'V1') {
        const contract = new myWallet.eth.Contract(FOUR_ABI_V1, tokenManager);
        const buyFee = 0.005;
        const minTradeFee = 0.001;
        const i = new BigNumber(baseAmount).times(buyFee);
        const t = new BigNumber(baseAmount).plus(i.lt(minTradeFee) ? minTradeFee : i).toString();
        const value = amount2Decimals(t, baseAsset.decimals);
        txParams.value = isNativeToken(baseAsset.address) ? value : 0;
        func = contract.methods.purchaseToken(quoteAsset.address, amount, value);
      } else {
        const contract = new myWallet.eth.Contract(FOUR_ABI_V2, tokenManager);
        txParams.value = isNativeToken(baseAsset.address) ? maxFunds : 0;
        func = contract.methods.buyToken(quoteAsset.address, amount, maxFunds);
      }
    }
    const result = await executeTx(func, txParams);
    const { transactionHash } = result || {};
    if (transactionHash) {
      instanct.change({
        message: "submit",
        chainCode,
        hash: transactionHash,
        status: "success",
      });
    } else {
      instanct.change({
        message: "Transaction not confirmed.",
        chainCode,
        status: "rejected",
      });
    }
  } catch (e) {
    instanct.change({
      message: getException(e),
      chainCode,
      status: "rejected",
    });
  }
};

export const sell = async (params) => {
  const chainCode = "bsc";
  const instanct = showLoading({
    text: "Obtaining token information and quotation",
  });
  try {
    const { baseAsset, quoteAsset, coinData, baseAmount, tokenAmount, tokenAmountDecimals, account } = params;
    const { four } = coinData;
    const { version, progress, tokenManager } = four || {};
    if (+progress === 1) {
      return await swap(instanct, account, {
        inTokenSymbol: quoteAsset.symbol,
        inTokenAddress: quoteAsset.address,
        outTokenSymbol: baseAsset.symbol,
        outTokenAddress: baseAsset.address,
        amount: amount2Decimals(tokenAmount, quoteAsset.decimals)
      });
    }
    instanct.change({
      status: "loading",
      chainCode,
      text: `Swaping ${tokenAmount} ${quoteAsset.symbol} for ${baseAmount} ${baseAsset.symbol}`
    });
    const gasPrice = await getGasPrice();
    const amount = tokenAmountDecimals || amount2Decimals(tokenAmount, quoteAsset.decimals);
    await checkApprove(quoteAsset.address, tokenManager, amount, gasPrice, account);

    let func = null;
    const txParams = {
      from: account,
      to: tokenManager,
      gasPrice
    }
    if (version === 'V1') {
      const contract = new myWallet.eth.Contract(FOUR_ABI_V1, tokenManager);
      func = contract.methods.saleToken(quoteAsset.address, amount);
    } else {
      const contract = new myWallet.eth.Contract(FOUR_ABI_V2, tokenManager);
      func = contract.methods.sellToken(quoteAsset.address, amount);
    }
    const result = await executeTx(func, txParams);
    const { transactionHash } = result;
    if (transactionHash) {
      instanct.change({
        message: "submit",
        chainCode,
        hash: transactionHash,
        status: "success",
      });
    } else {
      instanct.change({
        message: "Transaction not confirmed.",
        chainCode,
        status: "rejected",
      });
    }
  } catch (e) {
    instanct.change({
      message: getException(e),
      chainCode,
      status: "rejected",
    });
  }
};

async function swap(instanct, account, params) {
  const swapParams = {
    inTokenSymbol: params.inTokenSymbol,
    inTokenAddress: params.inTokenAddress,
    outTokenSymbol: params.outTokenSymbol,
    outTokenAddress: params.outTokenAddress,
    amount: params.amount,
    slippage: params.slippage || 100,
    account: account,
    referrer: "0x3487ef9f9b36547e43268b8f0e2349a226c70b53",
    referrerFee: 30,
    disableRfq: 1,
    gasPrice: 1000000000
  };
  if (swapParams.inTokenAddress === '0x0000000000000000000000000000000000000000') {
    swapParams.inTokenAddress = '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE';
  }
  if (swapParams.outTokenAddress === '0x0000000000000000000000000000000000000000') {
    swapParams.outTokenAddress = '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE';
  }
  const chainCode = "bsc";
  const res = await getSwap(56, swapParams);
  const inAmount = decimals2Amount(res.inAmount, res.inToken.decimals);
  const outAmount = decimals2Amount(res.outAmount, res.outToken.decimals);
  instanct.change({
    status: "loading",
    chainCode,
    text: `Swaping ${inAmount} ${res.inToken.symbol} for ${outAmount} ${res.outToken.symbol}`,
  });
  try {
    const gasPrice = await getGasPrice();
    if (!isNativeToken(swapParams.inTokenAddress)) {
      await checkApprove(swapParams.inTokenAddress, res.to, params.amount, gasPrice, account);
    }
    const result = await myWallet.eth.sendTransaction({
      from: account,
      to: res.to,
      gasPrice,
      data: res.data,
      value: res.value
    });
    const { transactionHash } = result;
    if (transactionHash) {
      instanct.change({
        message: "submit",
        chainCode,
        hash: transactionHash,
        status: "success",
      });
    } else {
      instanct.change({
        message: "Transaction not confirmed.",
        chainCode,
        status: "rejected",
      });
    }
  } catch (e) {
    instanct.change({
      message: e.message,
      chainCode,
      status: "rejected",
    });
  }
}